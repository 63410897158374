import React, { useEffect } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { TiWarning } from "react-icons/ti";
import "./ConfirmationMessage.css";

export default function ConfirmationMessage({
  functionAdd,
  functionDelete,
  functionUpdate,
  isFunctionAlert,
  nameOfFunction,
  isVisible,
  isConfirmAlert,
  isValidInput,
  isSaveChanges,
  setIsVisible,
  isMoveUser,
  newCircle,
  isDeleteUser,
  isDeleteCircle,
  thisCircle,
  isSiteAdded,
  isSiteChanged,
  action,
  isSMChanged,
  nameOfTheSocialMedia,
  isCLChanged,
  isReportSubmit,
  respondentUsername,
}) {
  useEffect(() => {
    if (isVisible) {
      if (isConfirmAlert || isValidInput || isSaveChanges) {
        const timer = setTimeout(() => {
          setIsVisible(false);
        }, 4000);

        return () => clearTimeout(timer);
      } 
      else if(isReportSubmit){
        const timer = setTimeout(() => {
          setIsVisible(false);
        }, 8000);

        return () => clearTimeout(timer);
      }
      else {
        const timer = setTimeout(() => {
          setIsVisible(false);
        }, 4000);

        return () => clearTimeout(timer);
      }
    }
  }, [isVisible, setIsVisible, isConfirmAlert, isSaveChanges, isValidInput]);

  if (!isVisible) return null;

  return (
    <div className="confirmation-msg">
      <div className="cm-container">
        <div className="cm-success-icon">
          {isValidInput || isConfirmAlert ? (
            <TiWarning style={{ color: "#936f38" }} />
          ) : (
            <FaCheckCircle />
          )}
        </div>
        <div className="confirmation-msg-title">
          {isValidInput || isConfirmAlert ? "Warning" : "Success!"}
        </div>
        <div className="cm-body">
          {functionAdd && (
            <div className="cm-desc">{nameOfFunction} added successfully! </div>
          )}
          {functionDelete && (
            <div className="cm-desc">
              {nameOfFunction} deleted successfully!
            </div>
          )}
          {functionUpdate && (
            <div className="cm-desc">
              {nameOfFunction} updated successfully!
            </div>
          )}
          {isFunctionAlert && (
            <div className="cm-desc">
              {nameOfFunction} updated successfully!
            </div>
          )}
          {isConfirmAlert && (
            <div className="cm-desc">Please fill out all the fields!</div>
          )}
          {isValidInput && (
            <div className="cm-desc">
              Please input valid details before proceeding.
            </div>
          )}
          {isSaveChanges && (
            <div className="cm-desc">Your changes saved successfully!</div>
          )}
          {isMoveUser && (
            <div className="cm-desc">
              Successfully moved connections to {newCircle} circle.
            </div>
          )}
          {isDeleteCircle && (
            <div className="cm-desc">
              Successfully deleted {thisCircle} circle with all the connections.
            </div>
          )}
          {isDeleteUser && (
            <div className="cm-desc">
              Successfully removed connection from {newCircle} circle.
            </div>
          )}
          {isSiteAdded && (
            <div className="cm-desc">Site added successfully!</div>
          )}
          {isSiteChanged && (
            <div className="cm-desc">Site {action} successfully!</div>
          )}
          {isSMChanged && (
            <div className="cm-desc">
              {nameOfTheSocialMedia} is {action} successfully!
            </div>
          )}
          {isCLChanged && (
            <div className="cm-desc">Custom Link is {action} successfully!</div>
          )}
          {isReportSubmit && (
            <div className="cm-desc">
              Your report on {respondentUsername} has been successfully
              submitted. We will review the report and take the necessary
              action. Thank you for helping us maintain a safe community!
            </div>
          )}
        </div>

        <div className="cm-footer">
          <div
            className="cm-ok"
            onClick={() => {
              setIsVisible(false);
            }}
          >
            OK
          </div>
        </div>
      </div>
    </div>
  );
}

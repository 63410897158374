import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SwapWeb from "../../Assets/SwapWeb.png";
import Cookies from "js-cookie";
import "./DirectNotice.css";
import API_DOMAIN from "../../ApiConfig";
import ConsoleError from "../ConfirmationMessage/ConsoleError";
import { jwtDecode } from "jwt-decode";

export default function DirectNotice() {
  const [consoleError, setConsoleError] = useState("");
  const [isConsoleError, setIsConsoleError] = useState(false);
  const [usernameOfTheUser, setUsernameOfTheUser] = useState("");

  const directedPage = Cookies.get("redirect");
  const nameOfUser = Cookies.get("guestUsername");
  const loginToView = Cookies.get("loginToView");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const siteURL = queryParams.get("site");
  const iv = queryParams.get("iv");

  const userId = Cookies.get("userId");

  useEffect(() => {
    const fetchUserId = async () => {
      if (userId === "undefined") {
        const userName = Cookies.get("username");
        const response = await fetch(`${API_DOMAIN}/login/detail/${userName}`);
        if (!response.ok) {
          throw new Error("Failed to fetch token and userId");
        }
        const data = await response.json();
        const userId = data.userId;
        Cookies.set("userId", userId);
      }
    };
    fetchUserId();
  }, [userId]);

  useEffect(() => {
    if (!userId) {
      window.location.href = "/";
      const loginToView = "loginToView";
      Cookies.set("site", siteURL);
      Cookies.set("iv", iv);
      Cookies.set("loginToView", loginToView);
    }
  }, [userId, iv, siteURL]);

  const fetchActualLink = async () => {
    try {
      const response = await fetch(
        `${API_DOMAIN}/decode/url/view?fullUrl=https://www.tinly-front-dev.netfy.app/?site=${siteURL}%26iv=${iv}`
      );
      const result = await response.json();
      if (result.success) {
        const { decodedUrl } = jwtDecode(result.token);
        return decodedUrl;
      } else {
        window.location.href = "/";
        Cookies.remove("redirect");
        Cookies.remove("username");
        Cookies.remove("site");
        Cookies.remove("iv");
        Cookies.remove("loginToView");
      }
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  useEffect(() => {
    const fetchUsernameOfTheUser = async () => {
      const response = await fetch(`${API_DOMAIN}/user/view?userId=${userId}`);
      const result = await response.json();
      const { userName } = jwtDecode(result.token);
      setUsernameOfTheUser(userName);
    };
    fetchUsernameOfTheUser();
  }, [userId]);

  const handleStayHere = () => {
    if (directedPage && nameOfUser) {
      window.location.href = `/guest/${nameOfUser}`;
      Cookies.remove("redirect");
    } else {
      window.location.href = `/@${usernameOfTheUser}`;
      Cookies.remove("loginToView");
    }
    Cookies.remove("username");
    Cookies.remove("site");
    Cookies.remove("iv");
  };

  const handleContinue = async () => {
    const url = await fetchActualLink();
    if (url) {
      window.open(url, "__blank");
    }
    if (loginToView && !nameOfUser) {
      window.location.href = `/@${usernameOfTheUser}`;
      Cookies.remove("loginToView");
    } else {
      window.location.href = `/guest/${nameOfUser}`;
      Cookies.remove("redirect");
    }
    Cookies.remove("username");
    Cookies.remove("site");
    Cookies.remove("iv");
  };

  return (
    <>
      <div className="direct-notice-container">
        <div className="direct-notice">
          <div className="d-notice-image">
            <img src={SwapWeb} alt="direct-notice" />
          </div>
          <div className="d-notice-title">Heads up! You're leaving Tinly</div>
          <div className="d-notice-desc">
            You're about to visit an external site. While we strive to recommend
            reliable resources, we don't responsible for the content or security
            of external websites. Click "Continue" to proceed or "Stay here" to
            cancel.
          </div>
          <div className="d-notice-btns">
            <div className="notice-continue" onClick={handleContinue}>
              Continue
            </div>
            <div className="notice-stay" onClick={handleStayHere}>
              Stay here
            </div>
          </div>
        </div>
      </div>
      <ConsoleError
        isConsoleError={isConsoleError}
        consoleError={consoleError}
        closeConsoleError={setIsConsoleError}
      />
    </>
  );
}

import React, { useEffect, useRef, useState } from "react";
import CloseButton from "react-bootstrap/CloseButton";
import API_DOMAIN from "../../ApiConfig";
import Cookies from "js-cookie";
import Popup from "reactjs-popup";
import "./ProfileImage.css";
import ImageCrop from "../ImageCrop/ImageCrop";
import ImageAdd from "../ImageCrop/ImageAdd";
import ConsoleError from "../ConfirmationMessage/ConsoleError";

export default function ProfileImage({
  userImage,
  userPhotoAdded,
  addedImage,
  fetchProfileImage,
}) {
  const fileInputRef = useRef(null);
  const viewImageRef = useRef(null);
  const [previewImage, setPreviewImage] = useState("");
  const [isImageAdded, setIsImageAdded] = useState(false);
  const [newImage, setNewImage] = useState("");
  const [userPhoto, setUserPhoto] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isViewImage, setIsViewImage] = useState(false);
  const [consoleError, setConsoleError] = useState("");
  const [isConsoleError, setIsConsoleError] = useState(false);

  const userId = Cookies.get("userId");

  const updateAvatar = (imgSrc) => {
    setNewImage(imgSrc);
  };

  //click outside to close the modal
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        viewImageRef.current &&
        !viewImageRef.current.contains(event.target)
      ) {
        setIsViewImage(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [viewImageRef]);

  //button click loading animation
  const handleClickLoading = async () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  useEffect(() => {
    if (addedImage) {
      setPreviewImage(addedImage);
      setUserPhoto(addedImage);
      setIsImageAdded(true);
      userPhotoAdded(true);
      userImage(addedImage);
    }
  }, [addedImage, userImage, userPhotoAdded]);

  //delete profile photo
  const deleteProfileImage = async () => {
    try {
      await fetch(`${API_DOMAIN}/profile/image/delete?fk_user_id=${userId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });
      setIsImageAdded(false);
      setPreviewImage("");
      userPhotoAdded(false);
      userImage("");
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  const handleRemoveImage = () => {
    setUserPhoto("");
    setNewImage("");
  };

  const handleSubmit = (close) => {
    if (newImage) {
      setPreviewImage(newImage);
      userPhotoAdded(true);
      userImage(newImage);
      setTimeout(() => {
        close();
      }, 8000);
    } else if (userPhoto === "") {
      deleteProfileImage();
    }
  };

  return (
    <>
      <div className="profile-image">
        {isImageAdded ? (
          <Popup
            trigger={
              <img src={previewImage} alt="Profile" className="preview-image" />
            }
            modal
            nested
          >
            {(close) => (
              <>
                <div className="photo-edit-popup">
                  <div className="pep-header">
                    <div className="pep-title">
                      <h>Profile Photo</h>
                    </div>
                    <button className="pep-close-btn" onClick={() => close()}>
                      <CloseButton style={{ height: "5px", width: "5px" }} />
                    </button>
                  </div>

                  <div className="pep-body">
                    <div
                      className="pep-body-left"
                      onClick={() => {
                        setIsViewImage(true);
                      }}
                    >
                      <img
                        src={newImage || userPhoto}
                        alt="Add New"
                        className="user-photo"
                      />
                    </div>
                    <div className="pep-body-right">
                      <div
                        className="add-new"
                        onClick={() => fileInputRef.current.click()}
                      >
                        <ImageAdd
                          updateAvatar={updateAvatar}
                          fetchProfilePic={fetchProfileImage}
                          stateUserPhoto={userPhotoAdded}
                          photoUser={userImage}
                        />
                      </div>
                      <button
                        className="image-remove"
                        onClick={handleRemoveImage}
                      >
                        Remove
                      </button>
                    </div>
                  </div>

                  <div className="pep-footer">
                    <button
                      className="save-btn"
                      onClick={() => {
                        handleSubmit(close);
                        handleClickLoading();
                      }}
                      disabled={isLoading}
                    >
                      {isLoading ? <div className="spinner"></div> : "Save"}
                    </button>
                  </div>
                </div>

                {isViewImage && (
                  <div className="view-image-modal" ref={viewImageRef}>
                    <img
                      src={previewImage}
                      alt="Profile"
                      className="view-image"
                    />
                  </div>
                )}
              </>
            )}
          </Popup>
        ) : (
          <div>
            <input className="add-image-file" />
            <ImageCrop
              previewImage={setPreviewImage}
              userPhoto={setUserPhoto}
              imageAdded={setIsImageAdded}
              stateUserPhoto={userPhotoAdded}
              imageOfUser={userImage}
            />
          </div>
        )}
      </div>
      <ConsoleError
        isConsoleError={isConsoleError}
        consoleError={consoleError}
        closeConsoleError={setIsConsoleError}
      />
    </>
  );
}

import React, { useState, useRef, useEffect } from "react";
import Popup from "reactjs-popup";
import CloseButton from "react-bootstrap/CloseButton";
import { IoMdAdd } from "react-icons/io";
import { BsYoutube, BsInstagram, BsSnapchat, BsTwitterX } from "react-icons/bs";
import {
  BiLogoFacebook,
  BiLogoTiktok,
  BiLogoPatreon,
  BiLogoLinkedin,
} from "react-icons/bi";
import { TbFileUpload } from "react-icons/tb";
import { FaPinterestP } from "react-icons/fa";
import { FaTumblr } from "react-icons/fa6";
import { SlSocialVkontakte } from "react-icons/sl";
import "react-image-crop/dist/ReactCrop.css";
import API_DOMAIN from "../../ApiConfig";
import Cookies from "js-cookie";
import "./SMLinks.css";
import ConfirmationMessage from "../ConfirmationMessage/ConfirmationMessage";
import ConsoleError from "../ConfirmationMessage/ConsoleError";

export default function SMLinks({
  newShortLink,
  fetchTemplate,
  fetchSM,
  fetchCL,
  smLinksAdded,
}) {
  const fileInputRef = useRef(null);
  const [facebookData, setFacebookData] = useState("");
  const [instagramData, setInstagramData] = useState("");
  const [twitterData, setTwitterData] = useState("");
  const [youtubeData, setYoutubeData] = useState("");
  const [tiktokData, setTiktokData] = useState("");
  const [pinterestData, setPinterestData] = useState("");
  const [patreonData, setPatreonData] = useState("");
  const [tumblrData, setTumblrData] = useState("");
  const [vkData, setVKData] = useState("");
  const [linkedInData, setLinkedInData] = useState("");
  const [snapchatData, setSnapchatData] = useState("");
  const [addType, setAddType] = useState("");
  const [isFooterVisible, setIsFooterVisible] = useState(false);
  const [image, setImage] = useState("");
  const [, setIsSMAdded] = useState(false);
  const [customLinkUrl, setCustomLinkUrl] = useState("");
  const [isValidCustomLinks, setIsValidCustomLinks] = useState(false);
  const [customLinkError, setCustomLinkError] = useState("");
  const [customLinkTitleError, setCustomLinkTitleError] = useState("");
  const [customLinkTitle, setCustomLinkTitle] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [, setIsCustomLinkAdded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fbError, setFbError] = useState("");
  const [instaError, setInstaError] = useState("");
  const [xError, setXError] = useState("");
  const [youtubeError, setYoutubeError] = useState("");
  const [tiktokError, setTiktokError] = useState("");
  const [pinterestError, setPinterestError] = useState("");
  const [patreonError, setPatreonError] = useState("");
  const [tumblrError, setTumblrError] = useState("");
  const [vkError, setVkError] = useState("");
  const [linkedinError, setLinkedinError] = useState("");
  const [snapchatError, setSnapchatError] = useState("");
  const [isValidFb, setIsValidFb] = useState(false);
  const [isValidInsta, setIsValidInsta] = useState(false);
  const [isValidYt, setIsValidYt] = useState(false);
  const [isValidTwitter, setIsValidTwitter] = useState(false);
  const [isValidTiktok, setIsValidTiktok] = useState(false);
  const [isValidPinterest, setIsValidPinterest] = useState(false);
  const [isValidPatreon, setIsValidPatreon] = useState(false);
  const [isValidTumblr, setIsValidTumblr] = useState(false);
  const [isValidVk, setIsValidVk] = useState(false);
  const [isValidLinkedIn, setIsValidLinkedIn] = useState(false);
  const [isValidSnapchat, setIsValidSnapchat] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isConfirmAlert] = useState(false);
  const [consoleError, setConsoleError] = useState("");
  const [isConsoleError, setIsConsoleError] = useState(false);
  const customLinkUrlRef = useRef(null);
  const customLinkTitleRef = useRef(null);

  const userId = Cookies.get("userId");

  const [checkChecked, setCheckChecked] = useState({
    facebook: false,
    instagram: false,
    twitter: false,
    youtube: false,
    tiktok: false,
    pinterest: false,
    patreon: false,
    tumblr: false,
    vk: false,
    linkedin: false,
    snapchat: false,
  });

  useEffect(() => {
    checkChecked.facebook = false;
    checkChecked.instagram = false;
    checkChecked.twitter = false;
    checkChecked.youtube = false;
    checkChecked.tiktok = false;
    checkChecked.pinterest = false;
    checkChecked.patreon = false;
    checkChecked.tumblr = false;
    checkChecked.vk = false;
    checkChecked.linkedin = false;
    checkChecked.snapchat = false;
    if (smLinksAdded) {
      smLinksAdded.forEach((sm) => {
        const { type, link } = sm;
        if (link !== "N/A") {
          switch (type) {
            case "Facebook":
              setFacebookData(link);
              checkChecked.facebook = true;
              break;
            case "Instagram":
              setInstagramData(link);
              checkChecked.instagram = true;
              break;
            case "X":
              setTwitterData(link);
              checkChecked.twitter = true;
              break;
            case "Youtube":
              setYoutubeData(link);
              checkChecked.youtube = true;
              break;
            case "TikTok":
              setTiktokData(link);
              checkChecked.tiktok = true;
              break;
            case "Pinterest":
              setPinterestData(link);
              checkChecked.pinterest = true;
              break;
            case "Patreon":
              setPatreonData(link);
              checkChecked.patreon = true;
              break;
            case "Tumblr":
              setTumblrData(link);
              checkChecked.tumblr = true;
              break;
            case "VK":
              setVKData(link);
              checkChecked.vk = true;
              break;
            case "LinkedIn":
              setLinkedInData(link);
              checkChecked.linkedin = true;
              break;
            case "Snapchat":
              setSnapchatData(link);
              checkChecked.snapchat = true;
              break;
            default:
              break;
          }
          setIsSMAdded(true);
        }
      });
    }
  }, [smLinksAdded]);

  //button click loading animation
  const handleClickLoading = async () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 4000);
  };

  //custom links validation
  const handleCustomLinks = (e) => {
    const enteredCustomLink = e.target.value;
    setCustomLinkUrl(enteredCustomLink);
    const urlCheck =
      /^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/;
    if (enteredCustomLink.trim() !== "") {
      setIsValidCustomLinks(urlCheck.test(enteredCustomLink));
      setCustomLinkError("Enter a valid URL (https:// or http://)");
    } else {
      setIsValidCustomLinks(urlCheck.test(enteredCustomLink));
      setCustomLinkError("");
    }
  };

  const handleCustomTitle = (e) => {
    const enteredCustomTitle = e.target.value;
    setCustomLinkTitle(enteredCustomTitle);
    if (enteredCustomTitle.trim()) {
      setCustomLinkTitleError("");
    }
  };

  //facebook link validation
  const handleFbLink = (e) => {
    if (checkChecked.facebook) {
      const enteredSmLink = e.target.value;
      const fbUrl = /^(?!.*\.\.)(?!.*\.$)[a-zA-Z0-9.]{5,}$/;

      setFacebookData(enteredSmLink);

      if (enteredSmLink.trim() === "") {
        setFbError("");
      } else if (fbUrl.test(enteredSmLink)) {
        setIsValidFb(true);
        setFbError("");
      } else {
        setIsValidFb(false);
        setFbError("Enter a valid Facebook Username");
      }
    }
  };

  //instagram link validation
  const handleInstaLink = (e) => {
    if (checkChecked.instagram) {
      const enteredInstaLink = e.target.value;
      const instaName = /^[a-zA-Z0-9._]{4,30}$/;

      setInstagramData(enteredInstaLink);

      if (enteredInstaLink.trim() === "") {
        setInstaError("");
      } else if (instaName.test(enteredInstaLink)) {
        setIsValidInsta(true);
        setInstaError("");
      } else {
        setIsValidInsta(false);
        setInstaError("Enter a valid Instagram Username");
      }
    }
  };

  //Youtube link validation
  const handleYt = (e) => {
    if (checkChecked.youtube) {
      const enteredYt = e.target.value;
      const ytUrl = /^[a-zA-Z0-9_]{3,20}$/;

      setYoutubeData(enteredYt);

      if (enteredYt.trim() === "") {
        setYoutubeError("");
      } else if (ytUrl.test(enteredYt)) {
        setIsValidYt(true);
        setYoutubeError("");
      } else {
        setIsValidYt(false);
        setYoutubeError("Enter a valid Youtube username");
      }
    } else {
      setYoutubeData("");
    }
  };

  //Twitter link validation
  const handleTwitterLink = (e) => {
    if (checkChecked.twitter) {
      const enteredTwitterLink = e.target.value;
      const twitterName = /^[a-zA-Z0-9_]{4,15}$/;

      setTwitterData(enteredTwitterLink);

      if (enteredTwitterLink.trim() === "") {
        setXError("");
      } else if (twitterName.test(enteredTwitterLink)) {
        setIsValidTwitter(true);
        setXError("");
      } else {
        setIsValidTwitter(false);
        setXError("Enter a valid X Username");
      }
    }
  };

  //TikTok link validation
  const handleTiktokLink = (e) => {
    if (checkChecked.tiktok) {
      const enteredTiktokLink = e.target.value;
      const tiktokName = /^(?!.*\.$)[a-zA-Z0-9_.]{4,30}$/;

      setTiktokData(enteredTiktokLink);

      if (enteredTiktokLink.trim() === "") {
        setTiktokError("");
      } else if (tiktokName.test(enteredTiktokLink)) {
        setIsValidTiktok(true);
        setTiktokError("");
      } else {
        setIsValidTiktok(false);
        setTiktokError("Enter a valid Tiktok Username");
      }
    }
  };

  //Pinterest link validation
  const handlePinterest = (e) => {
    if (checkChecked.pinterest) {
      const enteredPinterest = e.target.value;
      const pinterestName = /^(?!.*\.$)[a-zA-Z0-9_.]{4,30}$/;

      setPinterestData(enteredPinterest);

      if (enteredPinterest.trim() === "") {
        setPinterestError("");
      } else if (pinterestName.test(enteredPinterest)) {
        setIsValidPinterest(true);
        setPinterestError("");
      } else {
        setIsValidPinterest(false);
        setPinterestError("Enter a valid Pinterest Username");
      }
    }
  };

  //Patreon link validation
  const handlePatreon = (e) => {
    if (checkChecked.patreon) {
      const enteredPatreon = e.target.value;
      const patreonName = /^(?!.*\.$)[a-zA-Z0-9_.]{4,30}$/;

      setPatreonData(enteredPatreon);

      if (enteredPatreon.trim() === "") {
        setPatreonError("");
      } else if (patreonName.test(enteredPatreon)) {
        setIsValidPatreon(true);
        setPatreonError("");
      } else {
        setIsValidPatreon(false);
        setPatreonError("Enter a valid Patreon Username");
      }
    }
  };

  //Tumblr link validation
  const handleTumblr = (e) => {
    if (checkChecked.tumblr) {
      const enteredTumblr = e.target.value;
      const tumblrName = /^(?!.*\.$)[a-zA-Z0-9_.]{4,30}$/;

      setTumblrData(enteredTumblr);

      if (enteredTumblr.trim() === "") {
        setTumblrError("");
      } else if (tumblrName.test(enteredTumblr)) {
        setIsValidTumblr(true);
        setTumblrError("");
      } else {
        setIsValidTumblr(false);
        setTumblrError("Enter a valid Tumblr Username");
      }
    }
  };

  //Vk link validation
  const handleVk = (e) => {
    if (checkChecked.vk) {
      const enteredVk = e.target.value;
      const vkUProfile = /^https?:\/\/vk\.com\/id/;
      const vkCommunity = /^https?:\/\/vk\.com\/public/;
      const vkClub = /^https?:\/\/vk\.com\/club/;

      setVKData(enteredVk);

      if (enteredVk.trim() === "") {
        setVkError("");
      } else if (
        vkUProfile.test(enteredVk) ||
        vkCommunity.test(enteredVk) ||
        vkClub.test(enteredVk)
      ) {
        setIsValidVk(true);
        setVkError("");
      } else {
        setIsValidVk(false);
        setVkError("Enter a valid VK page ID");
      }
    }
  };

  //LinkedIn link validation
  const handleLinkedin = (e) => {
    if (checkChecked.linkedin) {
      const enteredLinkedin = e.target.value;
      const linkedinName = /^https?:\/\/(www\.)?linkedin\.com\/in\//;

      setLinkedInData(enteredLinkedin);

      if (enteredLinkedin.trim() === "") {
        setLinkedinError("");
      } else if (linkedinName.test(enteredLinkedin)) {
        setIsValidLinkedIn(true);
        setLinkedinError("");
      } else {
        setIsValidLinkedIn(false);
        setLinkedinError(
          "Enter a valid LinkedIn URL(https://www.linkedin.com/in/)"
        );
      }
    }
  };

  //Snapchat link validation
  const handleSnapchat = (e) => {
    if (checkChecked.snapchat) {
      const enteredSnapchat = e.target.value;
      const snapchatName = /^(?!.*\.$)[a-zA-Z0-9_.]{4,30}$/;
      const snapchatUrl = /^snapchat\.com\/add\//;

      setSnapchatData(enteredSnapchat);

      if (enteredSnapchat.trim() === "") {
        setSnapchatError("");
      } else if (
        snapchatName.test(enteredSnapchat) ||
        snapchatUrl.test(enteredSnapchat)
      ) {
        setIsValidSnapchat(true);
        setSnapchatError("");
      } else {
        setIsValidSnapchat(false);
        setSnapchatError("Enter a valid Snapchat Username or Page ID");
      }
    }
  };

  const handleCheckBoxChecked = (type, event) => {
    const isChecked = event.target.checked;
    setCheckChecked((prevState) => ({
      ...prevState,
      [type]: isChecked,
    }));
  };

  //update social media links
  const updateSMLinks = async () => {
    try {
      const updatedSocialMediaData = [
        { type: "Facebook", username: facebookData },
        { type: "Instagram", username: instagramData },
        { type: "X", username: twitterData },
        { type: "Youtube", username: youtubeData },
        { type: "TikTok", username: tiktokData },
        { type: "Pinterest", username: pinterestData },
        { type: "Patreon", username: patreonData },
        { type: "Tumblr", username: tumblrData },
        { type: "VK", username: vkData },
        { type: "LinkedIn", username: linkedInData },
        { type: "Snapchat", username: snapchatData },
      ];

      const filledUpdatedSocialMediaData = updatedSocialMediaData.filter(
        (entry) => entry.username !== ""
      );

      const res = await fetch(`${API_DOMAIN}/socialmedia/update/${userId}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          updates: filledUpdatedSocialMediaData,
        }),
      });

      const result = await res.json();

      if (result.success) {
        setIsSMAdded(true);
      } else {
        const isNotFound = result.code === 404;

        if (isNotFound) {
          setIsConsoleError(true);
          setConsoleError(result.error);
          return { success: false };
        } else {
          return { success: false };
        }
      }
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  const postSMLinks = async () => {
    const checkedLinks = Object.keys(checkChecked).filter(
      (key) => checkChecked[key]
    );

    let hasErrors = false;

    // Validate each checked platform
    checkedLinks.forEach((platform) => {
      switch (platform) {
        case "facebook":
          if (facebookData.trim() === "") {
            setFbError("Please fill in your Facebook URL");
            hasErrors = true;
          } else if (
            !/^(?!.*\.\.)(?!.*\.$)[a-zA-Z0-9.]{5,}$/.test(facebookData)
          ) {
            setFbError("Enter a valid Facebook Username");
            hasErrors = true;
          } else {
            setFbError("");
          }
          break;
        case "instagram":
          if (instagramData.trim() === "") {
            setInstaError("Please fill in your Instagram Username");
            hasErrors = true;
          } else if (!/^[a-zA-Z0-9._]{4,30}$/.test(instagramData)) {
            setInstaError("Enter a valid Instagram Username");
            hasErrors = true;
          } else {
            setInstaError("");
          }
          break;
        case "youtube":
          if (youtubeData.trim() === "") {
            setYoutubeError("Please fill in your Youtube Chanel URL");
            hasErrors = true;
          } else if (!/^[a-zA-Z0-9_]{3,20}$/.test(youtubeData)) {
            setYoutubeError("Enter a valid Youtube username");
            hasErrors = true;
          } else {
            setYoutubeError("");
          }
          break;
        case "twitter":
          if (twitterData.trim() === "") {
            setXError("Please fill in your X Username");
            hasErrors = true;
          } else if (!/^[a-zA-Z0-9_]{4,15}$/.test(twitterData)) {
            setXError("Enter a valid X Username");
            hasErrors = true;
          } else {
            setXError("");
          }
          break;
        case "tiktok":
          if (tiktokData.trim() === "") {
            setTiktokError("Please fill in your Tiktok Username");
            hasErrors = true;
          } else if (!/^(?!.*\.$)[a-zA-Z0-9_.]{4,30}$/.test(tiktokData)) {
            setTiktokError("Enter a valid Tiktok Username");
            hasErrors = true;
          } else {
            setTiktokError("");
          }
          break;
        case "pinterest":
          if (pinterestData.trim() === "") {
            setPinterestError("Please fill in your Pinterest Username");
            hasErrors = true;
          } else if (!/^(?!.*\.$)[a-zA-Z0-9_.]{4,30}$/.test(pinterestData)) {
            setPinterestError("Enter a valid Pinterest Username");
            hasErrors = true;
          } else {
            setPinterestError("");
          }
          break;
        case "patreon":
          if (patreonData.trim() === "") {
            setPatreonError("Please fill in your Patreon Username");
            hasErrors = true;
          } else if (!/^(?!.*\.$)[a-zA-Z0-9_.]{4,30}$/.test(patreonData)) {
            setPatreonError("Enter a valid Patreon Username");
            hasErrors = true;
          } else {
            setPatreonError("");
          }
          break;
        case "tumblr":
          if (tumblrData.trim() === "") {
            setTumblrError("Please fill in your Tumblr Username");
            hasErrors = true;
          } else if (!/^(?!.*\.$)[a-zA-Z0-9_.]{4,30}$/.test(tumblrData)) {
            setTumblrError("Enter a valid Tumblr Username");
            hasErrors = true;
          } else {
            setTumblrError("");
          }
          break;
        case "vk":
          if (vkData.trim() === "") {
            setVkError("Please fill in your VK page ID");
            hasErrors = true;
          } else if (
            !/^https?:\/\/vk\.com\/id/.test(vkData) &&
            !/^https?:\/\/vk\.com\/public/.test(vkData) &&
            !/^https?:\/\/vk\.com\/club/.test(vkData)
          ) {
            setVkError("Enter a valid VK page ID");
            hasErrors = true;
          } else {
            setVkError("");
          }
          break;
        case "linkedin":
          if (linkedInData.trim() === "") {
            setLinkedinError("Please fill in your LinkedIn URL");
            hasErrors = true;
          } else if (
            !/^https?:\/\/(www\.)?linkedin\.com\/in\//.test(linkedInData)
          ) {
            setLinkedinError(
              "Enter a valid LinkedIn URL (https://www.linkedin.com/in/)"
            );
            hasErrors = true;
          } else {
            setLinkedinError("");
          }
          break;
        case "snapchat":
          if (snapchatData.trim() === "") {
            setSnapchatError(
              "Please fill in your Snapchat Username or Page ID"
            );
            hasErrors = true;
          } else if (
            !/^(?!.*\.$)[a-zA-Z0-9_.]{4,30}$/.test(snapchatData) &&
            !/^snapchat\.com\/add\//.test(snapchatData)
          ) {
            setSnapchatError("Enter a valid Snapchat Username or Page ID");
            hasErrors = true;
          } else {
            setSnapchatError("");
          }
          break;
        default:
          break;
      }
    });

    if (hasErrors) {
      return { isSuccess: false };
    } else {
      try {
        const socialMediaData = [
          { type: "Facebook", username: facebookData },
          { type: "Instagram", username: instagramData },
          { type: "X", username: twitterData },
          { type: "Youtube", username: youtubeData },
          { type: "TikTok", username: tiktokData },
          { type: "Pinterest", username: pinterestData },
          { type: "Patreon", username: patreonData },
          { type: "Tumblr", username: tumblrData },
          { type: "VK", username: vkData },
          { type: "LinkedIn", username: linkedInData },
          { type: "Snapchat", username: snapchatData },
        ];

        const filledSocialMediaData = socialMediaData.filter(
          (entry) => entry.username !== ""
        );

        const response = await fetch(
          `${API_DOMAIN}/socialmedia/save/${userId}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              socialMediaLinks: filledSocialMediaData,
            }),
          }
        );
        const result = await response.json();

        if (result.success) {
          setIsSMAdded(true);
          return { isSuccess: true };
        } else {
          const isNotFound = result.code === 404;

          if (isNotFound) {
            setIsConsoleError(true);
            setConsoleError(result.error);
            return { isSuccess: false };
          } else {
            hasErrors = true;
            return { success: false };
          }
        }
      } catch (error) {
        if (error.message === "Failed to fetch") {
          setIsConsoleError(true);
          setConsoleError("Unable to connect. Please check back shortly!");
        } else {
          setIsConsoleError(true);
          setConsoleError(error.message);
        }
      }
    }
  };

  //post custom links
  const postCustomLink = async () => {
    try {
      if (!customLinkUrl.trim()) {
        setCustomLinkError("Website URL is required");
        setIsValidCustomLinks(false);
        customLinkUrlRef.current.scrollIntoView({ behavior: "smooth" });
        return { isSuccess: false };
      } else if (!customLinkTitle.trim()) {
        setCustomLinkTitleError("Website Title is required");
        setIsValidCustomLinks(false);
        customLinkTitleRef.current.scrollIntoView({ behavior: "smooth" });
        return { isSuccess: false };
      } else {
        const customLinks = customLinkUrl;
        const title = customLinkTitle;
        const image = selectedFile;

        const formData = new FormData();
        formData.append("customLinks", customLinks);
        formData.append("title", title);
        formData.append("userId", userId);
        formData.append("image", image);

        const response = await fetch(`${API_DOMAIN}/customlinks/insert`, {
          method: "POST",
          body: formData,
        });
        const result = await response.json();
        if (result.success) {
          setIsCustomLinkAdded(true);
          return { isSuccess: true };
        } else {
          const isNotFound = result.code === 404;

          if (isNotFound) {
            setIsConsoleError(true);
            setConsoleError(result.error);
            return { isSuccess: false };
          } else if (result.error === "URL is blacklisted") {
            setIsValidCustomLinks(false);
            setCustomLinkError("Restricted URL. Try another one.");
            customLinkUrlRef.current.scrollIntoView({ behavior: "smooth" });
            return { isSuccess: false };
          } else {
            setIsValidCustomLinks(false);
            setCustomLinkError(result.error);
            customLinkUrlRef.current.scrollIntoView({ behavior: "smooth" });
            return { isSuccess: false };
          }
        }
      }
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  const deleteSMLinks = async (type) => {
    try {
      await fetch(`${API_DOMAIN}/socialmedia/delete/${userId}/${type}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  const handleAddTypeChange = (icon) => {
    setAddType(icon);
    setIsFooterVisible(true);
  };

  const handleClick = async (close) => {
    await postSMLinks();

    await updateSMLinks();

    if (!checkChecked.facebook) {
      await deleteSMLinks("Facebook");
    }
    if (!checkChecked.instagram) {
      await deleteSMLinks("Instagram");
    }
    if (!checkChecked.twitter) {
      await deleteSMLinks("X");
    }
    if (!checkChecked.youtube) {
      await deleteSMLinks("Youtube");
    }
    if (!checkChecked.tiktok) {
      await deleteSMLinks("TikTok");
    }
    if (!checkChecked.pinterest) {
      await deleteSMLinks("Pinterest");
    }
    if (!checkChecked.patreon) {
      await deleteSMLinks("Patreon");
    }
    if (!checkChecked.tumblr) {
      await deleteSMLinks("Tumblr");
    }
    if (!checkChecked.vk) {
      await deleteSMLinks("VK");
    }
    if (!checkChecked.linkedin) {
      await deleteSMLinks("LinkedIn");
    }
    if (!checkChecked.snapchat) {
      await deleteSMLinks("Snapchat");
    }
    fetchTemplate();
    fetchSM();
    close();
  };

  const handleCustom = async (close) => {
    const result = await postCustomLink();
    if (result && result.isSuccess) {
      fetchTemplate();
      fetchSM();
      fetchCL();
      close();
    }
  };

  const handleImageChange = (e) => {
    const image = e.target.files[0];
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(URL.createObjectURL(image));
        setSelectedFile(image);
      };
      reader.readAsDataURL(image);
    }
  };

  const handleChangeImage = (e) => {
    const newImage = e.target.files[0];
    if (newImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(URL.createObjectURL(newImage)); // Update the image preview
        setSelectedFile(newImage); // Update the selected file
      };
      reader.readAsDataURL(newImage);
    }
  };

  const handleOnClick = () => {
    fetchSM();
    setCustomLinkUrl("");
    setCustomLinkTitle("");
    setImage("");
    setSelectedFile("");
    setCustomLinkTitleError("");
    setCustomLinkError("");
    setFbError("");
    setInstaError("");
    setYoutubeError("");
    setTiktokError("");
    setPinterestError("");
    setPatreonError("");
    setTumblrError("");
    setVkError("");
    setLinkedinError("");
    setSnapchatError("");
  };

  const handleRemoveImage = () => {
    setImage(""); // Clear the displayed image
    setSelectedFile(""); // Reset selectedFile state to empty string or null
  };

  return (
    <div className="shortlys-canvas">
      <Popup
        trigger={
          <button className="add-sm" value={newShortLink}>
            <IoMdAdd className="add-sm-icon" onClick={handleOnClick} />
          </button>
        }
        modal
        nested
      >
        {(close) => (
          <div className="sm-popup">
            <div className="smp-header">
              <div className="smp-close">
                <button className="smp-close-btn" onClick={() => close()}>
                  <CloseButton style={{ height: "5px", width: "5px" }} />
                </button>
              </div>
              <div className="smp-title">
                <h> Add Shortly </h>
              </div>
            </div>
            <div className="smp-buttons">
              <div className="smp-buttons-sec">
                <button
                  className={`smp-btn ${
                    addType === "socialMedia" ? "active" : ""
                  }`}
                  onClick={() => handleAddTypeChange("socialMedia")}
                >
                  Social Media Links
                </button>
              </div>

              <div className="smp-buttons-sec">
                <button
                  className={`smp-btn ${addType === "custom" ? "active" : ""}`}
                  onClick={() => handleAddTypeChange("custom")}
                >
                  Custom Links
                </button>
              </div>
            </div>

            {addType === "socialMedia" && (
              <div className="smp-body">
                <div className="smp-instruction">
                  Please tap the checkbox before proceeding to add social
                  accounts.
                </div>

                <div className="smp-scroll">
                  <div className="add-sm-link-container">
                    <div className="add-sm-link">
                      <input
                        type="checkbox"
                        className="select-box"
                        checked={checkChecked.facebook}
                        onChange={(event) => {
                          handleCheckBoxChecked("facebook", event);
                        }}
                      />

                      <div className="add-sm-box">
                        <div className="icon-box">
                          <div className="sm-icon">
                            <BiLogoFacebook />
                          </div>
                        </div>
                        <input
                          type="text"
                          className="sm-url"
                          placeholder={"Facebook Username"}
                          disabled={!checkChecked.facebook && setFbError("")}
                          value={facebookData}
                          onChange={handleFbLink}
                        />
                      </div>
                    </div>
                    {isValidFb ? (
                      ""
                    ) : (
                      <div className="error-msg-sm-links">{fbError}</div>
                    )}
                  </div>
                  <div className="add-sm-link-container">
                    <div className="add-sm-link">
                      <input
                        type="checkbox"
                        className="select-box"
                        checked={checkChecked.instagram}
                        onChange={(event) =>
                          handleCheckBoxChecked("instagram", event)
                        }
                      />
                      <div className="add-sm-box">
                        <div className="icon-box">
                          <div className="sm-icon">
                            <BsInstagram />
                          </div>
                        </div>
                        <input
                          type="text"
                          className="sm-url"
                          placeholder={"Instagram Username"}
                          disabled={
                            !checkChecked.instagram && setInstaError("")
                          }
                          value={instagramData}
                          onChange={handleInstaLink}
                        />
                      </div>
                    </div>
                    {isValidInsta ? (
                      ""
                    ) : (
                      <div className="error-msg-sm-links">{instaError}</div>
                    )}
                  </div>
                  <div className="add-sm-link-container">
                    <div className="add-sm-link">
                      <input
                        type="checkbox"
                        className="select-box"
                        checked={checkChecked.twitter}
                        onChange={(event) =>
                          handleCheckBoxChecked("twitter", event)
                        }
                      />
                      <div className="add-sm-box">
                        <div className="icon-box">
                          <div className="sm-icon">
                            <BsTwitterX />
                          </div>
                        </div>
                        <input
                          type="text"
                          className="sm-url"
                          placeholder={"X Username"}
                          disabled={!checkChecked.twitter && setXError("")}
                          value={twitterData}
                          onChange={handleTwitterLink}
                        />
                      </div>
                    </div>
                    {isValidTwitter ? (
                      ""
                    ) : (
                      <div className="error-msg-sm-links">{xError}</div>
                    )}
                  </div>
                  <div className="add-sm-link-container">
                    <div className="add-sm-link">
                      <input
                        type="checkbox"
                        className="select-box"
                        checked={checkChecked.youtube}
                        onChange={(event) =>
                          handleCheckBoxChecked("youtube", event)
                        }
                      />
                      <div className="add-sm-box">
                        <div className="icon-box">
                          <div className="sm-icon">
                            <BsYoutube />
                          </div>
                        </div>
                        <input
                          type="text"
                          className="sm-url"
                          placeholder={"Youtube Username"}
                          disabled={
                            !checkChecked.youtube && setYoutubeError("")
                          }
                          value={youtubeData}
                          onChange={handleYt}
                        />
                      </div>
                    </div>
                    {isValidYt ? (
                      ""
                    ) : (
                      <div className="error-msg-sm-links">{youtubeError}</div>
                    )}
                  </div>
                  <div className="add-sm-link-container">
                    <div className="add-sm-link">
                      <input
                        type="checkbox"
                        className="select-box"
                        checked={checkChecked.tiktok}
                        onChange={(event) =>
                          handleCheckBoxChecked("tiktok", event)
                        }
                      />
                      <div className="add-sm-box">
                        <div className="icon-box">
                          <div className="sm-icon">
                            <BiLogoTiktok />
                          </div>
                        </div>
                        <input
                          type="text"
                          className="sm-url"
                          placeholder={"TikTok Username"}
                          disabled={!checkChecked.tiktok && setTiktokError("")}
                          value={tiktokData}
                          onChange={handleTiktokLink}
                        />
                      </div>
                    </div>
                    {isValidTiktok ? (
                      ""
                    ) : (
                      <div className="error-msg-sm-links">{tiktokError}</div>
                    )}
                  </div>
                  <div className="add-sm-link-container">
                    <div className="add-sm-link">
                      <input
                        type="checkbox"
                        className="select-box"
                        checked={checkChecked.pinterest}
                        onChange={(event) =>
                          handleCheckBoxChecked("pinterest", event)
                        }
                      />
                      <div className="add-sm-box">
                        <div className="icon-box">
                          <div className="sm-icon">
                            <FaPinterestP />
                          </div>
                        </div>
                        <input
                          type="text"
                          className="sm-url"
                          placeholder={"Pinterest Username"}
                          disabled={
                            !checkChecked.pinterest && setPinterestError("")
                          }
                          value={pinterestData}
                          onChange={handlePinterest}
                        />
                      </div>
                    </div>
                    {isValidPinterest ? (
                      ""
                    ) : (
                      <div className="error-msg-sm-links">{pinterestError}</div>
                    )}
                  </div>
                  <div className="add-sm-link-container">
                    <div className="add-sm-link">
                      <input
                        type="checkbox"
                        className="select-box"
                        checked={checkChecked.patreon}
                        onChange={(event) =>
                          handleCheckBoxChecked("patreon", event)
                        }
                      />
                      <div className="add-sm-box">
                        <div className="icon-box">
                          <div className="sm-icon">
                            <BiLogoPatreon />
                          </div>
                        </div>
                        <input
                          type="text"
                          className="sm-url"
                          placeholder={"Patreon Username"}
                          disabled={
                            !checkChecked.patreon && setPatreonError("")
                          }
                          value={patreonData}
                          onChange={handlePatreon}
                        />
                      </div>
                    </div>
                    {isValidPatreon ? (
                      ""
                    ) : (
                      <div className="error-msg-sm-links">{patreonError}</div>
                    )}
                  </div>
                  <div className="add-sm-link-container">
                    <div className="add-sm-link">
                      <input
                        type="checkbox"
                        className="select-box"
                        checked={checkChecked.tumblr}
                        onChange={(event) =>
                          handleCheckBoxChecked("tumblr", event)
                        }
                      />
                      <div className="add-sm-box">
                        <div className="icon-box">
                          <div className="sm-icon">
                            <FaTumblr />
                          </div>
                        </div>
                        <input
                          type="text"
                          className="sm-url"
                          placeholder={"Tumblr Username"}
                          disabled={!checkChecked.tumblr && setTumblrError("")}
                          value={tumblrData}
                          onChange={handleTumblr}
                        />
                      </div>
                    </div>
                    {isValidTumblr ? (
                      ""
                    ) : (
                      <div className="error-msg-sm-links">{tumblrError}</div>
                    )}
                  </div>
                  <div className="add-sm-link-container">
                    <div className="add-sm-link">
                      <input
                        type="checkbox"
                        className="select-box"
                        checked={checkChecked.vk}
                        onChange={(event) => handleCheckBoxChecked("vk", event)}
                      />
                      <div className="add-sm-box">
                        <div className="icon-box">
                          <div className="sm-icon">
                            <SlSocialVkontakte />
                          </div>
                        </div>
                        <input
                          type="text"
                          className="sm-url"
                          placeholder={"VK Page ID"}
                          disabled={!checkChecked.vk && setVkError("")}
                          value={vkData}
                          onChange={handleVk}
                        />
                      </div>
                    </div>
                    {isValidVk ? (
                      ""
                    ) : (
                      <div className="error-msg-sm-links">{vkError}</div>
                    )}
                  </div>
                  <div className="add-sm-link-container">
                    <div className="add-sm-link">
                      <input
                        type="checkbox"
                        className="select-box"
                        checked={checkChecked.linkedin}
                        onChange={(event) =>
                          handleCheckBoxChecked("linkedin", event)
                        }
                      />
                      <div className="add-sm-box">
                        <div className="icon-box">
                          <div className="sm-icon">
                            <BiLogoLinkedin />
                          </div>
                        </div>
                        <input
                          type="text"
                          className="sm-url"
                          placeholder={"LinkedIn Profile URL"}
                          disabled={
                            !checkChecked.linkedin && setLinkedinError("")
                          }
                          value={linkedInData}
                          onChange={handleLinkedin}
                        />
                      </div>
                    </div>
                    {isValidLinkedIn ? (
                      ""
                    ) : (
                      <div className="error-msg-sm-links">{linkedinError}</div>
                    )}
                  </div>
                  <div className="add-sm-link-container">
                    <div className="add-sm-link">
                      <input
                        type="checkbox"
                        className="select-box"
                        checked={checkChecked.snapchat}
                        onChange={(event) =>
                          handleCheckBoxChecked("snapchat", event)
                        }
                      />
                      <div className="add-sm-box">
                        <div className="icon-box">
                          <div className="sm-icon">
                            <BsSnapchat />
                          </div>
                        </div>
                        <input
                          type="text"
                          className="sm-url"
                          placeholder={"Snapchat Username or Page ID"}
                          disabled={
                            !checkChecked.snapchat && setSnapchatError("")
                          }
                          value={snapchatData}
                          onChange={handleSnapchat}
                        />
                      </div>
                    </div>
                    {isValidSnapchat ? (
                      ""
                    ) : (
                      <div className="error-msg-sm-links">{snapchatError}</div>
                    )}
                  </div>
                  <div className="smp-instruction-bottom">
                    If you can't find the link you want, add it as a custom
                    link.
                  </div>
                </div>
              </div>
            )}

            {addType === "custom" && (
              <div className="custom-body">
                <div className="input-sec">
                  <div className="input-sec-required">
                    <div className="cb-input-title">Website URL</div>
                    <div className="required-cl">*</div>
                  </div>
                  <div className="cl-input-canvas">
                    <input
                      type="text"
                      className="txt-url"
                      placeholder="https://url or http://url"
                      value={customLinkUrl}
                      onChange={handleCustomLinks}
                      ref={customLinkUrlRef}
                      required
                    />
                  </div>

                  {isValidCustomLinks ? (
                    ""
                  ) : (
                    <div className="error-msg-custom-link">
                      {customLinkError}
                    </div>
                  )}
                </div>

                <div className="input-sec">
                  <div className="cb-input-title">Website Image or Logo</div>
                  <div className="add-img-sec">
                    {image ? (
                      <div className="uploaded-img-container">
                        <img
                          src={image}
                          alt="Website Logo"
                          className="uploaded-img"
                        />
                        <div className="btn-group">
                          <button
                            className="btn-change"
                            onClick={() => fileInputRef.current.click()}
                          >
                            <input
                              type="file"
                              accept="image/*"
                              className="input-img"
                              ref={fileInputRef}
                              onChange={handleChangeImage}
                              hidden
                            />
                            Change Image
                          </button>
                          <button
                            className="btn-remove"
                            onClick={handleRemoveImage}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div
                        onClick={() =>
                          document.querySelector(".input-img").click()
                        }
                      >
                        <input
                          type="file"
                          accept="image/*"
                          className="input-img"
                          onChange={handleImageChange}
                          hidden
                        />
                        <div className="upload-area">
                          <TbFileUpload className="upload-icon" />
                          <p className="upload-desc">
                            Upload a picture in BMP, JPG, JPEG or PNG format.
                            <br /> Maximum file size: 100MB
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="input-sec">
                  <div className="input-sec-required">
                    <div className="cb-input-title">Website Title</div>
                    <div className="required-cl">*</div>
                  </div>
                  <div className="cl-input-canvas">
                    <input
                      type="text"
                      className="txt-url"
                      placeholder="Title"
                      value={customLinkTitle}
                      onChange={handleCustomTitle}
                      ref={customLinkTitleRef}
                      required
                    />
                  </div>
                  {customLinkTitleError && (
                    <div className="error-msg-custom-link">
                      {customLinkTitleError}
                    </div>
                  )}
                </div>
              </div>
            )}
            {addType === "socialMedia" && (
              <div>
                {isFooterVisible && (
                  <div className="smp-footer">
                    <button
                      className="smp-save"
                      onClick={() => {
                        handleClick(close);
                        handleClickLoading();
                      }}
                      disabled={isLoading}
                    >
                      {isLoading ? <div className="spinner"></div> : "Add"}
                    </button>
                  </div>
                )}
              </div>
            )}
            {addType === "custom" && (
              <div>
                {isFooterVisible && (
                  <div className="smp-footer">
                    <button
                      className="smp-save"
                      onClick={() => {
                        handleCustom(close);
                        handleClickLoading();
                      }}
                      disabled={isLoading}
                    >
                      {isLoading ? <div className="spinner"></div> : "Add"}
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </Popup>
      <ConfirmationMessage
        isConfirmAlert={isConfirmAlert}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
      />
      <ConsoleError
        isConsoleError={isConsoleError}
        consoleError={consoleError}
        closeConsoleError={setIsConsoleError}
      />
    </div>
  );
}

import React, { useState, useRef, useEffect } from "react";
import { RxCross2 } from "react-icons/rx";
import UpgradePlans from "../UpgradePlans/UpgradePlans";
import "./CircleLimitError.css";

export default function CircleLimitError({ closeCircleLimit }) {
  const [isCircleUpgradeCanvas, setIsCircleUpgradeCanvas] = useState(false);
  const circleUpgradeRef = useRef(null);

  //close modal when click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        circleUpgradeRef.current &&
        !circleUpgradeRef.current.contains(event.target)
      ) {
        setIsCircleUpgradeCanvas(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [circleUpgradeRef]);

  return (
    <>
      <div className="circle-limit-error-modal">
        <div className="limit-circle-header">
          <div
            className="limit-close"
            onClick={() => {
              closeCircleLimit(false);
            }}
          >
            <RxCross2 />
          </div>
        </div>
        <div className="limit-circle-body">
          <div className="circle-upgrade-msg">
            You can only add 5 circles with your free plan. Please delete one
            before add an another.
          </div>
          <div className="circle-upgrade-msg">
            <b>Upgrade to the premium version to add more Circles.</b>
          </div>
        </div>
        <div className="limit-circle-footer">
          <div
            className="limit-circle-upgrade"
            onClick={() => {
              setIsCircleUpgradeCanvas(true);
            }}
          >
            Upgrade
          </div>
        </div>
      </div>

      {isCircleUpgradeCanvas && (
        <div ref={circleUpgradeRef}>
          <UpgradePlans closeUpgradeModal={setIsCircleUpgradeCanvas} />
        </div>
      )}
    </>
  );
}

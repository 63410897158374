import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import Cookies from "js-cookie";
import CloseButton from "react-bootstrap/CloseButton";
import Edit from "../../Assets/Edit.svg";
import API_DOMAIN from "../../ApiConfig";
import ConfirmationMessage from "../ConfirmationMessage/ConfirmationMessage";
import ConsoleError from "../ConfirmationMessage/ConsoleError";
import "./BioDescription.css";

export default function BioDescription({ bioDesc, fetchBio }) {
  const [bio, setBio] = useState("");
  const [isBioAdded, setIsBioAdded] = useState(false);
  const [inputBio, setInputBio] = useState("");
  const [charCount, setCharCount] = useState(0);
  const [errorMsg, setErrorMsg] = useState("");
  const [action, setAction] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [consoleError, setConsoleError] = useState("");
  const [isConsoleError, setIsConsoleError] = useState(false);

  const userId = Cookies.get("userId");

  const handleCount = (e) => {
    const { value } = e.target;
    setBio(value);
    setCharCount(value.length);
    setErrorMsg("");
  };

  useEffect(() => {
    if (bioDesc) {
      setBio(bioDesc);
      setIsBioAdded(true);
      setCharCount(bioDesc.length);
      setInputBio(bioDesc);
    }
  }, [bioDesc]);

  //Post bio to API
  const postBio = async () => {
    try {
      const response = await fetch(`${API_DOMAIN}/bio`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId, bio }),
      });
      const result = await response.json();
      if (result.success) {
        setIsBioAdded(true);
        setAction("add");
        setIsVisible(true);
        fetchBio();
      } else {
        if (result.error) {
          setIsConsoleError(true);
          setConsoleError(result.error);
        }
      }
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  //Delete Bio Description
  const deleteBio = async () => {
    try {
      await fetch(`${API_DOMAIN}/bio/delete`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId }),
      });
      setAction("delete");
      setIsVisible(true);
      setIsBioAdded(false);
      setBio("");
      fetchBio();
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  const handleSubmit = (close) => {
    if (!bio.trim()) {
      setErrorMsg("Bio is required.");
      return;
    }
    postBio();

    close();
  };

  const handleUpdate = async (close) => {
    if (!bio.trim()) {
      setErrorMsg("Bio is required.");
      return;
    }
    await postBio();
    setAction("update");
    setIsVisible(true);
    fetchBio();
    close();
  };

  const handleDelete = (close) => {
    deleteBio();
    close();
  };

  return (
    <>
      <Popup
        trigger={
          <button className="bio-description">
            <span className="bio-desc">
              <img src={Edit} alt="" className="edit-icon" />
              {isBioAdded
                ? inputBio
                  ? `${inputBio.slice(0, 90)}${
                      inputBio.length > 90 ? "..." : ""
                    }`
                  : "Bio Description"
                : "Bio Description"}
            </span>
          </button>
        }
        modal
        nested
      >
        {(close) => (
          <div className="bio-popup">
            <div className="bio-popup-header">
              <div className="bp-box">
                <div className="bp-close">
                  <button className="bp-close-btn" onClick={() => close()}>
                    <CloseButton style={{ height: "5px", width: "5px" }} />
                  </button>
                </div>
                <div className="bp-title">
                  <h> Bio Description </h>
                </div>
              </div>
            </div>
            <div className="bio-popup-body">
              <div className="bio-popup-body-canvas">
                <textarea
                  rows={5}
                  cols={35}
                  value={bio}
                  maxLength={150}
                  onChange={(e) => {
                    setBio(e.target.value);
                    handleCount(e);
                  }}
                  placeholder={"Add Your Bio Description Here"}
                  className="bio-body"
                />
                <div className="char-bio">{charCount}/150</div>
              </div>

              {errorMsg && (
                <div className="bio-pb-error-message">{errorMsg}</div>
              )}
            </div>
            <div className="bio-popup-footer">
              {!isBioAdded ? (
                <button className="bp-save" onClick={() => handleSubmit(close)}>
                  Save
                </button>
              ) : (
                <div className="bio-update-delete">
                  <button
                    className="bio-delete"
                    onClick={() => handleDelete(close)}
                  >
                    Delete
                  </button>
                  <button
                    className="bio-update"
                    onClick={() => handleUpdate(close)}
                  >
                    Update
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </Popup>
      <ConfirmationMessage
        functionAdd={action === "add"}
        functionUpdate={action === "update"}
        functionDelete={action === "delete"}
        nameOfFunction={"Bio Description"}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
      />
      <ConsoleError
        consoleError={consoleError}
        isConsoleError={isConsoleError}
        closeConsoleError={setIsConsoleError}
      />
    </>
  );
}

import React, { useState, useEffect } from "react";
import html2canvas from "html2canvas";
import { RxCross2 } from "react-icons/rx";
import { IoSettingsOutline } from "react-icons/io5";
import { MdOutlineContentCopy } from "react-icons/md";
import { PiDownloadSimpleBold } from "react-icons/pi";
import QRCode from "qrcode.react";
import SocialShare from "../SocialShare/SocialShare";
import ConsoleError from "../ConfirmationMessage/ConsoleError";
import "./ShareProfile.css";

export default function ShareProfile({
  closeShareModal,
  username,
  guestUserName,
}) {
  const [copiedText, setCopiedText] = useState("");
  const [editableUserContent, setEditableUserContent] = useState("");
  const [charCountUser, setCharCountUser] = useState("");
  const [charCountGuest, setCharCountGuest] = useState("");
  const [editableGuestContent, setEditableGuestContent] = useState("");
  const [consoleError, setConsoleError] = useState("");
  const [isConsoleError, setIsConsoleError] = useState(false);

  useEffect(() => {
    const userContent = `🎉Join me on Tinly: tinly-front-dev.netfy.app/guest/${username} 🌐Let’s connect, explore, and share the fun! 🔗Be part of my journey today!🚀`;
    const guestContent = `✨Discover the amazing world of ${guestUserName} on Tinly! 📲Click here to connect: tinly-front-dev.netfy.app/guest/${guestUserName} 🔗Let’s make social moments together!`;

    setEditableUserContent(userContent);
    setEditableGuestContent(guestContent);
    setCharCountUser(userContent.length);
    setCharCountGuest(guestContent.length);
  }, [username, guestUserName]);

  const handleUserCount = (e) => {
    const { value } = e.target;
    setEditableUserContent(value);
    setCharCountUser(value.length);
  };

  const handleGuestCount = (e) => {
    const { value } = e.target;
    setEditableGuestContent(value);
    setCharCountGuest(value.length);
  };

  // Copy to clipboard own profile link
  const copyToClipboardUser = () => {
    const textToCopy = `tinly-front-dev.netfy.app/guest/${username}`;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopiedText("Copied");
      })
      .catch((err) => {
        setIsConsoleError(true);
        setConsoleError(err.message || String(err));
        setCopiedText("Failed to copy");
      });
  };

  // Copy to clipboard guest profile link
  const copyToClipboardGuest = () => {
    const textToCopy = `tinly-front-dev.netfy.app/guest/${guestUserName}`;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopiedText("Copied");
      })
      .catch((err) => {
        setIsConsoleError(true);
        setConsoleError(err.message || String(err));
        setCopiedText("Failed to copy");
      });
  };

  // Prevent close modal when click inside
  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  const downloadQRCodeUser = () => {
    const qrCodeElement = document.querySelector(".spp-body-qr-code1");

    html2canvas(qrCodeElement).then((canvas) => {
      const qrCodeURL = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");

      let aEl = document.createElement("a");
      aEl.href = qrCodeURL;
      aEl.download = `tinly-profile-${username}.png`;
      document.body.appendChild(aEl);
      aEl.click();
      document.body.removeChild(aEl);
    });
  };

  const downloadQRCodeGuest = () => {
    const qrCodeElement = document.querySelector(".spp-body-qr-code2");

    html2canvas(qrCodeElement).then((canvas) => {
      const qrCodeURL = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");

      let aEl = document.createElement("a");
      aEl.href = qrCodeURL;
      aEl.download = `tinly-profile-${guestUserName}.png`;
      document.body.appendChild(aEl);
      aEl.click();
      document.body.removeChild(aEl);
    });
  };

  return (
    <>
      <div className="share-profile-popup" onClick={handleModalClick}>
        <div className="spp-header">
          <div className="spp-body-icon">
            <IoSettingsOutline />
          </div>
          <div className="spp-title">Sharing</div>
          <div
            className="spp-close"
            onClick={() => {
              closeShareModal(false);
            }}
          >
            <RxCross2 />
          </div>
        </div>

        <div className="spp-body">
          <div className="spp-copy-url1">
            <div className="spp-url1">{`tinly-front-dev.netfy.app/guest/${username}`}</div>
            <div className="spp-url2">{`tinly-front-dev.netfy.app/guest/${guestUserName}`}</div>

            <div className="spp-url-copyicon1" onClick={copyToClipboardUser}>
              {copiedText ? (
                <div className="copied-text">Copied</div>
              ) : (
                <MdOutlineContentCopy />
              )}
            </div>
            <div className="spp-url-copyicon2" onClick={copyToClipboardGuest}>
              {copiedText ? (
                <div className="copied-text">Copied</div>
              ) : (
                <MdOutlineContentCopy />
              )}
            </div>
          </div>

          <div className="spp-body-qr">
            <div className="spp-body-qr-code1">
              <QRCode
                id="qrCodeEl"
                value={`https://tinly-front-dev.netfy.app/guest/${username}`}
                style={{ height: "100%", width: "100%" }}
              />
            </div>
            <div className="spp-body-qr-code2">
              <QRCode
                id="qrCodeEl"
                value={`https://tinly-front-dev.netfy.app/guest/${guestUserName}`}
                style={{ height: "100%", width: "100%" }}
              />
            </div>

            <div className="download-qr">
              <div className="download-qr-icon1">
                <PiDownloadSimpleBold onClick={downloadQRCodeUser} />
              </div>

              <div className="download-qr-icon2">
                <PiDownloadSimpleBold onClick={downloadQRCodeGuest} />
              </div>
            </div>
          </div>

          <div className="spp-share-msg">
            <div className="spp-share-own">
              <textarea
                rows={4}
                cols={35}
                className="ssm-edit"
                value={editableUserContent}
                maxLength={150}
                onChange={(e) => {
                  setEditableUserContent(e.target.value);
                  handleUserCount(e);
                }}
              />
              <div className="char-social">{charCountUser}/150</div>
            </div>

            <div className="spp-share-other">
              <textarea
                rows={4}
                cols={35}
                className="ssm-edit"
                value={editableGuestContent}
                maxLength={150}
                onChange={(e) => {
                  setEditableGuestContent(e.target.value);
                  handleGuestCount(e);
                }}
              />
              <div className="char-social">{charCountGuest}/150</div>
            </div>
          </div>
        </div>

        <div className="spp-footer">
          <SocialShare
            closeShareModal={closeShareModal}
            linkToShare={`https://tinly-front-dev.netfy.app/guest/${
              username || guestUserName
            }`}
            contentToShare={
              username ? editableUserContent : editableGuestContent
            }
          />
        </div>
      </div>
      <ConsoleError
        isConsoleError={isConsoleError}
        consoleError={consoleError}
        closeConsoleError={setIsConsoleError}
      />
    </>
  );
}

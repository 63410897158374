import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import { RxCross2 } from "react-icons/rx";
import { Circles } from "react-loader-spinner";
import API_DOMAIN from "../../ApiConfig";
import ConsoleError from "../ConfirmationMessage/ConsoleError";

export default function UserConfirm({
  guestUserID,
  accepted,
  fetchedConnections,
  fetchCircles,
  fetchNotificationCount,
}) {
  const [circleChecked, setCircleChecked] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isCirclesCreated, setIsCirclesCreated] = useState(false);
  const [availableCircles, setAvailableCircles] = useState([]);
  const [isAcceptReq, setIsAcceptReq] = useState(false);
  const [consoleError, setConsoleError] = useState("");
  const [isConsoleError, setIsConsoleError] = useState(false);

  const userId = Cookies.get("userId");

  const handleCircleChange = (option) => {
    setCircleChecked(option);
  };

  const handleConnect = () => {
    setIsPopupOpen(true);
  };

  useEffect(() => {
    const fetchCircleData = async () => {
      try {
        const response = await fetch(
          `${API_DOMAIN}/circle/view?userId=${userId}`
        );
        const result = await response.json();
        if (result.success) {
          const { circles } = jwtDecode(result.token);
          const fetchedCircles = circles
            .map((link) => {
              const color = link.color;
              if (color === "N/A") {
                return null;
              } else {
                setIsCirclesCreated(true);
                return {
                  type: link.name,
                };
              }
            })
            .filter(Boolean);
          setAvailableCircles(fetchedCircles);
        }
      } catch (error) {
        if (error.message === "Failed to fetch") {
          setIsConsoleError(true);
          setConsoleError("Unable to connect. Please check back shortly!");
        } else {
          setIsConsoleError(true);
          setConsoleError(error.message);
        }
      }
    };
    fetchCircleData();
  }, [userId]);

  const updateState = async (state) => {
    try {
      const requestBody = {
        userId: userId,
        followerId: guestUserID,
        state: state,
      };
      await fetch(`${API_DOMAIN}/follower/update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
      if (state === "delete") {
        fetchedConnections();
        fetchNotificationCount();
      }
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  const insertFollower = async () => {
    try {
      await fetch(`${API_DOMAIN}/user/circle/save/${userId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId,
          targetUserId: guestUserID,
          circleName: circleChecked,
        }),
      });
      setIsPopupOpen(false);
      fetchNotificationCount();
      fetchCircles();
      setTimeout(() => {
        setIsAcceptReq(false);
        fetchedConnections();
      }, 1500);
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  return (
    <>
      {isAcceptReq ? (
        <div className="added-user">
          You added this user to {circleChecked} circle
        </div>
      ) : (
        <div className="notification-btns">
          <button
            className="notification-confirm"
            onClick={() => {
              handleConnect();
            }}
          >
            Confirm
          </button>
          <button
            className="notification-delete"
            onClick={() => {
              updateState("delete");
            }}
          >
            Delete
          </button>
        </div>
      )}
      <Popup
        open={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        modal
        nested
      >
        {(close) => (
          <div className="add-circle-popup">
            <div className="acp-header">
              <div className="acp-title">Select a circle</div>
              <div className="acp-close" onClick={() => close()}>
                <RxCross2 />
              </div>
            </div>
            <div className="acp-body">
              {isCirclesCreated ? (
                availableCircles.map((link, index) => (
                  <>
                    <div className="created-circle-name" key={index}>
                      <input
                        type="radio"
                        checked={circleChecked === link.type}
                        onChange={() => handleCircleChange(link.type)}
                        className="acp-radio-btn"
                      />
                      <div className="acp-radio-label">{link.type}</div>
                    </div>
                  </>
                ))
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Circles
                    height="50"
                    width="50"
                    color="#09f"
                    ariaLabel="circles-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </div>
              )}
            </div>

            <div className="acp-footer">
              {isCirclesCreated && circleChecked !== "customize" && (
                <div
                  className="acp-select-color-btn"
                  onClick={() => {
                    insertFollower();
                    setIsPopupOpen(false);
                    accepted(true);
                    setIsAcceptReq(true);
                    updateState("confirm");
                  }}
                >
                  Add
                </div>
              )}
            </div>
          </div>
        )}
      </Popup>
      <ConsoleError
        isConsoleError={isConsoleError}
        consoleError={consoleError}
        closeConsoleError={setIsConsoleError}
      />
    </>
  );
}

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import Cookies from "js-cookie";
import "./ManageCircle.css";
import ModifyCircle from "../AddNewCircle/ModifyCircle";
import API_DOMAIN from "../../ApiConfig";
import { jwtDecode } from "jwt-decode";
import MoveUser from "../MoveUser/MoveUser";
import ConsoleError from "../ConfirmationMessage/ConsoleError";
import ConfirmationMessage from "../ConfirmationMessage/ConfirmationMessage";

export default function ManageCircle({
  closeManageCircle,
  circleId,
  circleName,
  fetchCirclesAvailable,
  followerCountChanged,
}) {
  const [searchQuery, setSearchQuery] = useState("");
  const [isRecentConTab, setIsRecentConTab] = useState(true);
  const [userImages, setUserImages] = useState([]);
  const [isShowUserConnect, setIsShowUserConnect] = useState(false);
  const [userIdsToMove, setUserIdsToMove] = useState([]);
  const [connections, setConnections] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [consoleError, setConsoleError] = useState("");
  const [isConsoleError, setIsConsoleError] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isUsersDeleted, setIsUsersDeleted] = useState(false);
  const [action, setAction] = useState("");
  const [isUserSelected, setIsUserSelected] = useState(false);
  const [connectionsCount, setConnectionsCount] = useState();
  const [isConSelected, setIsConSelected] = useState(false);

  const userId = Cookies.get("userId");
  const navigate = useNavigate();

  // useEffect(() => {
  //   fetchFollowers();
  //   fetchProfileImage();

  //   const interval = setInterval(() => {
  //     fetchFollowers();
  //     fetchProfileImage();
  //   }, 5000);

  //   return () => clearInterval(interval);
  // }, [userId, circleName]);

  useEffect(() => {
    if (followerCountChanged) {
      fetchFollowers();
    }
  }, [followerCountChanged]);

  const fetchFollowers = async () => {
    try {
      const response = await fetch(
        `${API_DOMAIN}/user/circle/view/${userId}?circleName=${circleName}`
      );
      const result = await response.json();
      if (result.success) {
        const filteredNames = jwtDecode(result.token);
        const followers = filteredNames.users;

        const followerNames = followers.map((link) => ({
          uname: link.fullName,
          uId: link.userID,
          userName: link.username,
        }));

        // Sort the followerNames array based on uname in alphabetical order
        const sortedNames = followerNames.sort((a, b) =>
          a.uname.localeCompare(b.uname)
        );

        if (sortedNames) {
          setConnectionsCount(sortedNames.length);
        } else {
          setConnectionsCount(0);
        }

        setConnections(sortedNames);

        sortedNames.forEach((followerName) => {
          fetchProfileImage(followerName.uId);
        });
      }
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  const fetchProfileImage = async (idOfUser) => {
    try {
      const response = await fetch(
        `${API_DOMAIN}/profile/image/view?fk_user_id=${idOfUser}`
      );

      if (response.ok) {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.startsWith("image/")) {
          const blob = await response.blob();
          const imageUrl = URL.createObjectURL(blob);
          setUserImages((prevImages) => ({
            ...prevImages,
            [idOfUser]: imageUrl,
          }));
        } else {
          setUserImages((prevImages) => ({
            ...prevImages,
            [idOfUser]: null,
          }));
        }
      }
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  const closeModalAfterDelay = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        closeManageCircle(false);
        resolve();
      }, 3000);

      setIsUsersDeleted(true);
      setIsVisible(true);
    });
  };

  const deleteFollower = async () => {
    try {
      if (userIdsToMove.length === 0) {
        setIsUserSelected(true);
        setAction("Remove");
      } else {
        await fetch(`${API_DOMAIN}/user/circle/delete/${userId}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            users: userIdsToMove,
            circleName,
          }),
        });
        fetchCirclesAvailable();
        closeModalAfterDelay();
      }
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleUserSelect = (userId, isSelected) => {
    setUserIdsToMove((prevUserIds) => {
      if (isSelected) {
        return [...prevUserIds, { targetUserId: userId }];
      } else {
        return prevUserIds.filter((user) => user.targetUserId !== userId);
      }
    });
  };

  //select all the users at once
  useEffect(() => {
    setIsSelectAll(userIdsToMove.length === connections.length);
  }, [userIdsToMove, connections.length]);

  const handleSelectAll = (checked) => {
    setIsSelectAll(checked);
    if (checked) {
      setUserIdsToMove(connections.map((link) => ({ targetUserId: link.uId })));
    } else {
      setUserIdsToMove([]);
    }
  };

  const filteredConnections = connections.filter((connection) =>
    connection.uname.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (isShowUserConnect) {
    return (
      <MoveUser
        isPopup={setIsShowUserConnect}
        moveUserId={userIdsToMove}
        closeManageCircleModal={closeManageCircle}
        fetchCircles={fetchCirclesAvailable}
        nameOfCircle={circleName}
      />
    );
  }

  const handleMoveUserModal = () => {
    if (userIdsToMove.length === 0) {
      setIsUserSelected(true);
      setAction("Move");
    } else {
      setIsShowUserConnect(true);
    }
  };

  return (
    <>
      <div className="manage-circles-modal">
        <div className="mcm-header">
          <div
            className="mcm-close-btn"
            onClick={() => {
              closeManageCircle(false);
            }}
          >
            <RxCross2 />
          </div>
          <div className="mcm-header-sec">
            <div className="mcm-settings-icon">
              <ModifyCircle
                idCircle={circleId}
                nameCircle={circleName}
                fetchAvailableCircles={fetchCirclesAvailable}
                closeManageCircle={closeManageCircle}
              />
            </div>
            <div className="mcm-title">{circleName}</div>
            <div className="mcm-con-count">{connectionsCount}</div>
          </div>
        </div>

        <div className="mcm-body-search">
          <input
            type="text"
            value={searchQuery}
            onChange={handleInputChange}
            placeholder="Search..."
            className="mcm-search-input"
          />
        </div>

        <div className="mcm-body-tabs">
          {connectionsCount > 0 && (
            <div
              className={`mcm-tab-name ${
                isRecentConTab ? "con-tab-active" : ""
              }`}
              onClick={() => {
                setIsRecentConTab(true);
              }}
            >
              All
            </div>
          )}
        </div>
        {filteredConnections.length > 0 ? (
          <div className="mcm-body-connections">
            <div className="mcm-select-all">
              <input
                type="checkbox"
                className="mcm-check-box"
                checked={isSelectAll}
                onChange={(e) => {
                  handleSelectAll(e.target.checked);
                  if (e.target.checked) {
                    setIsConSelected(true);
                  } else {
                    setIsConSelected(false);
                  }
                }}
              />
              {isSelectAll ? (
                <label>Deselect All</label>
              ) : (
                <label>Select All</label>
              )}
            </div>
            {filteredConnections.map((link, index) => (
              <div key={index} className="mcm-connection">
                <input
                  type="checkbox"
                  className="mcm-check-box"
                  checked={userIdsToMove.some(
                    (user) => user.targetUserId === link.uId
                  )}
                  onChange={(e) => {
                    handleUserSelect(link.uId, e.target.checked);
                    if (e.target.checked) {
                      setIsConSelected(true);
                    } else {
                      setIsConSelected(false);
                    }
                  }}
                />
                <div className="mcm-con-photo">
                  {userImages[link.uId] ? (
                    <img
                      src={userImages[link.uId]}
                      alt=""
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "50%",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className="mcm-con-name"
                  onClick={() => {
                    navigate(`/guest/${link.userName}`);
                  }}
                >
                  {link.uname}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="mcm-body-connections">No connections added yet</div>
        )}

        {connectionsCount > 0 && (
          <div className="mcm-footer-btns">
            <div
              onClick={handleMoveUserModal}
              className={`mcm-footer-btn ${
                isConSelected ? "active" : "disable"
              }`}
            >
              Move
            </div>
            <div
              onClick={deleteFollower}
              className={`mcm-footer-btn ${
                isConSelected ? "active" : "disable"
              }`}
            >
              Remove
            </div>
          </div>
        )}
      </div>
      <ConsoleError
        isConsoleError={isConsoleError}
        consoleError={consoleError}
        closeConsoleError={setIsConsoleError}
        closeNoSelectError={setIsUserSelected}
        isUserSelected={isUserSelected}
        action={action}
      />
      <ConfirmationMessage
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        isDeleteUser={isUsersDeleted}
        newCircle={circleName}
      />
    </>
  );
}

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { AiFillHeart } from "react-icons/ai";
import { jwtDecode } from "jwt-decode";
import TinlyLogo from "../../Assets/TinlyLogo.png";
import mavicLogo from "../../Assets/MavicLogo.png";
import API_DOMAIN from "../../ApiConfig";
import "./MainLogin.css";
import ConsoleError from "../../Components/ConfirmationMessage/ConsoleError";

function MainLogin({
  guestUserAdd,
  guestReport,
  guestUsername,
  loginToView,
  site,
  iv,
}) {
  const [consoleError, setConsoleError] = useState("");
  const [isConsoleError, setIsConsoleError] = useState(false);

  const handleSignUpWithMavicsoft = () => {
    // Redirect to Mavicsoft's sign-up page
    window.location.href =
      "https://one.mavicsoft.com/?identity=d4828aeaeec4bba86f327245b94c381f5e2b73e68b89ef3f6fba147c2c144e02";
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const userName = queryParams.get("userName");

  useEffect(() => {
    if (token && userName) {
      fetch(`${API_DOMAIN}/login/fetch/profile`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token, userName }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch the particular profile");
          }
          return response.json();
        })
        .then(async (data) => {
          if (guestUserAdd) {
            window.location.href = `/guest/${guestUsername}`;
          } else if (guestReport) {
            window.location.href = `/guest/${guestUsername}`;
          } else if (loginToView) {
            window.location.href = `/direct/?site=${site}&iv=${iv}`;
          } else {
            if (data.error === "Username already exists") {
              const detailResponse = await fetch(
                `${API_DOMAIN}/login/detail/${userName}`
              );
              const detailResult = await detailResponse.json();

              const userId = detailResult.userId;

              const userResponse = await fetch(
                `${API_DOMAIN}/user/view?userId=${userId}`
              );
              const userResults = await userResponse.json();

              const decodedToken = jwtDecode(userResults.token);
              const username = decodedToken.userName;

              window.location.href = `/@${username}`;
            } else {
              window.location.href = "/getstart";
            }
          }
        })
        .catch((error) => {
          setIsConsoleError(true);
          setConsoleError(error.message);
        });
    }

    const fetchToken = async () => {
      try {
        const response = await fetch(`${API_DOMAIN}/login/detail/${userName}`);
        if (!response.ok) {
          throw new Error("Failed to fetch token and userId");
        }
        const result = await response.json();
        const userId = result.userId;

        Cookies.set("userId", userId);

        const res = await fetch(`${API_DOMAIN}/theme/view?userId=${userId}`);
        const resultTheme = await res.json();
        if (resultTheme.success) {
          const { light, mid, dark } = jwtDecode(resultTheme.token).theme[0]
            .color;
          const themeArray = [light, mid, dark];
          Cookies.set("theme", JSON.stringify(themeArray), { expires: 1 / 12 });
        } else {
          const themeArray = ["#0ddfff", "#1fd093", "#30c027"];
          Cookies.set("theme", JSON.stringify(themeArray), { expires: 1 / 12 });
        }
      } catch (error) {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    };

    if (userName && token) {
      Cookies.set("username", userName);
      Cookies.set("token", token);
      fetchToken();
    }
  }, [token, userName, guestUserAdd, guestUsername, iv, site, loginToView]);

  return (
    <>
      <div className="login-wrapper">
        <div className="container d-flex justify-content-center align-items-center ">
          <div className="top-section">
            <div className="logo">
              <img src={TinlyLogo} alt="logo" />
            </div>
            <div className="mid-section">
              <p className="p1">Beyond Bio </p>
              <p className="p2">Empower Your Digital Persona! </p>
              <p className="p3"> Tinly</p>
            </div>
            <div className="ml-btn">
              <button className="btn" onClick={handleSignUpWithMavicsoft}>
                <img src={mavicLogo} className="btn-img" alt="mavicLogo" />
                <span>Sign in with Mavic One</span>
              </button>
            </div>
          </div>
          <div className="bottom-section">
            <p>
              Made &nbsp;
              <AiFillHeart className="heart-icon" /> &nbsp; with Tinly
            </p>
          </div>
        </div>
      </div>
      <ConsoleError
        isConsoleError={isConsoleError}
        consoleError={consoleError}
        closeConsoleError={setIsConsoleError}
      />
    </>
  );
}
export default MainLogin;

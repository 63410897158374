import React, { useState } from "react";
import Sticker1 from "../../Assets/sticker1.png";
import Sticker2 from "../../Assets/sticker2.svg";
import Sticker3 from "../../Assets/Sticker3.png";
import Sticker4 from "../../Assets/Sticker4.png";
import Sticker5 from "../../Assets/Sticker5.png";
import Sticker6 from "../../Assets/Sticker6.png";
import CloseButton from "react-bootstrap/CloseButton";
import { FaUserCircle } from "react-icons/fa";
import { FaRegHeart } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import HomeLogo from "../../Components/HomeLogo/HomeLogo";
import "./Stickers.css";

export default function Stickers({ userPhoto, isPhoto, setIsStickersActive }) {
  const [isStickerLibraryOpen, setIsStickerLibraryOpen] = useState(true);
  const [isMyCollOpen, setIsMyCollOpen] = useState(false);
  const [isStickerViewOpen, setIsStickerViewOpen] = useState(false);
  const [isAllStickerActive, setIsAllStickerActive] = useState(true);
  const [isNetworkingActive, setIsNetworkingActive] = useState(false);
  const [isEngagementActive, setIsEngagementActive] = useState(false);
  const [isPromotionActive, setIsPromotionActive] = useState(false);
  const [isAnimatedActive, setIsAnimatedActive] = useState(false);

  return (
    <>
      <div className="sticker-popup">
        <div className="sticker-menu-bar">
          <div>
            <HomeLogo />
          </div>
          <div className="smb-title">Stickers</div>
          <div className="sticker-to-home">
            {isPhoto ? (
              <div
                onClick={() => {
                  setIsStickersActive(false);
                }}
              >
                <img src={userPhoto} alt="" className="home-photo" />
              </div>
            ) : (
              <FaUserCircle
                className="sticker-to-home-icon"
                onClick={() => {
                  setIsStickersActive(false);
                }}
              />
            )}
          </div>
        </div>
        <div className="sticker-header">
          <div className="sticker-title">
            <h> Stickers </h>
          </div>

          <div className="sticker-close">
            <button
              className="sticker-close-btn"
              onClick={() => {
                setIsStickersActive(false);
              }}
            >
              <CloseButton style={{ height: "5px", width: "5px" }} />
            </button>
          </div>
        </div>
        <div className="sticker-container">
          <div className="sticker-tabs">
            <div
              className={`sticker-library ${
                isStickerLibraryOpen ? "active-sl" : ""
              }`}
              onClick={() => {
                setIsStickerLibraryOpen(true);
                setIsMyCollOpen(false);
              }}
            >
              Sticker Library
            </div>
            <div
              className={`my-collection ${isMyCollOpen ? "active-mc" : ""}`}
              onClick={() => {
                setIsMyCollOpen(true);
                setIsStickerLibraryOpen(false);
              }}
            >
              My Collection
            </div>
          </div>

          {isStickerLibraryOpen && (
            <>
              <div className="sticker-collection-tabs">
                <div
                  className={`sticker-category ${
                    isAllStickerActive ? "active-category" : ""
                  }`}
                  onClick={() => {
                    setIsAllStickerActive(true);
                    setIsNetworkingActive(false);
                    setIsEngagementActive(false);
                    setIsPromotionActive(false);
                    setIsAnimatedActive(false);
                  }}
                >
                  All
                </div>
                <div
                  className={`sticker-category ${
                    isNetworkingActive ? "active-category" : ""
                  }`}
                  onClick={() => {
                    setIsAllStickerActive(false);
                    setIsNetworkingActive(true);
                    setIsEngagementActive(false);
                    setIsPromotionActive(false);
                    setIsAnimatedActive(false);
                  }}
                >
                  Networking
                </div>
                <div
                  className={`sticker-category ${
                    isEngagementActive ? "active-category" : ""
                  }`}
                  onClick={() => {
                    setIsAllStickerActive(false);
                    setIsNetworkingActive(false);
                    setIsEngagementActive(true);
                    setIsPromotionActive(false);
                    setIsAnimatedActive(false);
                  }}
                >
                  Engagement
                </div>
                <div
                  className={`sticker-category ${
                    isPromotionActive ? "active-category" : ""
                  }`}
                  onClick={() => {
                    setIsAllStickerActive(false);
                    setIsNetworkingActive(false);
                    setIsEngagementActive(false);
                    setIsPromotionActive(true);
                    setIsAnimatedActive(false);
                  }}
                >
                  Promotion
                </div>
                <div
                  className={`sticker-category ${
                    isAnimatedActive ? "active-category" : ""
                  }`}
                  onClick={() => {
                    setIsAllStickerActive(false);
                    setIsNetworkingActive(false);
                    setIsEngagementActive(false);
                    setIsPromotionActive(false);
                    setIsAnimatedActive(true);
                  }}
                >
                  Animated
                </div>
              </div>

              <div
                className={`sticker-body ${isAllStickerActive ? "open" : ""}`}
              >
                <div className="sticker-card-canvas">
                  <div className="sticker-card">
                    <img
                      src={Sticker1}
                      alt="sticker1"
                      className="sticker-card-image-t1"
                    />
                    <div
                      className="sticker-card-view"
                      onClick={() => {
                        setIsStickerViewOpen(true);
                      }}
                    >
                      View
                    </div>
                  </div>
                  <div className="sticker-card">
                    <img
                      src={Sticker2}
                      alt="sticker2"
                      className="sticker-card-image-t1"
                    />
                    <div className="sticker-card-view">View</div>
                  </div>
                  <div className="sticker-card">
                    <img
                      src={Sticker3}
                      alt="sticker3"
                      className="sticker-card-image-t2"
                    />
                    <div className="sticker-card-view">View</div>
                  </div>
                  <div className="sticker-card">
                    <img
                      src={Sticker4}
                      alt="sticker4"
                      className="sticker-card-image-t2"
                    />
                    <div className="sticker-card-view">View</div>
                  </div>
                  <div className="sticker-card">
                    <img
                      src={Sticker5}
                      alt="sticker5"
                      className="sticker-card-image-t2"
                    />
                    <div className="sticker-card-view">View</div>
                  </div>
                  <div className="sticker-card">
                    <img
                      src={Sticker6}
                      alt="sticker6"
                      className="sticker-card-image-t1"
                    />
                    <div className="sticker-card-view">View</div>
                  </div>
                </div>
              </div>
            </>
          )}

          {isMyCollOpen && (
            <div className="sticker-collection-body">
              Your sticker collection is empty!
            </div>
          )}
        </div>
      </div>
      {isStickerViewOpen && (
        <div className="view-sticker-canvas">
          <div className="view-sticker-header">
            <div className="view-sticker-title">
              Design Your Sticker for FREE
            </div>
            <div
              className="view-sticker-close"
              onClick={() => {
                setIsStickerViewOpen(false);
              }}
            >
              <IoMdClose />
            </div>
          </div>
          <div className="view-sticker-body">
            <div className="preview-sticker"></div>
            <div className="customize-sticker">
              <div className="customize-category">Color</div>
              <div className="customize-category">Size</div>
              <div className="customize-category">Aspect Ratio</div>
              <div className="customize-category">Resolution</div>
            </div>
          </div>
          <div className="view-sticker-footer">
            <div className="sticker-print">Print</div>
            <div className="sticker-download">Download</div>
            <div className="add-to-collection">
              <FaRegHeart />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

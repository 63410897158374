import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import CloseButton from "react-bootstrap/CloseButton";
import {
  BsYoutube,
  BsInstagram,
  BsSnapchat,
  BsThreeDots,
  BsTwitterX,
} from "react-icons/bs";
import {
  BiLogoFacebook,
  BiLogoTiktok,
  BiLogoPatreon,
  BiLogoLinkedin,
} from "react-icons/bi";
import { FaPinterestP, FaTumblr } from "react-icons/fa";
import { SlSocialVkontakte } from "react-icons/sl";
import "./SMOptions.css";
import API_DOMAIN from "../../ApiConfig";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import ConsoleError from "../ConfirmationMessage/ConsoleError";
import ConfirmationMessage from "../ConfirmationMessage/ConfirmationMessage";

export default function SMOptions({ type, fetchSMLinks, usernameOptions }) {
  const [updatedUsername, setUpdatedUsername] = useState("");
  const userId = Cookies.get("userId");
  const [consoleError, setConsoleError] = useState("");
  const [isConsoleError, setIsConsoleError] = useState(false);
  const [action, setAction] = useState("");
  const [nameOfTheSocialMedia, setNameOfTheSocialMedia] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [isValidLink, setIsValidLink] = useState(false);
  const [error, setError] = useState("");
  const [isSMChanged, setIsSMChanged] = useState(false);

  useEffect(() => {
    setUpdatedUsername(usernameOptions);
  }, [userId, usernameOptions]);

  const handleEnteredLink = (e) => {
    const enteredLink = e.target.value;
    if (type === "Facebook") {
      const fbUrl = /^(?!.*\.\.)(?!.*\.$)[a-zA-Z0-9.]{5,}$/;
      setUpdatedUsername(enteredLink);
      if (!fbUrl.test(enteredLink)) {
        setIsValidLink(false);
        setError("Enter a valid Facebook Username");
      } else {
        setIsValidLink(true);
        setError("");
      }
    } else if (type === "Instagram") {
      const instaName = /^[a-zA-Z0-9._]{4,30}$/;
      setUpdatedUsername(enteredLink);
      if (!instaName.test(enteredLink)) {
        setIsValidLink(false);
        setError("Enter a valid Instagram Username");
      } else {
        setIsValidLink(true);
        setError("");
      }
    } else if (type === "Youtube") {
      const ytUrl = /^[a-zA-Z0-9_]{3,20}$/;
      setUpdatedUsername(enteredLink);
      if (!ytUrl.test(enteredLink)) {
        setIsValidLink(false);
        setError("Enter a valid Youtube username");
      } else {
        setIsValidLink(true);
        setError("");
      }
    } else if (type === "X") {
      const twitterName = /^[a-zA-Z0-9_]{4,15}$/;
      setUpdatedUsername(enteredLink);
      if (!twitterName.test(enteredLink)) {
        setIsValidLink(false);
        setError("Enter a valid X Username");
      } else {
        setIsValidLink(true);
        setError("");
      }
    } else if (type === "TikTok") {
      const tiktokName = /^(?!.*\.$)[a-zA-Z0-9_.]{4,30}$/;
      setUpdatedUsername(enteredLink);
      if (!tiktokName.test(enteredLink)) {
        setIsValidLink(false);
        setError("Enter a valid Instagram Username");
      } else {
        setIsValidLink(true);
        setError("");
      }
    } else if (type === "Pinterest") {
      const pinterestName = /^(?!.*\.$)[a-zA-Z0-9_.]{4,30}$/;
      setUpdatedUsername(enteredLink);
      if (!pinterestName.test(enteredLink)) {
        setIsValidLink(false);
        setError("Enter a valid Instagram Username");
      } else {
        setIsValidLink(true);
        setError("");
      }
    } else if (type === "Patreon") {
      const patreonName = /^(?!.*\.$)[a-zA-Z0-9_.]{4,30}$/;
      setUpdatedUsername(enteredLink);
      if (!patreonName.test(enteredLink)) {
        setIsValidLink(false);
        setError("Enter a valid Instagram Username");
      } else {
        setIsValidLink(true);
        setError("");
      }
    } else if (type === "Tumblr") {
      const tumblrName = /^(?!.*\.$)[a-zA-Z0-9_.]{4,30}$/;
      setUpdatedUsername(enteredLink);
      if (!tumblrName.test(enteredLink)) {
        setIsValidLink(false);
        setError("Enter a valid Instagram Username");
      } else {
        setIsValidLink(true);
        setError("");
      }
    } else if (type === "VK") {
      const vkUProfile = /^https?:\/\/vk\.com\/id/;
      const vkCommunity = /^https?:\/\/vk\.com\/public/;
      const vkClub = /^https?:\/\/vk\.com\/club/;
      setUpdatedUsername(enteredLink);
      if (
        !vkUProfile.test(enteredLink) ||
        !vkCommunity.test(enteredLink) ||
        !vkClub.test(enteredLink)
      ) {
        setIsValidLink(false);
        setError("Enter a valid VK page ID");
      } else {
        setIsValidLink(true);
        setError("");
      }
    } else if (type === "LinkedIn") {
      const linkedinName = /^https?:\/\/(www\.)?linkedin\.com\/in\//;
      setUpdatedUsername(enteredLink);
      if (!linkedinName.test(enteredLink)) {
        setIsValidLink(false);
        setError("Enter a valid LinkedIn URL(https://www.linkedin.com/in/)");
      } else {
        setIsValidLink(true);
        setError("");
      }
    } else if (type === "Snapchat") {
      const snapchatName = /^(?!.*\.$)[a-zA-Z0-9_.]{4,30}$/;
      const snapchatUrl = /^snapchat\.com\/add\//;
      setUpdatedUsername(enteredLink);
      if (!snapchatName.test(enteredLink) || !snapchatUrl.test(enteredLink)) {
        setIsValidLink(false);
        setError("Enter a valid Snapchat Username or Page ID");
      } else {
        setIsValidLink(true);
        setError("");
      }
    } else {
      setError("");
    }
  };

  const deleteSMLinks = async () => {
    try {
      const response = await fetch(
        `${API_DOMAIN}/socialmedia/delete/${userId}/${type}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        setIsSMChanged(true);
        setIsVisible(true);
        setAction("deleted");
        setNameOfTheSocialMedia(type);
        // Fetch the existing social media links from local storage
        // let existingLinks =
        //   JSON.parse(localStorage.getItem("socialMediaLinks")) || [];

        // // Remove the specific social media link
        // const deletedLinks = existingLinks.filter((link) => link.type !== type);

        // if (deleteSMLinks.length === 0) {
        //   localStorage.removeItem("socialMediaLinks");
        // } else {
        //   // Save the updated list back to local storage
        //   localStorage.setItem(
        //     "socialMediaLinks",
        //     JSON.stringify(deletedLinks)
        //   );
        // }
        fetchSMLinks();
      }
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  //Post Social Media Links
  const updateSMLinks = async () => {
    try {
      if (!error) {
        const updateSocial = [{ type, username: updatedUsername }];
        const response = await fetch(
          `${API_DOMAIN}/socialmedia/update/${userId}/`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              updates: updateSocial,
            }),
          }
        );

        const result = await response.json();

        if (result.success) {
          setIsSMChanged(true);
          setIsVisible(true);
          setAction("updated");
          setNameOfTheSocialMedia(type);
          fetchSMLinksOptions();
          return { isSuccess: true };

          // let existingLinks =
          //   JSON.parse(localStorage.getItem("socialMediaLinks")) || [];
          // // Update the specific social media link
          // const updatedLinks = existingLinks.map((link) =>
          //   link.type === type
          //     ? {
          //         ...link,
          //         smUsername: updatedUsername,
          //       }
          //     : link
          // );
          // localStorage.setItem("socialMediaLinks", JSON.stringify(updatedLinks));
        } else {
          const isNotFound = result.code === 404;

          if (isNotFound) {
            setIsConsoleError(true);
            setConsoleError(result.error);
            return { isSuccess: false };
          } else {
            return { isSuccess: false };
          }
        }
      }
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  const fetchSMLinksOptions = async () => {
    try {
      const response = await fetch(`${API_DOMAIN}/socialmedia/view/${userId}`);
      const result = await response.json();
      if (result.success) {
        const { socialMediaLinks } = jwtDecode(result.token);
        const filteredLinks = socialMediaLinks.find(
          (link) => link.type === type
        );
        if (filteredLinks) {
          setUpdatedUsername(filteredLinks.link);
        }
      }
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  const socialMediaType = (type) => {
    switch (type) {
      case "TikTok":
        return <BiLogoTiktok />;
      case "Facebook":
        return <BiLogoFacebook />;
      case "X":
        return <BsTwitterX />;
      case "Instagram":
        return <BsInstagram />;
      case "Pinterest":
        return <FaPinterestP />;
      case "Patreon":
        return <BiLogoPatreon />;
      case "LinkedIn":
        return <BiLogoLinkedin />;
      case "Tumblr":
        return <FaTumblr />;
      case "VK":
        return <SlSocialVkontakte />;
      case "Snapchat":
        return <BsSnapchat />;
      case "Youtube":
        return <BsYoutube />;
      default:
        return null;
    }
  };

  const handleUpdate = async (close) => {
    const result = await updateSMLinks();
    if (result && result.isSuccess) {
      close();
    }
  };

  const handleDelete = async (close) => {
    await deleteSMLinks();
    close();
  };

  return (
    <>
      <Popup
        trigger={
          <span className="options">
            <BsThreeDots onClick={fetchSMLinksOptions} />
          </span>
        }
        modal
        nested
      >
        {(close) => (
          <div className="options-popup">
            <div className="options-popup-header">
              <div className="options-title">{type}</div>
              <button className="options-close" onClick={() => close()}>
                <CloseButton style={{ height: "5px", width: "5px" }} />
              </button>
            </div>

            <div className="options-popup-body">
              <div className="options-label">Username or Page ID</div>
              <div className="link-area">
                <div className="shortly-icon">{socialMediaType(type)}</div>
                <input
                  type="text"
                  className="link-text"
                  placeholder="Username"
                  value={updatedUsername}
                  onChange={handleEnteredLink}
                />
              </div>
              {isValidLink ? (
                ""
              ) : (
                <div className="error-msg-site-link">{error}</div>
              )}

              <div className="options-btn">
                <div className="remove" onClick={() => handleDelete(close)}>
                  Delete
                </div>
                <div className="update" onClick={() => handleUpdate(close)}>
                  Update
                </div>
              </div>
            </div>
          </div>
        )}
      </Popup>
      <ConsoleError
        isConsoleError={isConsoleError}
        consoleError={consoleError}
        closeConsoleError={setIsConsoleError}
      />
      <ConfirmationMessage
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        isSMChanged={isSMChanged}
        action={action}
        nameOfTheSocialMedia={nameOfTheSocialMedia}
      />
    </>
  );
}

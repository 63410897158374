import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import Cookies from "js-cookie";
import CloseButton from "react-bootstrap/CloseButton";
import Work from "../../Assets/Work.svg";
import API_DOMAIN from "../../ApiConfig";
import ConfirmationMessage from "../ConfirmationMessage/ConfirmationMessage";
import ConsoleError from "../ConfirmationMessage/ConsoleError";
import "./WorkPlace.css";
import InputInfo from "../InputInfo/InputInfo";

export default function WorkPlace({ addedWorkplace, fetchWorkPlace }) {
  const [workplace, setWorkplace] = useState("");
  const [inputWorkPlace, setInputWorkPlace] = useState("");
  const [isWorkplaceAdded, setIsWorkplaceAdded] = useState(false);
  const [workError, setWorkError] = useState("");
  const [action, setAction] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [consoleError, setConsoleError] = useState("");
  const [isConsoleError, setIsConsoleError] = useState(false);

  const userId = Cookies.get("userId");

  //fetch work place
  useEffect(() => {
    if (addedWorkplace) {
      setWorkplace(addedWorkplace);
      setInputWorkPlace(addedWorkplace);
      setIsWorkplaceAdded(true);
    }
  }, [addedWorkplace]);

  //post work place
  const postWorkPlace = async () => {
    try {
      const res = await fetch(`${API_DOMAIN}/work`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ workPlace: workplace, userId }),
      });

      const result = await res.json();
      if (result.success) {
        setIsWorkplaceAdded(true);
        return { isSuccess: true };
      } else {
        const isNotFound = result.code === 404;

        if (isNotFound) {
          setIsConsoleError(true);
          setConsoleError(result.error);
        } else {
          setWorkError(result.error);
        }
      }
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  //delete work place
  const deleteWorkPlace = async () => {
    try {
      await fetch(`${API_DOMAIN}/work/delete`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId }),
      });
      setAction("delete");
      setIsVisible(true);
      setIsWorkplaceAdded(false);
      setWorkplace("");
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  const handleSubmit = async (close) => {
    if (!workplace.trim()) {
      setWorkError("Workplace is required");
      return;
    }
    const result = await postWorkPlace();
    if (result && result.isSuccess) {
      close();
      setAction("add");
      setIsVisible(true);
      fetchWorkPlace();
    }
  };

  const handleUpdate = async (close) => {
    if (!workplace.trim()) {
      setWorkError("Workplace is required");
      return;
    }
    const result = await postWorkPlace();
    if (result && result.isSuccess) {
      close();
      setAction("update");
      setIsVisible(true);
      fetchWorkPlace();
    }
  };

  const handleDelete = (close) => {
    deleteWorkPlace();
    close();
  };

  const validateWorkplace = (e) => {
    const enteredWorkplace = e.target.value;
    setWorkplace(enteredWorkplace);
    const workplace = enteredWorkplace.trim();
    const workplaceRegex = /^[A-Za-z0-9\s\-&()'$]+$/;

    if (workplaceRegex.test(workplace)) {
      setWorkError("");
    } else {
      setWorkError("Enter a valid workplace");
    }
    if (!enteredWorkplace) {
      setWorkError("");
    }
  };

  return (
    <>
      <Popup
        trigger={
          <button className="demographic">
            <img src={Work} alt="" className="demo-icon" />
            <span className="demo-title">
              {isWorkplaceAdded
                ? inputWorkPlace
                  ? `${inputWorkPlace.slice(0, 20)}${
                      inputWorkPlace.length > 20 ? "..." : ""
                    }`
                  : "Workplace"
                : "Workplace"}
            </span>
          </button>
        }
        modal
        nested
      >
        {(close) => (
          <div className="wp-popup">
            <div className="wp-popup-header">
              <div className="wp-box">
                <div className="wp-close">
                  <button className="wp-close-btn" onClick={() => close()}>
                    <CloseButton style={{ height: "5px", width: "5px" }} />
                  </button>
                </div>
                <div className="wp-title">
                  <h> Work Place </h>
                </div>
              </div>
            </div>
            <div className="wp-body">
              <div className="wp-name">
                <input
                  type="text"
                  placeholder="Work Place"
                  value={workplace}
                  onChange={validateWorkplace}
                />
                <InputInfo
                  Description={
                    "Use only letters, numbers, and these characters: - & ( ) ' $"
                  }
                />
              </div>
              {workError && (
                <div className="work-pb-error-message">{workError}</div>
              )}
            </div>
            <div className="wp-footer">
              {!isWorkplaceAdded ? (
                <button className="wp-add" onClick={() => handleSubmit(close)}>
                  Add
                </button>
              ) : (
                <div className="wp-update-delete">
                  <button
                    className="wp-delete"
                    onClick={() => handleDelete(close)}
                  >
                    Delete
                  </button>
                  <button
                    className="wp-update"
                    onClick={() => handleUpdate(close)}
                  >
                    Update
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </Popup>
      <ConfirmationMessage
        functionAdd={action === "add"}
        functionDelete={action === "delete"}
        functionUpdate={action === "update"}
        nameOfFunction="Workplace"
        isVisible={isVisible}
        setIsVisible={setIsVisible}
      />
      <ConsoleError
        isConsoleError={isConsoleError}
        consoleError={consoleError}
        closeConsoleError={setIsConsoleError}
      />
    </>
  );
}

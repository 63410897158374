import React, { useEffect, useRef, useState } from "react";
import Cookies from "js-cookie";
import { RxCross2 } from "react-icons/rx";
import { FaCheck, FaUserFriends, FaSchool } from "react-icons/fa";
import {
  MdOutlinePets,
  MdContactEmergency,
  MdVolunteerActivism,
  MdRealEstateAgent,
  MdOutlineSportsCricket,
  MdShoppingCart,
  MdSchool,
  MdOutlineTheaterComedy,
} from "react-icons/md";
import { GiFamilyHouse, GiMusicalScore, GiLovers } from "react-icons/gi";
import { FaUserDoctor } from "react-icons/fa6";
import { LiaBirthdayCakeSolid, LiaHotelSolid } from "react-icons/lia";
import { IoFitnessSharp, IoRestaurantSharp, IoBusiness } from "react-icons/io5";
import { CgCardClubs } from "react-icons/cg";
import { HiMiniUserGroup } from "react-icons/hi2";
import { SiEventstore, SiFreelancer, SiAwsorganizations } from "react-icons/si";
import { GrTechnology } from "react-icons/gr";
import { IoIosPlanet } from "react-icons/io";
import { RiGovernmentLine } from "react-icons/ri";
import { LuUtilityPole } from "react-icons/lu";
import { CiBank } from "react-icons/ci";
import API_DOMAIN from "../../ApiConfig";
import ConsoleError from "../ConfirmationMessage/ConsoleError";
import "./AddNewCircle.css";

export default function AddNewCircle({
  closeAddNew,
  moveAndDelete,
  idsToMove,
  circleId,
  fetchCircleData,
  closeDeleteCircleModal,
  closeManageCircleModal,
  closeModifyCircleModal,
  fetchCircle,
}) {
  const [selectLightColor, setSelectLightColor] = useState("");
  const [selectDarkColor, setSelectDarkColor] = useState("");
  const [customCircleType, setCustomCircleType] = useState("");
  const [customError, setCustomError] = useState("");
  const [searchStickerQuery, setSearchStickerQuery] = useState("");
  const [isPersonal, setIsPersonal] = useState(true);
  const [isProfessional, setIsProfessional] = useState(false);
  const [isSocial, setIsSocial] = useState(false);
  const [isServices, setIsServices] = useState(false);
  const [isOther, setIsOther] = useState(false);
  const [selectCategory, setSelectCategory] = useState("Personal");
  const [selectIcon, setSelectIcon] = useState("");
  const [consoleError, setConsoleError] = useState("");
  const [isConsoleError, setIsConsoleError] = useState(false);
  const [availableCircles, setAvailableCircles] = useState([]);
  const [isFieldsRequired, setIsFieldsRequired] = useState(false);
  const [error, setError] = useState("");
  const circleNameInputRef = useRef(null);

  const userId = Cookies.get("userId");

  useEffect(() => {
    const fetchAvailableCircles = async () => {
      const response = await fetch(
        `${API_DOMAIN}/customise/circle/get/circle/titles?userId=${userId}`
      );
      const result = await response.json();
      if (result.success) {
        const circlesAvailable = result.circleTitles;
        setAvailableCircles(circlesAvailable);
      }
    };
    fetchAvailableCircles();
  }, [userId]);

  const handleCustomCircleType = (e) => {
    const enteredCustom = e.target.value;
    setCustomCircleType(enteredCustom);
    const customCircle = enteredCustom.trim();
    const circleNameRegex = /^[A-Za-z]+$/;

    if (!circleNameRegex.test(customCircle)) {
      setCustomError("Use only letters, and no spaces are allowed.");
    } else if (customCircle.length > 10) {
      setCustomError("Name should include less than 10 characters.");
    } else {
      const nameOfCircle =
        customCircle.charAt(0).toUpperCase() +
        customCircle.slice(1).toLowerCase();

      const isAvailable = availableCircles.includes(nameOfCircle);
      if (isAvailable) {
        setCustomError("Circle already exists");
      } else {
        setCustomError("");
      }
    }
  };

  const postCustomCircle = async () => {
    try {
      const response = await fetch(`${API_DOMAIN}/customise/circle`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: userId,
          title:
            customCircleType.charAt(0).toUpperCase() +
            customCircleType.slice(1).toLowerCase(),
          color: { light: selectLightColor, dark: selectDarkColor },
          category: selectCategory,
          icon: selectIcon,
        }),
      });
      const result = await response.json();
      if (result.success) {
        if (moveAndDelete) {
          moveUser();
        }
        return { success: true };
      } else {
        const isNotFound = result.code === 404;
        if (isNotFound) {
          setIsConsoleError(true);
          setConsoleError(result.error);
          return { success: false };
        } else {
          setIsFieldsRequired(true);
          setError("All fields are required.");
          setTimeout(() => {
            setIsFieldsRequired(false);
          }, 1000);
          return { success: false };
        }
      }
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  const moveUser = async () => {
    try {
      await fetch(`${API_DOMAIN}/user/circle/update/${userId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          users: idsToMove,
          newCircleName: customCircleType,
        }),
      });
      deleteCircle();
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  const deleteCircle = async () => {
    try {
      await fetch(`${API_DOMAIN}/customise/circle/delete`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: userId,
          circleId: circleId,
        }),
      });
      closeDeleteCircleModal(false);
      closeManageCircleModal(false);
      closeModifyCircleModal();
      fetchCircle();
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  const scrollToFieldWithErrors = () => {
    if (customError && circleNameInputRef.current) {
      circleNameInputRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const handleMoveAndDelete = () => {
    postCustomCircle();
  };

  const handlePostCustomCircle = async () => {
    const result = await postCustomCircle();
    if (!result.success) {
      scrollToFieldWithErrors();
    } else {
      closeAddNew(false);
      fetchCircleData();
    }
  };

  const handleStickerInputChange = (e) => {
    setSearchStickerQuery(e.target.value);
  };

  return (
    <>
      <div className="add-circle-popup">
        <div className="acp-header">
          <div className="acp-title">Create a Circle</div>
          <div
            className="acp-close"
            onClick={() => {
              closeAddNew(false);
            }}
          >
            <RxCross2 />
          </div>
        </div>
        <div className="acp-body-canvas">
          <div className="acp-body-container">
            <div className="ccp-new-circle-name">
              <div className="input-circle-canvas">
                <div className="ccp-new-name-label">Enter circle name</div>
                <div className="required-circle-name">*</div>
              </div>
              <input
                type="text"
                ref={circleNameInputRef}
                className="ccp-new-name-input"
                placeholder="Circle name"
                value={customCircleType}
                onChange={handleCustomCircleType}
              />
              {customError && (
                <div className="error-msg-custom-link">{customError}</div>
              )}
            </div>
            <div className="input-circle-canvas">
              <div className="ccp-body-title">Circle Color</div>
              <div className="required-circle-name">*</div>
            </div>

            <div className="ccp-color-container">
              <div
                className="ccp-color-box"
                onClick={() => {
                  setSelectLightColor("#faff00");
                  setSelectDarkColor("#dc7f19");
                }}
              >
                {selectLightColor === "#faff00" &&
                  selectDarkColor === "#dc7f19" && (
                    <div className="ccp-color-select">
                      <FaCheck />
                    </div>
                  )}
              </div>
              <div
                className="ccp-color-box"
                onClick={() => {
                  setSelectLightColor("#01e6ff");
                  setSelectDarkColor("#0004ff");
                }}
              >
                {selectLightColor === "#01e6ff" &&
                  selectDarkColor === "#0004ff" && (
                    <div className="ccp-color-select">
                      <FaCheck />
                    </div>
                  )}
              </div>
              <div
                className="ccp-color-box"
                onClick={() => {
                  setSelectLightColor("#e4c0e1");
                  setSelectDarkColor("#5e3c83");
                }}
              >
                {selectLightColor === "#e4c0e1" &&
                  selectDarkColor === "#5e3c83" && (
                    <div className="ccp-color-select">
                      <FaCheck />
                    </div>
                  )}
              </div>
              <div
                className="ccp-color-box"
                onClick={() => {
                  setSelectLightColor("#9eff00");
                  setSelectDarkColor("#0e883b");
                }}
              >
                {selectLightColor === "#9eff00" &&
                  selectDarkColor === "#0e883b" && (
                    <div className="ccp-color-select">
                      <FaCheck />
                    </div>
                  )}
              </div>
              <div
                className="ccp-color-box"
                onClick={() => {
                  setSelectLightColor("#ffaccf");
                  setSelectDarkColor("#af1517");
                }}
              >
                {selectLightColor === "#ffaccf" &&
                  selectDarkColor === "#af1517" && (
                    <div className="ccp-color-select">
                      <FaCheck />
                    </div>
                  )}
              </div>
            </div>

            <div className="input-circle-canvas">
              <div className="ccp-body-title">Circle Icon</div>
              <div className="required-circle-name">*</div>
            </div>

            <div className="ccp-sticker-container">
              <div className="ccp-sticker-box">
                <div className="ccp-search">
                  <div className="ccp-body-search">
                    <input
                      type="text"
                      value={searchStickerQuery}
                      onChange={handleStickerInputChange}
                      placeholder="Search..."
                      className="ccp-search-input"
                    />
                  </div>
                </div>
                <div className="ccp-icons-container">
                  <div className="ccp-icon-set">
                    <div
                      className={`ccp-icon-type ${
                        isPersonal ? "active-icons" : ""
                      }`}
                      onClick={() => {
                        setIsPersonal(true);
                        setIsProfessional(false);
                        setIsSocial(false);
                        setIsServices(false);
                        setIsOther(false);
                        setSelectCategory("Personal");
                      }}
                    >
                      Personal
                    </div>
                    <div
                      className={`ccp-icon-type ${
                        isProfessional ? "active-icons" : ""
                      }`}
                      onClick={() => {
                        setIsPersonal(false);
                        setIsProfessional(true);
                        setIsSocial(false);
                        setIsServices(false);
                        setIsOther(false);
                        setSelectCategory("Professional");
                      }}
                    >
                      Professional
                    </div>
                    <div
                      className={`ccp-icon-type ${
                        isSocial ? "active-icons" : ""
                      }`}
                      onClick={() => {
                        setIsPersonal(false);
                        setIsProfessional(false);
                        setIsSocial(true);
                        setIsServices(false);
                        setIsOther(false);
                        setSelectCategory("Social");
                      }}
                    >
                      Social
                    </div>
                    <div
                      className={`ccp-icon-type ${
                        isServices ? "active-icons" : ""
                      }`}
                      onClick={() => {
                        setIsPersonal(false);
                        setIsProfessional(false);
                        setIsSocial(false);
                        setIsServices(true);
                        setIsOther(false);
                        setSelectCategory("Services");
                      }}
                    >
                      Services
                    </div>
                    <div
                      className={`ccp-icon-type ${
                        isOther ? "active-icons" : ""
                      }`}
                      onClick={() => {
                        setIsPersonal(false);
                        setIsProfessional(false);
                        setIsSocial(false);
                        setIsServices(false);
                        setIsOther(true);
                        setSelectCategory("Other");
                      }}
                    >
                      Other
                    </div>
                  </div>
                  <div className="icons-based-on-type">
                    {isPersonal && (
                      <div className="ccp-icons-row">
                        <div
                          className="ccp-icons"
                          onClick={() => {
                            setSelectIcon("Friends");
                          }}
                        >
                          <div
                            className={`ccp-icon ${
                              selectIcon === "Friends" ? "active" : ""
                            }`}
                          >
                            <FaUserFriends />
                          </div>
                          <div className="ccp-icon-name">Friends</div>
                        </div>
                        <div
                          className="ccp-icons"
                          onClick={() => {
                            setSelectIcon("Family");
                          }}
                        >
                          <div
                            className={`ccp-icon ${
                              selectIcon === "Family" ? "active" : ""
                            }`}
                          >
                            <GiFamilyHouse />
                          </div>
                          <div className="ccp-icon-name">Family</div>
                        </div>
                        <div
                          className="ccp-icons"
                          onClick={() => {
                            setSelectIcon("Pets");
                          }}
                        >
                          <div
                            className={`ccp-icon ${
                              selectIcon === "Pets" ? "active" : ""
                            }`}
                          >
                            <MdOutlinePets />
                          </div>
                          <div className="ccp-icon-name">Pets</div>
                        </div>
                        <div
                          className="ccp-icons"
                          onClick={() => {
                            setSelectIcon("Doctors");
                          }}
                        >
                          <div
                            className={`ccp-icon ${
                              selectIcon === "Doctors" ? "active" : ""
                            }`}
                          >
                            <FaUserDoctor />
                          </div>
                          <div className="ccp-icon-name">Doctors</div>
                        </div>
                        <div
                          className="ccp-icons"
                          onClick={() => {
                            setSelectIcon("Emergency");
                          }}
                        >
                          <div
                            className={`ccp-icon ${
                              selectIcon === "Emergency" ? "active" : ""
                            }`}
                          >
                            <MdContactEmergency />
                          </div>
                          <div className="ccp-icon-name">Emergency</div>
                        </div>
                        <div
                          className="ccp-icons"
                          onClick={() => {
                            setSelectIcon("Birthdays");
                          }}
                        >
                          <div
                            className={`ccp-icon ${
                              selectIcon === "Birthdays" ? "active" : ""
                            }`}
                          >
                            <LiaBirthdayCakeSolid />
                          </div>
                          <div className="ccp-icon-name">Birthdays</div>
                        </div>
                        <div
                          className="ccp-icons"
                          onClick={() => {
                            setSelectIcon("Fitness");
                          }}
                        >
                          <div
                            className={`ccp-icon ${
                              selectIcon === "Fitness" ? "active" : ""
                            }`}
                          >
                            <IoFitnessSharp />
                          </div>
                          <div className="ccp-icon-name">Fitness</div>
                        </div>
                      </div>
                    )}
                    {isProfessional && (
                      <div className="ccp-icons-row">
                        <div
                          className="ccp-icons"
                          onClick={() => {
                            setSelectIcon("Business");
                          }}
                        >
                          <div
                            className={`ccp-icon ${
                              selectIcon === "Business" ? "active" : ""
                            }`}
                          >
                            <IoBusiness />
                          </div>
                          <div className="ccp-icon-name">Business</div>
                        </div>
                        <div
                          className="ccp-icons"
                          onClick={() => {
                            setSelectIcon("Real Estate");
                          }}
                        >
                          <div
                            className={`ccp-icon ${
                              selectIcon === "Real Estate" ? "active" : ""
                            }`}
                          >
                            <MdRealEstateAgent />
                          </div>
                          <div className="ccp-icon-name">Real Estate</div>
                        </div>
                        <div
                          className="ccp-icons"
                          onClick={() => {
                            setSelectIcon("Technology");
                          }}
                        >
                          <div
                            className={`ccp-icon ${
                              selectIcon === "Technology" ? "active" : ""
                            }`}
                          >
                            <GrTechnology />
                          </div>
                          <div className="ccp-icon-name">Technology</div>
                        </div>
                        <div
                          className="ccp-icons"
                          onClick={() => {
                            setSelectIcon("Freelancers");
                          }}
                        >
                          <div
                            className={`ccp-icon ${
                              selectIcon === "Freelancers" ? "active" : ""
                            }`}
                          >
                            <SiFreelancer />
                          </div>
                          <div className="ccp-icon-name">Freelancers</div>
                        </div>
                      </div>
                    )}
                    {isSocial && (
                      <div className="ccp-icons-row">
                        <div
                          className="ccp-icons"
                          onClick={() => {
                            setSelectIcon("Social Media");
                          }}
                        >
                          <div
                            className={`ccp-icon ${
                              selectIcon === "Social Media" ? "active" : ""
                            }`}
                          >
                            <IoIosPlanet />
                          </div>
                          <div className="ccp-icon-name">Social Media</div>
                        </div>
                        <div
                          className="ccp-icons"
                          onClick={() => {
                            setSelectIcon("Events");
                          }}
                        >
                          <div
                            className={`ccp-icon ${
                              selectIcon === "Events" ? "active" : ""
                            }`}
                          >
                            <SiEventstore />
                          </div>
                          <div className="ccp-icon-name">Events</div>
                        </div>
                        <div
                          className="ccp-icons"
                          onClick={() => {
                            setSelectIcon("Teams");
                          }}
                        >
                          <div
                            className={`ccp-icon ${
                              selectIcon === "Teams" ? "active" : ""
                            }`}
                          >
                            <HiMiniUserGroup />
                          </div>
                          <div className="ccp-icon-name">Teams</div>
                        </div>
                        <div
                          className="ccp-icons"
                          onClick={() => {
                            setSelectIcon("Volunteers");
                          }}
                        >
                          <div
                            className={`ccp-icon ${
                              selectIcon === "Volunteers" ? "active" : ""
                            }`}
                          >
                            <MdVolunteerActivism />
                          </div>
                          <div className="ccp-icon-name">Volunteers</div>
                        </div>
                        <div
                          className="ccp-icons"
                          onClick={() => {
                            setSelectIcon("Clubs");
                          }}
                        >
                          <div
                            className={`ccp-icon ${
                              selectIcon === "Clubs" ? "active" : ""
                            }`}
                          >
                            <CgCardClubs />
                          </div>
                          <div className="ccp-icon-name">Clubs</div>
                        </div>
                        <div
                          className="ccp-icons"
                          onClick={() => {
                            setSelectIcon("Company");
                          }}
                        >
                          <div
                            className={`ccp-icon ${
                              selectIcon === "Company" ? "active" : ""
                            }`}
                          >
                            <SiAwsorganizations />
                          </div>
                          <div className="ccp-icon-name">Company</div>
                        </div>
                      </div>
                    )}

                    {isServices && (
                      <div className="ccp-icons-row">
                        <div
                          className="ccp-icons"
                          onClick={() => {
                            setSelectIcon("Government");
                          }}
                        >
                          <div
                            className={`ccp-icon ${
                              selectIcon === "Government" ? "active" : ""
                            }`}
                          >
                            <RiGovernmentLine />
                          </div>
                          <div className="ccp-icon-name">Government</div>
                        </div>
                        <div
                          className="ccp-icons"
                          onClick={() => {
                            setSelectIcon("Bank");
                          }}
                        >
                          <div
                            className={`ccp-icon ${
                              selectIcon === "Bank" ? "active" : ""
                            }`}
                          >
                            <CiBank />
                          </div>
                          <div className="ccp-icon-name">Bank</div>
                        </div>
                        <div
                          className="ccp-icons"
                          onClick={() => {
                            setSelectIcon("Utilities");
                          }}
                        >
                          <div
                            className={`ccp-icon ${
                              selectIcon === "Utilities" ? "active" : ""
                            }`}
                          >
                            <LuUtilityPole />
                          </div>
                          <div className="ccp-icon-name">Utilities</div>
                        </div>
                        <div
                          className="ccp-icons"
                          onClick={() => {
                            setSelectIcon("Restaurants");
                          }}
                        >
                          <div
                            className={`ccp-icon ${
                              selectIcon === "Restaurants" ? "active" : ""
                            }`}
                          >
                            <IoRestaurantSharp />
                          </div>
                          <div className="ccp-icon-name">Restaurants</div>
                        </div>
                        <div
                          className="ccp-icons"
                          onClick={() => {
                            setSelectIcon("Hotels");
                          }}
                        >
                          <div
                            className={`ccp-icon ${
                              selectIcon === "Hotels" ? "active" : ""
                            }`}
                          >
                            <LiaHotelSolid />
                          </div>
                          <div className="ccp-icon-name">Hotels</div>
                        </div>
                      </div>
                    )}
                    {isOther && (
                      <div className="ccp-icons-row">
                        <div
                          className="ccp-icons"
                          onClick={() => {
                            setSelectIcon("School");
                          }}
                        >
                          <div
                            className={`ccp-icon ${
                              selectIcon === "School" ? "active" : ""
                            }`}
                          >
                            <FaSchool />
                          </div>
                          <div className="ccp-icon-name">School</div>
                        </div>
                        <div
                          className="ccp-icons"
                          onClick={() => {
                            setSelectIcon("University");
                          }}
                        >
                          <div
                            className={`ccp-icon ${
                              selectIcon === "University" ? "active" : ""
                            }`}
                          >
                            <MdSchool />
                          </div>
                          <div className="ccp-icon-name">University</div>
                        </div>
                        <div
                          className="ccp-icons"
                          onClick={() => {
                            setSelectIcon("Sports");
                          }}
                        >
                          <div
                            className={`ccp-icon ${
                              selectIcon === "Sports" ? "active" : ""
                            }`}
                          >
                            <MdOutlineSportsCricket />
                          </div>
                          <div className="ccp-icon-name">Sports</div>
                        </div>
                        <div
                          className="ccp-icons"
                          onClick={() => {
                            setSelectIcon("Music");
                          }}
                        >
                          <div
                            className={`ccp-icon ${
                              selectIcon === "Music" ? "active" : ""
                            }`}
                          >
                            <GiMusicalScore />
                          </div>
                          <div className="ccp-icon-name">Music</div>
                        </div>

                        <div
                          className="ccp-icons"
                          onClick={() => {
                            setSelectIcon("Shopping");
                          }}
                        >
                          <div
                            className={`ccp-icon ${
                              selectIcon === "Shopping" ? "active" : ""
                            }`}
                          >
                            <MdShoppingCart />
                          </div>
                          <div className="ccp-icon-name">Shopping</div>
                        </div>
                        <div
                          className="ccp-icons"
                          onClick={() => {
                            setSelectIcon("Partners");
                          }}
                        >
                          <div
                            className={`ccp-icon ${
                              selectIcon === "Partners" ? "active" : ""
                            }`}
                          >
                            <GiLovers />
                          </div>
                          <div className="ccp-icon-name">Partners</div>
                        </div>
                        <div
                          className="ccp-icons"
                          onClick={() => {
                            setSelectIcon("Comedy");
                          }}
                        >
                          <div
                            className={`ccp-icon ${
                              selectIcon === "Comedy" ? "active" : ""
                            }`}
                          >
                            <MdOutlineTheaterComedy />
                          </div>
                          <div className="ccp-icon-name">Comedy</div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="acp-footer">
          {moveAndDelete ? (
            <div>
              <div
                className="acp-select-color-btn"
                onClick={handleMoveAndDelete}
              >
                Create & Move
              </div>
            </div>
          ) : (
            <div>
              <div
                className="acp-select-color-btn"
                onClick={handlePostCustomCircle}
              >
                Create Circle
              </div>
            </div>
          )}
          {isFieldsRequired && <div className="name-count-error">{error}</div>}
        </div>
      </div>
      <ConsoleError
        isConsoleError={isConsoleError}
        consoleError={consoleError}
        closeConsoleError={setIsConsoleError}
      />
    </>
  );
}

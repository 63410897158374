import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import Lottie from "react-lottie";
import Cookies from "js-cookie";
import animationData from "../../Lottie/ConnectionIcon1.json";
import { jwtDecode } from "jwt-decode";
import { BsYoutube, BsInstagram, BsSnapchat, BsTwitterX } from "react-icons/bs";
import {
  BiLogoFacebook,
  BiLogoTiktok,
  BiLogoPatreon,
  BiLogoLinkedin,
} from "react-icons/bi";
import { TbWorld } from "react-icons/tb";
import { SlSocialVkontakte, SlOptions } from "react-icons/sl";
import { RxCross2 } from "react-icons/rx";
import { CiGlobe, CiBank } from "react-icons/ci";
import {
  RiLogoutCircleRLine,
  RiEmojiStickerLine,
  RiGovernmentLine,
} from "react-icons/ri";
import {
  FaUserFriends,
  FaSchool,
  FaPinterestP,
  FaTumblr,
  FaRegEye,
} from "react-icons/fa";
import {
  MdOutlinePets,
  MdContactEmergency,
  MdVolunteerActivism,
  MdRealEstateAgent,
  MdOutlineSportsCricket,
  MdShoppingCart,
  MdSchool,
  MdOutlineTheaterComedy,
  MdOutlineWidgets,
} from "react-icons/md";
import { GiFamilyHouse, GiMusicalScore, GiLovers } from "react-icons/gi";
import { FaUserDoctor, FaUnlockKeyhole } from "react-icons/fa6";
import {
  LiaBirthdayCakeSolid,
  LiaHotelSolid,
  LiaUserTieSolid,
} from "react-icons/lia";
import {
  IoFitnessSharp,
  IoRestaurantSharp,
  IoBusiness,
  IoSettingsOutline,
} from "react-icons/io5";
import { CgCardClubs } from "react-icons/cg";
import { HiMiniUserGroup } from "react-icons/hi2";
import { SiEventstore, SiFreelancer, SiAwsorganizations } from "react-icons/si";
import { GrTechnology } from "react-icons/gr";
import { IoIosPlanet } from "react-icons/io";
import { LuUtilityPole } from "react-icons/lu";
import PhoneW from "../../Assets/PhoneW.png";
import AddressW from "../../Assets/AddressW.png";
import EmailW from "../../Assets/EmailW.png";
import WhatsappW from "../../Assets/WhatsappW.png";
import TelegramW from "../../Assets/TelegramW.png";
import Logo from "../../Assets/TinlyLogo.png";
import Share from "../../Assets/Share.svg";
import Shortlys from "../../Assets/Shortlys.svg";
import Circles from "../../Assets/Circle.svg";
import Fab from "../../Assets/Fab.svg";
import PhoneHome from "../../Assets/PhoneHome.png";
import User from "../../Assets/User.svg";
import API_DOMAIN from "../../ApiConfig";
import Footer from "../../Components/Footer/Footer";
import BioDescription from "../../Components/BioDescription/BioDescription";
import UserLocation from "../../Components/Location/UserLocation";
import UserDesignation from "../../Components/UserDesignation/UserDesignation";
import WorkPlace from "../../Components/WorkPlace/WorkPlace";
import ProfileImage from "../../Components/ProfileImage/ProfileImage";
import Notification from "../../Components/Notification/Notification";
import Settings from "../../Components/Settings/Settings";
import ShareProfile from "../../Components/ShareProfile/ShareProfile";
import QRReader from "../../Components/QRReader/QRReader";
import Sites from "../../Assets/Sites.svg";
import AddCircles from "../../Assets/AddCircles.png";
import QR from "../../Assets/Qr.svg";
import AddSites from "../../Components/AddSites/AddSites";
import SitesOption from "../../Components/SitesOption/SitesOption";
import AddNewCircle from "../../Components/AddNewCircle/AddNewCircle";
import Loader from "../../Components/Loader/Loader";
import "./DefaultProfile.css";
import SMOptions from "../../Components/SMOptions/SMOptions";
import ManageCircle from "../../Components/ManageCircle/ManageCircle";
import SMLinks from "../../Components/SMLinks/SMLinks";
import PrivateLinks from "../../Components/PrivateLinks/PrivateLinks";
import CircleLimitError from "../../Components/CircleLimitError/CircleLimitError";
import CustomLinkOption from "../../Components/CustomLinkOption/CustomLinkOption";
import Stickers from "../../Components/Stickers/Stickers";
import ConsoleError from "../../Components/ConfirmationMessage/ConsoleError";

export default function DefaultProfile({ userId }) {
  const [isFabOpen, setIsFabOpen] = useState(false);
  const [fullName, setFullName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [profileUsername, setProfileUsername] = useState("");
  const [email, setEmail] = useState("");
  const [isSMAdded, setIsSMAdded] = useState(false);
  const [username, setUsername] = useState("");
  const [isModalSites, setIsModalSites] = useState(false);
  const [isModalOptions, setIsModalOptions] = useState(false);
  const [isModalSettings, setIsModalSettings] = useState(false);
  const [isModalShareOther, setIsModalShareOther] = useState(false);
  const [isModalShareOwn, setIsModalShareOwn] = useState(false);
  const [isNavbar, setIsNavbar] = useState(false);
  const [isModalScanQr, setIsModalScanQr] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState([]);
  const [siteLink, setSiteLink] = useState("");
  const [siteTitle, setSiteTitle] = useState("");
  const [isSiteAdded, setIsSiteAdded] = useState(false);
  const [isPhoto, setIsPhoto] = useState(false);
  const [userPhoto, setUserPhoto] = useState("");
  const [isShortlys, setIsShortlys] = useState(true);
  const [isCircles, setIsCircles] = useState(false);
  const [circleMemberCount, setCircleMemberCount] = useState({});
  const isModalSitesRef = useRef(null);
  const isModalOptionsRef = useRef(null);
  const isModalSettingsRef = useRef(null);
  const isModalShareOtherRef = useRef(null);
  const isModalShareOwnRef = useRef(null);
  const modalScanRef = useRef(null);
  const modalPlViewRef = useRef(null);
  const modalCircleLimit = useRef(null);
  const [privacy, setPrivacy] = useState("");
  const [isCircleAdd, setIsCircleAdd] = useState(false);
  const [circlesView, setCirclesView] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [circleCount, setCircleCount] = useState("");
  const [followerCount, setFollowerCount] = useState();
  const [isShortlysAdd, setIsShortlysAdd] = useState(false);
  const [isManageCircleModal, setIsManageCircleModal] = useState(false);
  const [circleId, setCircleId] = useState("");
  const [socialMediaLinks, setSocialMediaLinks] = useState([]);
  const [circleName, setCircleName] = useState("");
  const [isCircleLimitExceed, setIsCircleLimitExceed] = useState(false);
  const [isAddNewCircle, setIsAddNewCircle] = useState(false);
  const [isLogoutConfirm, setIsLogoutConfirm] = useState(false);
  const [isPlViewModal, setIsPlViewModal] = useState(false);
  const [isPLAdded, setPLAdded] = useState(false);
  const [privateLinks, setPrivateLinks] = useState([]);
  const [addedPrivateLinks, setAddedPrivateLinks] = useState([]);
  const [template, setTemplate] = useState("");
  const [customImages, setCustomImages] = useState({});
  const [customLinks, setCustomLinks] = useState([]);
  const [isCustomLinkAdded, setIsCustomLinkAdded] = useState(false);
  const [isStickersActive, setIsStickersActive] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const [circleTemplateColor, setCircleTemplateColor] = useState("");
  const [circleFontColor, setCircleFontColor] = useState("");
  const [consoleError, setConsoleError] = useState("");
  const [isConsoleError, setIsConsoleError] = useState(false);
  const [followerCountChanged, setFollowerCountChanged] = useState(false);
  const [hasDisplayedNetworkError, setHasDisplayedNetworkError] =
    useState(false);
  const [bio, setBio] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [position, setPosition] = useState("");
  const [workplace, setWorkplace] = useState("");
  const [addedSMLinks, setAddedSMLinks] = useState([]);
  const [image, setImage] = useState("");
  const [notificationCount, setNotificationCount] = useState("");

  const handleClickCircle = (index) => {
    if (activeIndex === index) {
      setIsManageCircleModal(true);
    } else {
      setActiveIndex(index);
      updateIndicatorStyle(circlesView[index]);
    }
  };

  //preload FAB icons
  const preloadImages = (src) => {
    const img = new Image();
    img.src = src;
  };

  const handleNetworkError = (error) => {
    if (error.message === "Failed to fetch" && !hasDisplayedNetworkError) {
      setIsConsoleError(true);
      setConsoleError(`Unable to connect. Please check back shortly!`);
      setHasDisplayedNetworkError(true);
    }
  };

  useEffect(() => {
    setIsConsoleError(false);
    setHasDisplayedNetworkError(false);
  }, [userId]);

  useEffect(() => {
    preloadImages(Sites);
    preloadImages(AddCircles);
    preloadImages(QR);
    preloadImages(Share);
  }, []);

  Cookies.remove("username");
  const { usernameOfTheUser } = useParams();
  const cleanUsername = usernameOfTheUser.slice(1);

  //fetch data while API call
  const fetchDataWithLoading = async (apiCall) => {
    setIsLoading(true);
    try {
      await apiCall();
    } catch (error) {
      handleNetworkError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNotificationCount();

    const interval = setInterval(() => {
      fetchNotificationCount();
    }, 5000);

    return () => clearInterval(interval);
  }, [userId]);

  useEffect(() => {
    const checkUsername = async () => {
      const response = await fetch(`${API_DOMAIN}/user/get/usernames/all`);
      const result = await response.json();

      if (result.success) {
        const availableUsernames = result.usernames;

        const res = await fetch(`${API_DOMAIN}/user/view?userId=${userId}`);
        const results = await res.json();

        if (results.success) {
          const { userName } = jwtDecode(results.token);
          const isSameUsername = userName === cleanUsername;
          const isAvailable = availableUsernames.includes(cleanUsername);

          if (isAvailable && isSameUsername) {
            fetchDataWithLoading(fetchAllData);
          } else {
            setIsConsoleError(true);
            setConsoleError("Enter a valid username");
          }
        }
      }
    };

    checkUsername();
  }, [cleanUsername, userId]);

  const setPLType = (iconPl) => {
    switch (iconPl) {
      case "Phone home":
        return (
          <div className="pl-icon-sec">
            <img src={PhoneHome} alt="" className="pl-icon-img" />
          </div>
        );
      case "Phone mobile":
        return (
          <div className="pl-icon-sec">
            <img src={PhoneW} alt="" className="pl-icon-img" />
          </div>
        );

      case "Email Address":
        return (
          <div className="pl-icon-sec">
            <img src={EmailW} alt="" className="pl-icon-img" />
          </div>
        );
      case "Mailing Address":
        return (
          <div className="pl-icon-sec">
            <img src={AddressW} alt="" className="pl-icon-img" />
          </div>
        );
      case "Whatsapp":
        return (
          <div className="pl-icon-sec">
            <img src={WhatsappW} alt="" className="pl-icon-img" />
          </div>
        );
      case "Telegram":
        return (
          <div className="pl-icon-sec">
            <img src={TelegramW} alt="" className="pl-icon-img" />
          </div>
        );
      case "Viber":
        return (
          <div className="pl-icon-sec-rio">
            <img
              src="https://img.icons8.com/external-tal-revivo-bold-tal-revivo/96/FFFFFF/external-viber-logo-with-hand-phone-receiver-under-chat-bubble-logo-bold-tal-revivo.png"
              alt="external-viber-logo-with-hand-phone-receiver-under-chat-bubble-logo-bold-tal-revivo"
              className="pl-icon-img-rio"
            />
          </div>
        );
      case "WeChat":
        return (
          <div className="pl-icon-sec-rio">
            <img
              src="https://img.icons8.com/external-tal-revivo-bold-tal-revivo/96/FFFFFF/external-wechat-worlds-largest-standalone-mobile-apps-by-monthly-active-users-logo-bold-tal-revivo.png"
              alt="external-wechat-worlds-largest-standalone-mobile-apps-by-monthly-active-users-logo-bold-tal-revivo"
              className="pl-icon-img-rio"
            />
          </div>
        );
      case "On-Request":
        return <FaUnlockKeyhole className="pl-icon-request" />;
      default:
        return null;
    }
  };

  const getIconByType = (type) => {
    switch (type) {
      case "Friends":
        return <FaUserFriends className="circle-icon" />;
      case "Family":
        return <GiFamilyHouse className="circle-icon" />;
      case "Pets":
        return <MdOutlinePets className="circle-icon" />;
      case "Doctors":
        return <FaUserDoctor className="circle-icon" />;
      case "Emergency":
        return <MdContactEmergency className="circle-icon" />;
      case "Birthdays":
        return <LiaBirthdayCakeSolid className="circle-icon" />;
      case "Fitness":
        return <IoFitnessSharp className="circle-icon" />;
      case "Business":
        return <IoBusiness className="circle-icon" />;
      case "Real Estate":
        return <MdRealEstateAgent className="circle-icon" />;
      case "Technology":
        return <GrTechnology className="circle-icon" />;
      case "Freelancers":
        return <SiFreelancer className="circle-icon" />;
      case "Social Media":
        return <IoIosPlanet className="circle-icon" />;
      case "Events":
        return <SiEventstore className="circle-icon" />;
      case "Teams":
        return <HiMiniUserGroup className="circle-icon" />;
      case "Volunteers":
        return <MdVolunteerActivism className="circle-icon" />;
      case "Clubs":
        return <CgCardClubs className="circle-icon" />;
      case "Company":
        return <SiAwsorganizations className="circle-icon" />;
      case "Government":
        return <RiGovernmentLine className="circle-icon" />;
      case "Bank":
        return <CiBank className="circle-icon" />;
      case "Utilities":
        return <LuUtilityPole className="circle-icon" />;
      case "Restaurants":
        return <IoRestaurantSharp className="circle-icon" />;
      case "Hotels":
        return <LiaHotelSolid className="circle-icon" />;
      case "School":
        return <FaSchool className="circle-icon" />;
      case "University":
        return <MdSchool className="circle-icon" />;
      case "Sports":
        return <MdOutlineSportsCricket className="circle-icon" />;
      case "Music":
        return <GiMusicalScore className="circle-icon" />;
      case "Shopping":
        return <MdShoppingCart className="circle-icon" />;
      case "Partners":
        return <GiLovers className="circle-icon" />;
      case "Comedy":
        return <MdOutlineTheaterComedy className="circle-icon" />;
      default:
        return <LiaUserTieSolid className="circle-icon" />;
    }
  };

  useEffect(() => {
    fetchFollowersCount();

    const interval = setInterval(() => {
      fetchFollowersCount();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  //fetch All data
  const fetchAllData = async () => {
    await Promise.all([
      fetchDataWithLoading(fetchProfileImage),
      fetchDataWithLoading(fetchCircleData),
      fetchDataWithLoading(fetchPrivateLinks),
      fetchDataWithLoading(fetchedSM),
      fetchDataWithLoading(fetchedCL),
      fetchDataWithLoading(fetchedCustomImage),
      fetchDataWithLoading(fetchedUData),
      fetchDataWithLoading(fetchTheme),
      fetchDataWithLoading(fetchFollowersCount),
      fetchDataWithLoading(fetchSites),
      fetchDataWithLoading(fetchPrivacyStatus),
      fetchDataWithLoading(fetchUserDetails),
      fetchDataWithLoading(fetchTemplate),
      fetchDataWithLoading(fetchCircleTemplate),
      fetchDataWithLoading(fetchBio),
      fetchDataWithLoading(fetchLocation),
      fetchDataWithLoading(fetchDesignation),
      fetchDataWithLoading(fetchWorkPlace),
      fetchDataWithLoading(fetchNotificationCount),
    ]);
  };

  const fetchProfileImage = async () => {
    try {
      const response = await fetch(
        `${API_DOMAIN}/profile/image/view?fk_user_id=${userId}`
      );
      if (response.ok) {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.startsWith("image/")) {
          const blob = await response.blob();
          const imageUrl = URL.createObjectURL(blob);
          setImage(imageUrl);
        }
      }
    } catch (error) {
      handleNetworkError(error);
    }
  };

  const fetchLocation = async () => {
    try {
      const response = await fetch(
        `${API_DOMAIN}/location/view?userId=${userId}`
      );
      const result = await response.json();
      if (result.success) {
        const res = jwtDecode(result.token);
        const country = res.country;
        const city = res.city;
        setSelectedCity({ value: city, label: city });
        setSelectedCountry({ value: country, label: country });
      }
    } catch (error) {
      handleNetworkError(error);
    }
  };

  const fetchWorkPlace = async () => {
    try {
      const response = await fetch(`${API_DOMAIN}/work/view?userId=${userId}`);
      const result = await response.json();
      if (result.success) {
        const { workPlace } = jwtDecode(result.token);
        setWorkplace(workPlace);
      }
    } catch (error) {
      handleNetworkError(error);
    }
  };

  const fetchDesignation = async () => {
    try {
      const response = await fetch(
        `${API_DOMAIN}/designation/view?userId=${userId}`
      );
      const result = await response.json();
      if (result.success) {
        const { position } = jwtDecode(result.token);
        setPosition(position);
      }
    } catch (error) {
      handleNetworkError(error);
    }
  };

  const fetchPrivateLinks = async () => {
    try {
      const response = await fetch(
        `${API_DOMAIN}/private/links/view/${userId}`
      );
      const result = await response.json();
      if (result.success) {
        const { privateLinks } = jwtDecode(result.token);
        const pLinks = privateLinks.map((link) => {
          return {
            plIcon: setPLType(link.type),
            privateLink: link.type,
            privacy_status: link.privacy_status,
          };
        });
        setPrivateLinks(pLinks);
        setAddedPrivateLinks(privateLinks);
        setPLAdded(true);
      } else {
        setPLAdded(false);
      }
    } catch (error) {
      handleNetworkError(error);
    }
  };

  const fetchNotificationCount = async () => {
    try {
      const response = await fetch(
        `${API_DOMAIN}/notification/count/view/${userId}`
      );
      const result = await response.json();
      if (result.success) {
        const { notificationCount } = jwtDecode(result.token);
        setNotificationCount(notificationCount);
      }
    } catch (error) {
      handleNetworkError(error);
    }
  };

  //fetch social media links
  const fetchedSM = async () => {
    try {
      const response = await fetch(`${API_DOMAIN}/socialmedia/view/${userId}`);
      const result = await response.json();
      if (result.success) {
        const { socialMediaLinks } = jwtDecode(result.token);
        const smLinks = socialMediaLinks
          .map((link) => {
            const smUsername = link.link;
            if (smUsername === "N/A") {
              return null;
            } else {
              return {
                icon: setIconType(link.type),
                type: link.type,
                smUsername: smUsername || link.fullLink,
                smUrl: link.fullLink,
              };
            }
          })
          .filter(Boolean); //removing null entries
        setSocialMediaLinks(smLinks);
        setAddedSMLinks(socialMediaLinks);
        setIsSMAdded(true);
        setIsShortlysAdd(true);
      } else {
        setIsSMAdded(false);
        setIsShortlysAdd(false);
      }
    } catch (error) {
      handleNetworkError(error);
    }
  };

  const fetchedCustomImage = async (customId) => {
    try {
      const response = await fetch(
        ` ${API_DOMAIN}/customlinks/viewImage?customId=${customId}`
      );
      if (response.ok) {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.startsWith("image/")) {
          const imageBlob = await response.blob();
          const imageUrl = URL.createObjectURL(imageBlob);
          setCustomImages((prevImages) => ({
            ...prevImages,
            [customId]: imageUrl,
          }));
        } else {
          setCustomImages((prevImages) => ({
            ...prevImages,
            [customId]: null,
          }));
        }
      }
    } catch (error) {
      handleNetworkError(error);
    }
  };

  const fetchedCL = async () => {
    try {
      const response = await fetch(
        `${API_DOMAIN}/customlinks/viewLink?userId=${userId}`
      );
      const result = await response.json();
      if (result.success) {
        const { row } = jwtDecode(result.token);
        const cLinks = row.map((link) => ({
          c_Id: link.pk_id,
          customTitle: link.title,
          customUrl: link.url,
        }));
        setIsCustomLinkAdded(true);
        setCustomLinks(cLinks);
        cLinks.forEach((cLink) => {
          fetchedCustomImage(cLink.c_Id);
        });
        // localStorage.setItem("customLinks", JSON.stringify(cLinks));
      } else {
        setIsCustomLinkAdded(false);
      }
    } catch (error) {
      handleNetworkError(error);
    }
  };

  //fetch user data
  const fetchedUData = async () => {
    try {
      const response = await fetch(`${API_DOMAIN}/user/view?userId=${userId}`);
      const result = await response.json();
      if (result.success) {
        const { fullName, userName } = jwtDecode(result.token);
        setFullName(fullName);
        setUsername(userName);
      }
    } catch (error) {
      handleNetworkError(error);
    }
  };

  //get theme color from cookies
  useEffect(() => {
    const interval = setInterval(() => {
      fetchTheme();
    }, 2 * 60 * 60 * 1000);
    return () => clearInterval(interval);
  }, [userId]);

  const fetchTheme = async () => {
    try {
      const themeFromCookie = Cookies.get("theme");
      if (themeFromCookie) {
        const parsedTheme = JSON.parse(themeFromCookie);
        setSelectedTheme(parsedTheme);
      } else {
        const response = await fetch(
          `${API_DOMAIN}/theme/view?userId=${userId}`
        );
        const theme = await response.json();
        if (theme.success) {
          const { light, mid, dark } = jwtDecode(theme.token).theme[0].color;
          const themeArray = [light, mid, dark];
          setSelectedTheme(themeArray);
          Cookies.set("theme", JSON.stringify(themeArray), { expires: 1 / 12 });
        }
      }
    } catch (error) {
      handleNetworkError(error);
    }
  };

  //fetch sites
  const fetchSites = async () => {
    try {
      const response = await fetch(`${API_DOMAIN}/site/view?userId=${userId}`);
      const res = await response.json();
      if (res.success) {
        const { url, title } = jwtDecode(res.token);
        setIsSiteAdded(true);
        setSiteLink(url);
        setSiteTitle(title);
      }
    } catch (error) {
      handleNetworkError(error);
    }
  };

  const fetchCircleData = async () => {
    try {
      const response = await fetch(
        `${API_DOMAIN}/customise/circle/view?userId=${userId}`
      );
      const result = await response.json();
      if (result.success) {
        const { circles } = jwtDecode(result.token);
        // updateIndicatorStyle(circles[0])
        const fetchedCircles = circles
          .map((link) => {
            const color = link.color;
            if (color === "N/A") {
              return null;
            } else {
              setIsCircleAdd(true);
              return {
                type: link.name,
                light: color.light,
                dark: color.dark,
                circleId: link.id,
                circleIcon: getIconByType(link.icon),
              };
            }
          })
          .filter(Boolean);

        fetchedCircles.forEach((circle) => {
          fetchMemberCount(circle.circleId);
        });
        setActiveIndex(circles[0]);
        updateIndicatorStyle(circles[0]);
        setCirclesView(fetchedCircles);
        setCircleCount(fetchedCircles.length);

        const interval = setInterval(() => {
          fetchedCircles.forEach((circle) => {
            fetchMemberCount(circle.circleId);
          });
        }, 5000);

        return () => clearInterval(interval);
      }
    } catch (error) {
      handleNetworkError(error);
    }
  };

  const fetchMemberCount = async (idOfCircle) => {
    try {
      const res = await fetch(
        `${API_DOMAIN}/circle/view/member/count?userId=${userId}&circleId=${idOfCircle}`
      );
      const result = await res.json();
      if (result.success) {
        const { totalCircleMember } = jwtDecode(result.token);
        setCircleMemberCount((prevCount) => ({
          ...prevCount,
          [idOfCircle]: totalCircleMember,
        }));
        if (totalCircleMember > 0) {
          setFollowerCountChanged(true);
        }
      }
    } catch (error) {
      handleNetworkError(error);
    }
  };

  //add limitations to circles
  const handleAddCircles = () => {
    if (circleCount >= 5) {
      setIsCircleLimitExceed(true);
    } else {
      setIsCircleLimitExceed(false);
    }
  };

  const fetchPrivacyStatus = async () => {
    try {
      const resPrivacy = await fetch(
        `${API_DOMAIN}/privacy/view/?userId=${userId}`
      );
      const privacyType = await resPrivacy.json();
      if (privacyType.success) {
        const { privacyMode } = jwtDecode(privacyType.token);
        setPrivacy(privacyMode);
      }
    } catch (error) {
      handleNetworkError(error);
    }
  };

  const fetchFollowersCount = async () => {
    try {
      const response = await fetch(
        `${API_DOMAIN}/circle/view/followers/count?userId=${userId}`
      );
      const result = await response.json();
      if (result.success) {
        const { followersCount } = jwtDecode(result.token);
        if (followersCount > 0) {
          setFollowerCount(followersCount);
        } else {
          setFollowerCount(0);
        }
      }
    } catch (error) {
      handleNetworkError(error);
    }
  };

  //change isNavbar background when scrolling body
  const changeBackground = () => {
    if (window.scrollY > 10) {
      setIsNavbar(true);
    } else {
      setIsNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // Close modal when clicked outside
  const handleClickOutside = (e) => {
    if (
      isModalSitesRef.current &&
      !isModalSitesRef.current.contains(e.target)
    ) {
      setIsModalSites(false);
    }
    if (
      (isModalOptionsRef.current &&
        !isModalOptionsRef.current.contains(e.target)) ||
      (isModalSettingsRef.current &&
        !isModalSettingsRef.current.contains(e.target))
    ) {
      setIsModalOptions(false);
      setIsModalSettings(false);
    }
    if (
      isModalShareOtherRef.current &&
      !isModalShareOtherRef.current.contains(e.target)
    ) {
      setIsModalShareOther(false);
    }
    if (
      isModalShareOwnRef.current &&
      !isModalShareOwnRef.current.contains(e.target)
    ) {
      setIsModalShareOwn(false);
    }
    if (modalScanRef.current && !modalScanRef.current.contains(e.target)) {
      setIsModalScanQr(false);
    }

    if (modalPlViewRef.current && !modalPlViewRef.current.contains(e.target)) {
      setIsPlViewModal(false);
    }
    if (
      modalCircleLimit.current &&
      !modalCircleLimit.current.contains(e.target)
    ) {
      setIsCircleLimitExceed(false);
    }
  };

  //prevent close modal when click inside
  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const setIconType = (type) => {
    switch (type) {
      case "Facebook":
        return <BiLogoFacebook />;
      case "Instagram":
        return <BsInstagram />;
      case "X":
        return <BsTwitterX />;
      case "Youtube":
        return <BsYoutube />;
      case "TikTok":
        return <BiLogoTiktok />;
      case "Pinterest":
        return <FaPinterestP />;
      case "Patreon":
        return <BiLogoPatreon />;
      case "Tumblr":
        return <FaTumblr />;
      case "VK":
        return <SlSocialVkontakte />;
      case "LinkedIn":
        return <BiLogoLinkedin />;
      case "Snapchat":
        return <BsSnapchat />;
      default:
        return null;
    }
  };

  const fetchUserDetails = async () => {
    try {
      const response = await fetch(`${API_DOMAIN}/user?userId=${userId}`);
      const result = await response.json();
      if (result.success) {
        const { firstName, lastName, userName, email } = jwtDecode(
          result.token
        );
        setFirstName(firstName);
        setLastName(lastName);
        setProfileUsername(userName);
        setEmail(email);
      }
    } catch (error) {
      handleNetworkError(error);
    }
  };

  const fetchBio = async () => {
    try {
      const response = await fetch(`${API_DOMAIN}/bio/view?userId=${userId}`);
      const result = await response.json();
      if (result.success) {
        const { bio } = jwtDecode(result.token);
        setBio(bio);
      }
    } catch (error) {
      handleNetworkError(error);
    }
  };

  const fetchTemplate = async () => {
    try {
      const resTemplate = await fetch(
        `${API_DOMAIN}/template/view/?userId=${userId}`
      );
      const templateType = await resTemplate.json();
      if (templateType.success) {
        const { template } = jwtDecode(templateType.token);
        setTemplate(template);
      }
    } catch (error) {
      handleNetworkError(error);
    }
  };

  const fetchCircleTemplate = async () => {
    try {
      const response = await fetch(
        `${API_DOMAIN}/circle/template/view?userId=${userId}`
      );
      const result = await response.json();
      if (result.success) {
        const { circleTemplate } = jwtDecode(result.token);
        setCircleTemplateColor(circleTemplate);
        if (circleTemplate === "#ffffff") {
          setCircleFontColor("#000000");
        } else {
          setCircleFontColor("#ffffff");
        }
      }
    } catch (error) {
      handleNetworkError(error);
    }
  };

  // const handleDrop = (index) => {
  //   if (activeCard === null || activeCard === undefined) return;

  //   const tasksToMove = socialMediaLinks[activeCard];
  //   const updatedTasks = socialMediaLinks.filter((_, i) => i !== activeCard);

  //   updatedTasks.splice(index, 0, tasksToMove);

  //   setSocialMediaLinks(updatedTasks);
  //   localStorage.setItem("socialMediaLinks", JSON.stringify(updatedTasks));
  //   setActiveCard(null);
  // };

  // const handleDropCustom = async (index) => {
  //   if (activeCard === null || activeCard === undefined) return;

  //   const tasksToMove = customLinks[activeCard];
  //   const updatedTasks = customLinks.filter((_, i) => i !== activeCard);

  //   updatedTasks.splice(index, 0, tasksToMove);

  //   setCustomLinks(updatedTasks);
  //   localStorage.setItem("customLinks", JSON.stringify(updatedTasks));
  //   setActiveCard(null);
  // };

  const handleRightClick = (e, socialMediaCopy) => {
    e.preventDefault(); //prevents the default context menu from appearing
    navigator.clipboard.writeText(socialMediaCopy);
  };

  const updateIndicatorStyle = (circle) => {
    const indicator = document.querySelector(".indicator");
    if (indicator) {
      indicator.style.setProperty("--light-color", circle.light);
      indicator.style.setProperty("--dark-color", circle.dark);
      indicator.style.setProperty("--circle-template", circleTemplateColor);
    }

    const addCircle = document.querySelector(".add-new-circle");
    if (addCircle) {
      addCircle.style.setProperty("--dark-color", circle.dark);
    }
  };

  const handleClickGuestView = () => {
    navigate(`/guest/${username}`);
  };

  return (
    <>
      {isLoading && <Loader />}
      <div
        className="profile-wrapper"
        style={{
          background: `linear-gradient(to bottom, ${selectedTheme[0]}, ${selectedTheme[2]})`,
        }}
      >
        {isModalShareOwn && (
          <div className="user-sp-modal-own">
            <ShareProfile
              closeShareModal={setIsModalShareOwn}
              username={profileUsername}
            />
          </div>
        )}

        {isModalShareOther && (
          <div className="user-sp-modal-other">
            <ShareProfile
              closeShareModal={setIsModalShareOther}
              guestUserName={profileUsername}
              username={profileUsername}
            />
          </div>
        )}

        {isModalScanQr && (
          <div className="scan-qr-modal">
            <QRReader closeScanModal={setIsModalScanQr} />
          </div>
        )}

        {isLogoutConfirm && (
          <div className="logout-confirm-modal">
            <div className="lcm-msg">Confirm Log out?</div>
            <div className="lcm-buttons">
              <div
                className="lcm-cancel-btn"
                onClick={() => {
                  setIsLogoutConfirm(false);
                }}
              >
                Cancel
              </div>
              <Link to="/">
                <div
                  className="lcm-logout-btn"
                  onClick={() => {
                    Cookies.remove("userId");
                    Cookies.remove("token");
                    Cookies.remove("theme");
                  }}
                >
                  Log out
                </div>
              </Link>
            </div>
          </div>
        )}

        <div className="default-profile">
          <div
            className={`isNavbar ${
              isNavbar ? "isNavbar-active" : "isNavbar-inactive"
            }`}
          >
            <Link to="/">
              <div className="home-btn">
                <img src={Logo} alt="" className="home-t-logo" />
              </div>
            </Link>

            <div
              className="profile-options-btn"
              onClick={() => {
                setIsModalOptions(!isModalOptions);
              }}
              ref={isModalOptionsRef}
            >
              <SlOptions className="profile-options-icon" />
            </div>

            {isModalOptions && (
              <div className="home-options-popup" onClick={handleModalClick}>
                <div
                  className="hop-settings-sec"
                  onClick={() => {
                    setIsModalSettings(true);
                    setIsModalOptions(false);
                  }}
                >
                  <div className="hop-settings-label">Settings</div>
                  <div className="hop-settings-icon">
                    <IoSettingsOutline />
                  </div>
                </div>

                <div className="hop-widgets-sec">
                  <div className="hop-widgets-label">Widgets</div>
                  <div className="hop-widgets-icon">
                    <MdOutlineWidgets />
                  </div>
                </div>

                <div
                  className="hop-widgets-sec"
                  onClick={() => {
                    setIsStickersActive(true);
                    setIsModalOptions(false);
                  }}
                >
                  <div className="hop-widgets-label">Stickers</div>
                  <div className="hop-widgets-icon">
                    <RiEmojiStickerLine />
                  </div>
                </div>

                <div
                  className="hop-logout-sec"
                  onClick={() => {
                    setIsLogoutConfirm(true);
                  }}
                >
                  <div className="hop-logout-label">Log out</div>
                  <div className="hop-logout-icon">
                    <RiLogoutCircleRLine />
                  </div>
                </div>
              </div>
            )}

            {isModalSettings && (
              <Settings
                closeModalSettings={setIsModalSettings}
                ref={isModalSettingsRef}
                fetchTheme={selectedTheme}
                fetchPrivacy={privacy}
                fetchThemeUpdated={fetchTheme}
                fetchPrivacyUpdated={fetchPrivacyStatus}
                fetchFirstName={firstName}
                fetchLastName={lastName}
                fetchUsername={profileUsername}
                fetchEmail={email}
                fetchUserDetailsUpdated={fetchedUData}
                fetchFirstNameUpdated={fetchUserDetails}
                fetchTemplate={template}
                fetchTemplateUpdated={fetchTemplate}
                fetchCircleTemplate={circleTemplateColor}
                fetchCircleTemplateUpdated={fetchCircleTemplate}
                fetchCircleData={fetchCircleData}
              />
            )}

            {isStickersActive && (
              <Stickers
                userPhoto={userPhoto}
                isPhoto={isPhoto}
                setIsStickersActive={setIsStickersActive}
              />
            )}
          </div>

          <div className="profile-top">
            <div className="section1">
              <div className="connections">
                <span
                  className="connection-count"
                  style={{ backgroundColor: selectedTheme[1], opacity: 0.7 }}
                >
                  {followerCount}
                </span>
                <Lottie
                  options={defaultOptions}
                  height={50}
                  width={50}
                  className="connection-icon"
                />
              </div>
              <ProfileImage
                userImage={setUserPhoto}
                userPhotoAdded={setIsPhoto}
                addedImage={image}
                fetchProfileImage={fetchProfileImage}
              />
              <div
                className="share-profile"
                onClick={() => {
                  setIsModalShareOther(true);
                }}
                ref={isModalShareOtherRef}
                style={{
                  backgroundColor: selectedTheme[1],
                  opacity: 0.7,
                  border: `2px solid ${selectedTheme[2]}`,
                }}
              >
                <img src={Share} alt="" className="share-icon" />
              </div>
            </div>
            <div className="section2">
              <div className="name-details">
                <span className="name">{`${fullName}`}</span>

                <div className="uname-report">
                  <div className="guest-view-icon">
                    <FaRegEye onClick={handleClickGuestView} />
                  </div>
                  <span className="user-name">@{username}</span>
                </div>
              </div>
            </div>
            <div className="section3" ref={isModalSitesRef}>
              <div
                className="sites-wrapper-d"
                onClick={() => {
                  setIsModalSites(true);
                }}
                style={{
                  backgroundColor: selectedTheme[1],
                  opacity: 0.7,
                }}
              >
                <CiGlobe className="sites-d" />
              </div>
              {isModalSites && (
                <div className="sites-view-popup">
                  <div className="sites-vp-title">
                    {firstName}'s Sites
                    <div
                      className="sites-vp-close"
                      onClick={() => setIsModalSites(false)}
                    >
                      <RxCross2 />
                    </div>
                  </div>
                  <div className="sites-vp-body">
                    {isSiteAdded ? (
                      <div className="sites-vlist">
                        <a
                          href={`https://${siteLink}`}
                          target="blank"
                          className="site-name"
                        >
                          {siteTitle}
                        </a>
                        <div className="dsite-option">
                          <SitesOption
                            fetchSites={fetchSites}
                            siteState={setIsSiteAdded}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="sites-vlist-no-sites">No sites added</div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="section4">
              <BioDescription bioDesc={bio} fetchBio={fetchBio} />
            </div>
            <div className="section5">
              <div className="demographic">
                <UserLocation
                  countrySelected={selectedCountry}
                  citySelected={selectedCity}
                  fetchLocation={fetchLocation}
                />
              </div>
              <div className="demographic">
                <UserDesignation
                  designation={position}
                  fetchDesignation={fetchDesignation}
                />
              </div>
              <div className="demographic">
                <WorkPlace
                  addedWorkplace={workplace}
                  fetchWorkPlace={fetchWorkPlace}
                />
              </div>
            </div>
          </div>
          <div className="profile-body">
            <div className="tabs">
              <div
                className={`tab-section-shortlys ${
                  isShortlys ? "active-shortlys" : ""
                }`}
                onClick={() => {
                  setIsShortlys(true);
                  setIsCircles(false);
                }}
              >
                <img src={Shortlys} alt="" className="tab-image" />
                <span className="tab-title">Shortlys</span>
                <span className="bar"></span>
              </div>
              <div
                className={`tab-section-circles ${
                  isCircles ? "active-circles" : ""
                }`}
                onClick={() => {
                  setIsCircles(true);
                  setIsShortlys(false);
                  setActiveIndex(null);
                }}
              >
                <img src={Circles} alt="" className="tab-image" />
                <span className="tab-title">Circles</span>
                <span className="bar"></span>
              </div>
            </div>

            {isShortlys && (
              <div
                className={`add-links ${
                  isShortlysAdd || isCustomLinkAdded || isSMAdded
                    ? "default-profile-smadd"
                    : "default-profile-smnot"
                }`}
              >
                <div className="dp-sm-links">
                  {/* <DropArea onDrop={(index) => handleDrop(index)} index={0} /> */}
                  {isSMAdded &&
                    socialMediaLinks.map((link, index) => (
                      <React.Fragment key={index}>
                        <div
                          className={`sm-links-added ${
                            template === "fill"
                              ? "template-fill"
                              : "template-border"
                          }`}
                          // draggable
                          // onDragStart={() => setActiveCard(index)}
                          // onDragEnd={() => setActiveCard(null)}
                          onContextMenu={(e) => handleRightClick(e, link.smUrl)}
                        >
                          <div
                            className="sm-links-logo"
                            onClick={() => {
                              window.open(link.smUrl, "__blank");
                            }}
                          >
                            {link.icon}
                          </div>
                          <h2
                            className="sm-links-title"
                            onClick={() => {
                              window.open(link.smUrl, "__blank");
                            }}
                          >
                            {link.type}
                          </h2>
                          <span>
                            <SMOptions
                              type={link.type}
                              fetchSMLinks={fetchedSM}
                              usernameOptions={link.smUsername}
                            />
                          </span>
                        </div>
                        {/* <DropArea
                          onDrop={(dropIndex) => handleDrop(dropIndex)}
                          index={index + 1}
                        /> */}
                      </React.Fragment>
                    ))}
                  {isCustomLinkAdded &&
                    customLinks.map((link, index) => (
                      <React.Fragment key={index}>
                        <div
                          className={`sm-links-added ${
                            template === "fill"
                              ? "template-fill"
                              : "template-border"
                          }`}
                          onContextMenu={(e) =>
                            handleRightClick(e, link.customUrl)
                          }
                        >
                          {customImages[link.c_Id] ? (
                            <div
                              className="cl-links-logo"
                              onClick={() => {
                                window.open(link.customUrl, "__blank");
                              }}
                            >
                              <img
                                src={customImages[link.c_Id]}
                                alt=""
                                className="custom-image"
                              />
                            </div>
                          ) : (
                            <div
                              className="sm-links-logo"
                              onClick={() => {
                                window.open(link.customUrl, "__blank");
                              }}
                            >
                              <TbWorld className="custom-image" />
                            </div>
                          )}
                          <h2
                            className="sm-links-title"
                            onClick={() => {
                              window.open(link.customUrl, "__blank");
                            }}
                          >
                            {link.customTitle}
                          </h2>
                          <div className="custom-options">
                            <CustomLinkOption
                              customId={link.c_Id}
                              fetchCustomLinks={fetchedCL}
                            />
                          </div>
                        </div>
                        {/* <DropArea
                          onDrop={(dropIndex) => handleDropCustom(dropIndex)}
                          index={index + 1}
                        /> */}
                      </React.Fragment>
                    ))}
                </div>
                <div className="dp-social">
                  <SMLinks
                    fetchTemplate={fetchTemplate}
                    fetchSM={fetchedSM}
                    fetchCL={fetchedCL}
                    smLinksAdded={addedSMLinks}
                  />
                </div>

                <div className="dp-p-links" ref={modalPlViewRef}>
                  <div
                    className="pl-icons"
                    onClick={() => setIsPlViewModal(!isPlViewModal)}
                  >
                    {isPLAdded
                      ? privateLinks.map((link, plId) => (
                          <div className="pl-icon" key={plId}>
                            {link.plIcon}
                          </div>
                        ))
                      : ""}
                  </div>
                  <PrivateLinks
                    fetchPL={fetchPrivateLinks}
                    privateLinksAdded={addedPrivateLinks}
                  />
                </div>
              </div>
            )}

            {isCircles && (
              <div
                className={`circles-view-body ${
                  isCircleAdd ? "circle-body-added" : "circle-body-not-added"
                }`}
              >
                <div
                  className="circle-container"
                  style={{
                    "--circle-count": circleCount,
                    backgroundColor: circleTemplateColor,
                    color: circleFontColor,
                  }}
                >
                  <div className="add-new-circle">
                    <img
                      src={AddCircles}
                      alt=""
                      className="add-circle-icon"
                      onClick={() => {
                        handleAddCircles();
                        setIsAddNewCircle(true);
                      }}
                    />
                  </div>
                  {circlesView.map((circle, index) => (
                    <li
                      key={circle.circleId}
                      style={{ "--i": index }}
                      className={activeIndex === index ? "active" : ""}
                      onClick={() => {
                        setCircleId(circle.circleId);
                        setCircleName(circle.type);
                        handleClickCircle(index);
                      }}
                    >
                      <div className="circle">
                        {activeIndex === index ? (
                          <div className="circle-count">
                            {circleMemberCount[circle.circleId]}
                          </div>
                        ) : (
                          <div>{circle.circleIcon}</div>
                        )}
                        <div className="circle-name">{circle.type}</div>
                      </div>
                    </li>
                  ))}
                  <div className="indicator"></div>
                </div>

                {isAddNewCircle && isCircleLimitExceed && (
                  <CircleLimitError closeCircleLimit={setIsCircleLimitExceed} />
                )}
                {isAddNewCircle && circleCount < 5 && (
                  <div className="add-new-circle-modal">
                    <AddNewCircle
                      closeAddNew={setIsAddNewCircle}
                      fetchCircleData={fetchCircleData}
                    />
                  </div>
                )}
                {isManageCircleModal && (
                  <div className="manage-circle-popup">
                    <ManageCircle
                      closeManageCircle={setIsManageCircleModal}
                      circleId={circleId}
                      circleName={circleName}
                      fetchCirclesAvailable={fetchCircleData}
                      followerCountChanged={followerCountChanged}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="fab">
          <div className="notifications">
            <Notification
              userPhoto={userPhoto}
              isPhoto={isPhoto}
              fetchCircleData={fetchCircleData}
              countOfNotification={notificationCount}
              fetchNotificationCount={fetchNotificationCount}
            />
          </div>
          <Link to={`/@${profileUsername}`}>
            <div className="user">
              {isPhoto ? (
                <img src={userPhoto} alt="" className="fab-user-photo" />
              ) : (
                <img src={User} alt="" className="fab-user-photo-icon" />
              )}
            </div>
          </Link>
          <div
            className="dots"
            onClick={() => {
              setIsFabOpen(!isFabOpen);
            }}
          >
            <img src={Fab} alt="" className="fab-icon" />
          </div>

          {isFabOpen && (
            <div className="fab-menu">
              {/* <Suspense fallback={<div>Loading...</div>}> */}
              <div
                className="share-own"
                onClick={() => {
                  setIsModalShareOwn(true);
                }}
                ref={isModalShareOwnRef}
                style={{
                  backgroundColor: selectedTheme[1],
                  opacity: 0.7,
                }}
              >
                <img src={Share} alt="" className="share-own-icon" />
              </div>
              <div
                className="qr"
                onClick={() => {
                  setIsModalScanQr(true);
                }}
                ref={modalScanRef}
              >
                <img src={QR} alt="" className="fab-qr-icon" />
              </div>

              <div className="sites-add">
                <AddSites fetchSites={fetchSites} />
              </div>
            </div>
          )}
        </div>

        <div className="profile-bottom">
          <Footer />
        </div>
      </div>
      <ConsoleError
        isConsoleError={isConsoleError}
        consoleError={consoleError}
        closeConsoleError={setIsConsoleError}
      />
    </>
  );
}

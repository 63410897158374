import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import CloseButton from "react-bootstrap/CloseButton";
import { FaUserCircle } from "react-icons/fa";
import { BsBell } from "react-icons/bs";
import HomeLogo from "../../Components/HomeLogo/HomeLogo";
import API_DOMAIN from "../../ApiConfig";
import UserConfirm from "../UserConfirm/UserConfirm";
import "./Notification.css";
import ConsoleError from "../ConfirmationMessage/ConsoleError";

function Notification({
  userPhoto,
  isPhoto,
  fetchCircleData,
  fetchNotificationCount,
  countOfNotification,
}) {
  const [followerImages, setFollowerImages] = useState([]);
  const [isPlReqBody, setIsPlReqBody] = useState(false);
  const [followRequest, setFollowRequest] = useState([]);
  const [isConReqBody, setIsConReqBody] = useState(true);
  const [notifyCount, setNotifyCount] = useState();
  const [, setAccepted] = useState(false);
  const [consoleError, setConsoleError] = useState("");
  const [isConsoleError, setIsConsoleError] = useState(false);

  const userId = Cookies.get("userId");

  const navigate = useNavigate();
  useEffect(() => {
    if (countOfNotification > 0) {
      setNotifyCount(countOfNotification);
    }else{
      setNotifyCount(0)
    }
  }, [countOfNotification]);

  useEffect(() => {
    if (countOfNotification > 0) {
      fetchConnection();
    }
  }, [countOfNotification]);

  //fetch profile photo
  const fetchNotificationProfileImage = async (userID) => {
    try {
      const response = await fetch(
        `${API_DOMAIN}/profile/image/view?fk_user_id=${userID}`
      );
      if (response.ok) {
        const blob = await response.blob();
        const imageUrl = URL.createObjectURL(blob);
        setFollowerImages((prevImages) => ({
          ...prevImages,
          [userID]: imageUrl,
        }));
      }
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  const fetchConnectionStatus = async () => {
    try {
      const response = await fetch(
        `${API_DOMAIN}/request/action/view/${userId}`
      );
      const result = await response.json();
      if (result.success) {
        const { actionDetails } = jwtDecode(result.token);
        return actionDetails.map((link) => ({
          userId: link.userId,
          profileAction: link.requestAction,
        }));
      }
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  const fetchConnection = async () => {
    try {
      const response = await fetch(`${API_DOMAIN}/notification/view/${userId}`);
      const result = await response.json();
      if (result.success) {
        const { notificationDetails } = jwtDecode(result.token);
        const actions = await fetchConnectionStatus();
        if (actions) {
          const followers = notificationDetails.map((link) => {
            const action = actions.find(
              (a) => a.userId === link.followerUserId
            );
            return {
              followerUserID: link.followerUserId,
              fullName: link.fullName,
              designation: link.designation,
              city: link.city,
              country: link.country,
              timeDifference: link.timeDifference,
              followerUsername: link.username,
              profileAction: action.profileAction,
              friendStatus: link.friendListStatus,
            };
          });
          const filteredFollowers = followers.filter(
            (follower) =>
              follower.profileAction === "N/A" &&
              follower.friendStatus === "Not a friend"
          );
          setFollowRequest(filteredFollowers);
          filteredFollowers.forEach((follower) => {
            fetchNotificationProfileImage(follower.followerUserID);
          });
        }
      }
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  return (
    <>
      <Popup
        trigger={
          <div className="notification" onClick={fetchConnection}>
            {notifyCount <= 0 ? (
              <BsBell className="notification-icon-default" />
            ) : (
              <>
                <BsBell className="notification-icon" />
                <div className="notification-count-icon">{notifyCount}</div>
              </>
            )}
          </div>
        }
        modal
        nested
      >
        {(close) => (
          <div className="notification-popup">
            <div className="notification-menu-bar">
              <div className="nmb-logo">
                <HomeLogo />
              </div>
              <div className="nmb-title">Notifications</div>
              <div className="n-to-home">
                {isPhoto ? (
                  <div className="n-to-home-photo" onClick={() => close()}>
                    <img src={userPhoto} alt="" className="home-photo" />
                  </div>
                ) : (
                  <FaUserCircle
                    className="n-to-home-icon"
                    onClick={() => close()}
                  />
                )}
              </div>
            </div>
            <div className="notification-header">
              <div className="notification-title">
                <h> Notifications </h>
              </div>

              <div className="notification-close">
                <button
                  className="notification-close-btn"
                  onClick={() => close()}
                >
                  <CloseButton style={{ height: "5px", width: "5px" }} />
                </button>
              </div>
            </div>
            <div className="np-container">
              <div className="notification-tabs">
                <div
                  className={`connection-requests ${
                    isConReqBody ? "active" : ""
                  }`}
                  onClick={() => {
                    setIsConReqBody(true);
                    setIsPlReqBody(false);
                  }}
                >
                  Connections
                </div>
                <div
                  className={`pl-requests ${isPlReqBody ? "active" : ""}`}
                  onClick={() => {
                    setIsPlReqBody(true);
                    setIsConReqBody(false);
                  }}
                >
                  Private Links
                </div>
              </div>

              {isConReqBody &&
                (notifyCount > 0 ? (
                  <div className="notification-body">
                    {followRequest.map((link, index) => (
                      <div className="notification-no" key={index}>
                        {followerImages[link.followerUserID] ? (
                          <div
                            className="n-to-home-photo"
                            onClick={() => close()}
                          >
                            <img
                              src={followerImages[link.followerUserID]}
                              alt=""
                              className="user-logo"
                              style={{
                                width: "70px",
                                height: "70px",
                                borderRadius: "50%",
                              }}
                            />
                          </div>
                        ) : (
                          <div className="notification-user">
                            <FaUserCircle className="user-logo" />
                          </div>
                        )}

                        <div className="notification-user-details">
                          <div className="notification-user-name">
                            <div
                              className="notify-name"
                              onClick={() => {
                                navigate(`/guest/${link.followerUsername}`);
                              }}
                            >
                              {link.fullName}
                            </div>
                            <div className="notify-time-sign">
                              {link.timeDifference}
                            </div>
                          </div>
                          <div className="notification-user-occupation">
                            <div>
                              {link.designation !== null &&
                                `${link.designation}, `}
                              {link.city !== null && ` ${link.city}`}
                              {link.country !== null && `, ${link.country}`}
                            </div>
                          </div>
                          <div className="notification-btns">
                            <UserConfirm
                              guestUserID={link.followerUserID}
                              guestName={link.followerUsername}
                              accepted={setAccepted}
                              fetchedConnections={fetchConnection}
                              fetchCircles={fetchCircleData}
                              fetchNotificationCount={fetchNotificationCount}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="notification-body">
                    <div className="empty-notification">
                      You're all caught up! No new notifications.
                    </div>
                  </div>
                ))}

              {isPlReqBody && (
                <div className="notification-body">
                  <div className="empty-notification">
                    You're all caught up! No new notifications.
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </Popup>
      <ConsoleError
        isConsoleError={isConsoleError}
        consoleError={consoleError}
        closeConsoleError={setIsConsoleError}
      />
    </>
  );
}

export default Notification;

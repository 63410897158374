import React, { useState, useEffect, useRef } from "react";
import { jwtDecode } from "jwt-decode";
import { RxCross2 } from "react-icons/rx";
import {
  MdOutlineKeyboardArrowRight,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";
import { HiMiniSignal } from "react-icons/hi2";
import Cookies from "js-cookie";
import Popup from "reactjs-popup";
import API_DOMAIN from "../../ApiConfig";
import "./DeleteCircle.css";
import AddNewCircle from "../AddNewCircle/AddNewCircle";
import ConsoleError from "../ConfirmationMessage/ConsoleError";
import CircleLimitError from "../CircleLimitError/CircleLimitError";
import ConfirmationMessage from "../ConfirmationMessage/ConfirmationMessage";

function DeleteCircle({
  nameOfCircle,
  idOfCircle,
  connections,
  fetchCircles,
  closeModifyCircle,
  closeManageCircle,
  countOfCircle,
}) {
  const [isDeleteCircleModalOpen, setIsDeleteCircleModalOpen] = useState(false);
  const [deleteOption, setDeleteOption] = useState("withConnections");
  const [isTabDeleteClicked, setIsTabDeleteClicked] = useState(false);
  const [circleNameInput, setCircleNameInput] = useState("");
  const [confirmError, setConfirmError] = useState("");
  const [isCircleTypeCanvas, setIsCircleTypeCanvas] = useState(false);
  const [isCirclesCreated, setIsCirclesCreated] = useState(true);
  const [isAddNewCircle, setIsAddNewCircle] = useState(false);
  const addNewModalRef = useRef(null);
  const [isMoveAndDeleteClicked, setIsMoveAndDeleteClicked] = useState(false);
  const scrollContainerRef = useRef(null);
  const [selectCircle, setSelectCircle] = useState("");
  const [userImages, setUserImages] = useState([]);
  const [availableCircles, setAvailableCircles] = useState([]);
  const [connectionsAvailable, setConnectionsAvailable] = useState([]);
  const [idToMove, setIdToMove] = useState([]);
  const [circleCount, setCircleCount] = useState("");
  const [isCircleLimitExceed, setIsCircleLimitExceed] = useState(false);
  const [isProgressMove, setIsProgressMove] = useState(false);
  const [filledProgress, setFilledProgress] = useState(0);
  const [isLoadProgress, setIsLoadProgress] = useState(false);
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);
  const [consoleError, setConsoleError] = useState("");
  const [isConsoleError, setIsConsoleError] = useState(false);
  const [isCircleDeleted, setIsCircleDeleted] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [circleMemberCount, setCircleMemberCount] = useState();

  const userId = Cookies.get("userId");

  const handleDeleteOption = (option) => {
    setDeleteOption(option);
  };

  //progress bar in move connections
  useEffect(() => {
    if (filledProgress < 100 && isLoadProgress) {
      setTimeout(() => setFilledProgress((prev) => prev + 10), 50);
    } else if (filledProgress === 100) {
      setTimeout(() => setIsProgressMove(false), 2000);
    }
  }, [filledProgress, isLoadProgress]);

  //close modal when click outside
  const handleClickOutside = (event) => {
    if (
      addNewModalRef.current &&
      !addNewModalRef.current.contains(event.target)
    ) {
      setIsAddNewCircle(false);
    }
  };

  useEffect(() => {
    if (isAddNewCircle) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isAddNewCircle]);

  // scrolls to the bottom when click 'move to'
  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop =
        scrollContainerRef.current.scrollHeight;
    }
  }, [isCircleTypeCanvas, deleteOption]);

  useEffect(() => {
    if (selectCircle && scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop =
        scrollContainerRef.current.scrollHeight;
    }
  }, [selectCircle]);

  const closeModalAfterDelay = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        setIsDeleteCircleModalOpen(false);
        closeManageCircle(false);
        closeModifyCircle();
        setIsDeleteConfirm(true);
        resolve();
      }, 3000);

      setIsCircleDeleted(true);
      setIsVisible(true);
    });
  };

  //delete circle
  const deleteCircle = async () => {
    try {
      await fetch(`${API_DOMAIN}/customise/circle/delete`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: userId,
          circleId: idOfCircle,
        }),
      });
      fetchCircles();
      setIsCircleDeleted(true);
      setTimeout(() => {
        setIsDeleteConfirm(false);
      }, 5000);
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  //confirm delete circle validation
  const handleCircleName = (e) => {
    const enteredCircleName = e.target.value;
    if (enteredCircleName === "") {
      setConfirmError("");
    }
    setCircleNameInput(enteredCircleName);
  };

  //handle confirm delete
  const handleConfirmDelete = () => {
    if (circleNameInput === nameOfCircle) {
      setConfirmError("");
      deleteCircle();
      setIsVisible(true);
      closeModalAfterDelay();
    } else {
      setConfirmError("Please type the circle name correctly!");
    }
  };

  //select circle to move
  const handleSelectCircle = (option) => {
    setSelectCircle(option);
  };

  //fetch connections count
  useEffect(() => {
    const fetchFollowers = async () => {
      try {
        const response = await fetch(
          `${API_DOMAIN}/user/circle/view/${userId}?circleName=${nameOfCircle}`
        );
        if (response.status === 404) {
          setIsConsoleError(false);
          return;
        }
        const result = await response.json();
        if (result.success) {
          const filteredNames = jwtDecode(result.token);
          const followers = filteredNames.users;
          const followerNames = followers.map((link) => ({
            uname: link.fullName,
            uId: link.userID,
          }));

          const ids = followerNames.map((follower) => ({
            targetUserId: follower.uId,
          }));
          setConnectionsAvailable(followerNames);
          setCircleMemberCount(followerNames.length);
          setIdToMove(ids);
          followerNames.forEach((followerName) => {
            fetchProfileImage(followerName.uId);
          });
        }
      } catch (error) {
        if (error.message === "Failed to fetch") {
          setIsConsoleError(true);
          setConsoleError("Unable to connect. Please check back shortly!");
        } else {
          setIsConsoleError(true);
          setConsoleError(error.message);
        }
      }
    };
    fetchFollowers();
  }, [userId, nameOfCircle]);

  const moveUser = async (circleType) => {
    try {
      await fetch(`${API_DOMAIN}/user/circle/update/${userId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          users: idToMove,
          newCircleName: circleType,
        }),
      });
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  //fetch connection's profile image
  const fetchProfileImage = async (idOfUser) => {
    try {
      const response = await fetch(
        `${API_DOMAIN}/profile/image/view?fk_user_id=${idOfUser}`
      );
      if (response.ok) {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.startsWith("image/")) {
          const blob = await response.blob();
          const imageUrl = URL.createObjectURL(blob);
          setUserImages((prevImages) => ({
            ...prevImages,
            [idOfUser]: imageUrl,
          }));
        } else {
          setUserImages((prevImages) => ({
            ...prevImages,
            [idOfUser]: null,
          }));
        }
      }
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  //fetch available circles
  useEffect(() => {
    const fetchAvailableCircles = async () => {
      try {
        const response = await fetch(
          `${API_DOMAIN}/customise/circle/view?userId=${userId}`
        );
        const result = await response.json();
        if (result.success) {
          const { circles } = jwtDecode(result.token);
          const fetchedCircles = circles.map((link) => ({
            circleName: link.name,
          }));
          setCircleCount(circles.length);
          setAvailableCircles(
            fetchedCircles.filter(
              (circle) => circle.circleName !== nameOfCircle
            )
          );
          setIsCirclesCreated(true);
        }
      } catch (error) {
        if (error.message === "Failed to fetch") {
          setIsConsoleError(true);
          setConsoleError("Unable to connect. Please check back shortly!");
        } else {
          setIsConsoleError(true);
          setConsoleError(error.message);
        }
      }
    };
    fetchAvailableCircles();
  }, [userId, circleCount]);

  //add limitations to circles
  const handleAddCircles = () => {
    if (circleCount >= 5) {
      setIsCircleLimitExceed(true);
    } else {
      setIsCircleLimitExceed(false);
    }
  };

  const handleMoveAndDelete = async () => {
    setIsProgressMove(true);
    setIsLoadProgress(true);
    await moveUser(selectCircle);
    await deleteCircle();
    setIsDeleteCircleModalOpen(false);
    closeManageCircle(false);
    closeModifyCircle();
  };

  return (
    <>
      {countOfCircle > 4 && (
        <button
          className="designation-delete"
          onClick={() => setIsDeleteCircleModalOpen(true)}
        >
          Delete
        </button>
      )}
      <Popup
        open={isDeleteCircleModalOpen}
        onClose={() => setIsDeleteCircleModalOpen(false)}
        modal
        nested
      >
        {(close) => (
          <div className="delete-circle-modal">
            <div className="dcm-header">
              <div className="dcm-title">Delete Circle</div>
              <div className="dcm-close" onClick={() => close()}>
                <RxCross2 />
              </div>
            </div>
            <div className="dcm-body">
              {circleMemberCount > 0 ? (
                <span className="dcm-confirm-msg">
                  Are you sure you want to delete this circle? Please choose an
                  option to proceed.
                </span>
              ) : (
                <span className="dcm-confirm-msg">
                  Are you sure you want to delete this circle?
                </span>
              )}
              <div className="scroll-when-move" ref={scrollContainerRef}>
                <div className="dcm-delete-options">
                  <div className="dcm-option-text">
                    <input
                      type="radio"
                      className="dcm-radio"
                      name="deleteOption"
                      value="withConnections"
                      checked={deleteOption === "withConnections"}
                      onChange={() => handleDeleteOption("withConnections")}
                    />
                    <span className="dcm-option-title">
                      Delete with Connections
                    </span>
                  </div>
                  <div className="dcm-option-desc">
                    This will permanently delete the circle along with all its
                    connections.
                  </div>
                </div>
                {circleMemberCount > 0 && (
                  <div className="dcm-delete-options">
                    <div className="dcm-option-text">
                      <input
                        type="radio"
                        className="dcm-radio"
                        name="deleteOption"
                        value="moveConnections"
                        checked={deleteOption === "moveConnections"}
                        onChange={() => handleDeleteOption("moveConnections")}
                      />
                      <span className="dcm-option-title">
                        Move Connections and Delete
                      </span>
                    </div>
                    <div className="dcm-option-desc">
                      This will move connections to another circle of your
                      choice before deleting the circle.
                    </div>
                  </div>
                )}
                {deleteOption === "moveConnections" && (
                  <div className="dcm-move-canvas">
                    <div className="move-con-title">
                      Your connections in {nameOfCircle} Circle
                    </div>
                    <div className="all-con-body">
                      {connectionsAvailable.map((link, index) => (
                        <div className="all-con-container" key={index}>
                          <div className="con-profile-photo">
                            {userImages[link.uId] ? (
                              <img
                                src={userImages[link.uId]}
                                alt=""
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  borderRadius: "50%",
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="con-name">{link.uname}</div>
                        </div>
                      ))}
                    </div>
                    <div
                      className="mc-select-circle"
                      onClick={() => {
                        setIsCircleTypeCanvas(!isCircleTypeCanvas);
                      }}
                    >
                      <div className="mc-move-circle">Move to</div>
                      {isCircleTypeCanvas ? (
                        <div className="mc-move-circle-icon">
                          <MdOutlineKeyboardArrowDown />
                        </div>
                      ) : (
                        <div className="mc-move-circle-icon">
                          <MdOutlineKeyboardArrowRight />
                        </div>
                      )}
                    </div>
                    {isCircleTypeCanvas && (
                      <div className="move-to-circle">
                        {isCirclesCreated ? (
                          <>
                            {availableCircles.map((link, index) => (
                              <div className="mtc-radio" key={index}>
                                <input
                                  type="radio"
                                  className="mtc-radio-btn"
                                  name="selectCircle"
                                  value={link.circleName}
                                  checked={selectCircle === link.circleName}
                                  onChange={() => {
                                    handleSelectCircle(link.circleName);
                                  }}
                                />
                                <div className="mtc-radio-label">
                                  {link.circleName}
                                </div>
                              </div>
                            ))}

                            <div className="mtc-radio">
                              <div
                                className="mtc-create-new"
                                onClick={() => {
                                  setIsMoveAndDeleteClicked(true);
                                  setIsAddNewCircle(true);
                                  handleAddCircles();
                                }}
                              >
                                + Add new circle
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="mtc-created-circles">
                              You don't have any other circles to move.
                            </div>
                            <div className="mtc-add-new">
                              <div
                                className="mtc-create-new"
                                onClick={() => {
                                  setIsMoveAndDeleteClicked(true);
                                  setIsAddNewCircle(true);
                                  handleAddCircles();
                                }}
                              >
                                + Add new circle
                              </div>
                            </div>
                          </>
                        )}
                        {selectCircle ? (
                          <div
                            className="move-to-btn"
                            onClick={handleMoveAndDelete}
                          >
                            Move & Delete
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="dcm-footer">
              {deleteOption === "moveConnections" ? (
                ""
              ) : (
                <div className="dcm-footer-delete">
                  <div
                    className="dcm-delete-btn"
                    onClick={() => {
                      setIsTabDeleteClicked(true);
                    }}
                  >
                    Delete
                  </div>
                </div>
              )}
            </div>

            {isAddNewCircle && circleCount < 5 && (
              <div className="add-custom-circle" ref={addNewModalRef}>
                <AddNewCircle
                  closeAddNew={setIsAddNewCircle}
                  moveAndDelete={isMoveAndDeleteClicked}
                  idsToMove={idToMove}
                  circleId={idOfCircle}
                  closeDeleteCircleModal={setIsDeleteCircleModalOpen}
                  closeManageCircleModal={closeManageCircle}
                  closeModifyCircleModal={closeModifyCircle}
                  fetchCircle={fetchCircles}
                />
              </div>
            )}

            {isCircleLimitExceed && (
              <CircleLimitError closeCircleLimit={setIsCircleLimitExceed} />
            )}

            {isProgressMove && (
              <div className="progress-move-modal">
                {filledProgress === 100 ? (
                  <div className="progress-move-body">
                    <div className="progress-move-delete">
                      Circle Deleted Successfully!
                    </div>
                  </div>
                ) : (
                  <div className="progress-move-body">
                    <div className="progress-move-desc">
                      Moving your connections into {selectCircle} Circle.
                    </div>
                    <div className="progress-bar-track">
                      <div
                        className="progress-bar-thumb"
                        style={{
                          height: "100%",
                          width: `${filledProgress}%`,
                          transition: "width 0.5s",
                        }}
                      ></div>
                    </div>
                    <div className="progress-move-count">{filledProgress}%</div>
                  </div>
                )}
              </div>
            )}

            {isTabDeleteClicked && (
              <div className="confirm-delete-modal">
                <div
                  className="cd-close"
                  onClick={() => setIsTabDeleteClicked(false)}
                >
                  <RxCross2 />
                </div>
                <div className="cd-circle-details">
                  <span className="cd-circle-type">{nameOfCircle}</span>
                  <div className="cd-circle-connections">
                    <div className="cd-con-icon">
                      <HiMiniSignal />
                    </div>
                    <div className="cd-con-count">
                      {connections} Connections
                    </div>
                  </div>
                </div>
                <div className="cd-retype">
                  <div className="cd-type-text">
                    To confirm delete, type circle name in the box below
                  </div>
                  <input
                    className="cd-type-text-input"
                    onChange={handleCircleName}
                  />
                  {confirmError && (
                    <div className="confirm-delete-error">{confirmError}</div>
                  )}
                </div>
                <div className="cd-footer">
                  <div
                    className="confirm-delete-btn"
                    onClick={() => {
                      handleConfirmDelete();
                    }}
                  >
                    Delete the Circle
                  </div>
                </div>
              </div>
            )}

            <ConfirmationMessage
              isVisible={isVisible}
              setIsVisible={setIsVisible}
              isDeleteCircle={isCircleDeleted}
              thisCircle={selectCircle}
            />

            {isDeleteConfirm && (
              <div className="delete-confirmed">
                Circle deleted successfully!
              </div>
            )}
          </div>
        )}
      </Popup>
      <ConsoleError
        isConsoleError={isConsoleError}
        consoleError={consoleError}
        closeConsoleError={setIsConsoleError}
      />
    </>
  );
}

export default DeleteCircle;

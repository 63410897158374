import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Link, useParams } from "react-router-dom";
import Lottie from "react-lottie";
import { jwtDecode } from "jwt-decode";
import ShareProfile from "../../Components/ShareProfile/ShareProfile";
import QRReader from "../../Components/QRReader/QRReader";
import { RxCross2 } from "react-icons/rx";
import { MdOutlineContentCopy } from "react-icons/md";
import Logo from "../../Assets/TinlyLogo.png";
import Notification from "../../Components/Notification/Notification";
import Shortlys from "../../Assets/Shortlys.svg";
import Circles from "../../Assets/Circle.svg";
import Designation from "../../Assets/Designation.svg";
import Share from "../../Assets/Share.svg";
import Location from "../../Assets/Location.svg";
import Work from "../../Assets/Work.svg";
import PhoneW from "../../Assets/PhoneW.png";
import PhoneHome from "../../Assets/PhoneHome.png";
import AddressW from "../../Assets/AddressW.png";
import EmailW from "../../Assets/EmailW.png";
import WhatsappW from "../../Assets/WhatsappW.png";
import ConsoleError from "../../Components/ConfirmationMessage/ConsoleError";
import {
  BsYoutube,
  BsInstagram,
  BsSnapchat,
  BsTwitterX,
  BsThreeDots,
} from "react-icons/bs";
import {
  BiLogoFacebook,
  BiLogoTiktok,
  BiLogoPatreon,
  BiLogoLinkedin,
} from "react-icons/bi";
import { FaPinterestP, FaTumblr } from "react-icons/fa";
import User from "../../Assets/User.svg";
import Fab from "../../Assets/Fab.svg";
import { SlSocialVkontakte, SlOptions } from "react-icons/sl";
import TelegramW from "../../Assets/TelegramW.png";
import { CiGlobe } from "react-icons/ci";
import { TbWorld } from "react-icons/tb";
import { BiLogOutCircle } from "react-icons/bi";
import QR from "../../Assets/Qr.svg";
import AddSites from "../../Components/AddSites/AddSites";
import animationData from "../../Lottie/ConnectionIcon1.json";
import UserConnect from "../../Components/UserConnect/UserConnect";
import Footer from "../../Components/Footer/Footer";
import "./GuestView.css";
import API_DOMAIN from "../../ApiConfig";
import ReportProfile from "../../Components/ReportProfile/ReportProfile";
import UpgradePlans from "../../Components/UpgradePlans/UpgradePlans";
import Loader from "../../Components/Loader/Loader";

export default function GuestView() {
  const [isOwnPhoto, setIsOwnPhoto] = useState(false);
  const [userPhoto, setUserPhoto] = useState("");
  const [isModalScanQr, setIsModalScanQr] = useState(false);
  const [isUserFound, setIsUserFound] = useState(false);
  const [isFabOpen, setIsFabOpen] = useState(false);
  const [isModalShareOwn, setIsModalShareOwn] = useState(false);
  const [fullName, setFullName] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [bio, setBio] = useState("");
  const [siteTitle, setSiteTitle] = useState("");
  const [siteLink, setSiteLink] = useState("");
  const [firstName, setFirstName] = useState("");
  const [position, setPosition] = useState("");
  const [template, setTemplate] = useState("");
  const [customLinks, setCustomLinks] = useState("");
  const [privacy, setPrivacy] = useState("");
  const [copiedPl, setCopiedPl] = useState();
  const [workplace, setWorkplace] = useState("");
  const [circleCount, setCircleCount] = useState();
  const [circleMember, setCircleMember] = useState();
  const [location, setLocation] = useState("");
  const [isModalShareOther, setIsModalShareOther] = useState(false);
  const [isSMAdded, setIsSMAdded] = useState(false);
  const [isNavbar, setIsNavbar] = useState(false);
  const [isModalSites, setIsModalSites] = useState(false);
  const [isShortlys, setIsShortlys] = useState(true);
  const [isCircles, setIsCircles] = useState(false);
  const [isCustomLinkAdded, setIsCustomLinkAdded] = useState(false);
  const [isPlViewModal, setIsPlViewModal] = useState(false);
  const [isPLAdded, setIsPLAdded] = useState(false);
  const [smType, setSMType] = useState("");
  const [smLink, setSMLink] = useState("");
  const [customLink, setCustomLink] = useState("");
  const [customTitle, setCustomTitle] = useState("");
  const [plType, setPlType] = useState("");
  const [plLink, setPlLink] = useState("");
  const [isCircleAdd] = useState(false);
  const [isGuestOptionModal, setIsGuestOptionModal] = useState(false);
  const [isGuestCustomModal, setIsGuestCustomModal] = useState(false);
  const [isSiteAdded, setIsSiteAdded] = useState(false);
  const [isSitesOpModalGuest, setIsSitesOpModalGuest] = useState(false);
  const [socialMediaLinks, setSocialMediaLinks] = useState([]);
  const [privateLinks, setPrivateLinks] = useState([]);
  const [customImages, setCustomImages] = useState({});
  const [, setIsConnectModal] = useState(false);
  const [, setIsReportModal] = useState(false);
  const [guestUserID, setGuestUserID] = useState("");
  const [copiedSMLink, setCopiedSMLink] = useState("");
  const [copiedCustomLink, setCopiedCustomLink] = useState("");
  const isModalShareOtherRef = useRef(null);
  const modalPlViewRef = useRef(null);
  const modalScanRef = useRef(null);
  const isModalSitesRef = useRef(null);
  const isModalShareOwnRef = useRef(null);
  const viewGuestImageRef = useRef(null);
  const [isViewGuestImage, setIsViewGuestImage] = useState(false);
  const [isGuestImage, setIsGuestImage] = useState(false);
  const [isUsernameExists, setIsUsernameExists] = useState(false);
  const [selectedUserTheme, setSelectedUserTheme] = useState([]);
  const [guestTheme, setGuestTheme] = useState([]);
  const [profileUsername, setProfileUsername] = useState("");
  const [isGuestAndUserID, setIsGuestAndUserID] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [consoleError, setConsoleError] = useState("");
  const [isConsoleError, setIsConsoleError] = useState(false);
  const [isCircleUpgradeCanvas, setIsCircleUpgradeCanvas] = useState(false);
  const [isViewAsGuest, setIsViewAsGuest] = useState(false);
  const circleUpgradeRef = useRef(null);

  const navigate = useNavigate();
  const userId = Cookies.get("userId");

  //close modal when click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        circleUpgradeRef.current &&
        !circleUpgradeRef.current.contains(event.target)
      ) {
        setIsCircleUpgradeCanvas(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [circleUpgradeRef]);

  //fetch data while API call
  const fetchDataWithLoading = async (apiCall) => {
    setIsLoading(true);
    try {
      await apiCall();
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAllData = async () => {
    await Promise.all([
      fetchDataWithLoading(fetchUserDetails),
      fetchDataWithLoading(fetchGuestImage),
      fetchDataWithLoading(fetchUserExist),
      fetchDataWithLoading(fetchedTheme),
      fetchDataWithLoading(fetchUsername),
    ]);
  };

  useEffect(() => {
    fetchDataWithLoading(fetchAllData);
  }, [userId]);

  //click outside to close the modal
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        viewGuestImageRef.current &&
        !viewGuestImageRef.current.contains(event.target)
      ) {
        setIsViewGuestImage(false);
      }

      if (
        isModalShareOtherRef.current &&
        !isModalShareOtherRef.current.contains(event.target)
      ) {
        // setIsModalShareOther(false);
      }
      if (
        isModalShareOwnRef.current &&
        !isModalShareOwnRef.current.contains(event.target)
      ) {
        // setIsModalShareOwn(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [viewGuestImageRef, isModalShareOtherRef, isModalShareOwnRef]);

  //change isNavbar background when scrolling body
  const changeBackground = () => {
    if (window.scrollY > 10) {
      setIsNavbar(true);
    } else {
      setIsNavbar(false);
    }
  };

  const { guestUsername } = useParams();

  Cookies.remove("guestUserAdd");
  Cookies.remove("guestUsername");
  Cookies.remove("username");
  Cookies.remove("loginToView");

  useEffect(() => {
    if (userId) {
      setIsUserFound(true);
    }
    const fetchedPP = async () => {
      try {
        const response = await fetch(
          `${API_DOMAIN}/profile/image/view?fk_user_id=${userId}`
        );
        if (response.ok) {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.startsWith("image/")) {
            const blob = await response.blob();
            const imageUrl = URL.createObjectURL(blob);
            setUserPhoto(imageUrl);
            setIsOwnPhoto(true);
          } else {
            setIsOwnPhoto(false);
          }
        } else {
          setIsOwnPhoto(false);
        }
      } catch (error) {
        if (error.message === "Failed to fetch") {
          setIsConsoleError(true);
          setConsoleError("Unable to connect. Please check back shortly!");
        } else {
          setIsConsoleError(true);
          setConsoleError(error.message);
        }
      }
    };
    fetchedPP();
  }, [userId]);

  const setIconType = (type) => {
    switch (type) {
      case "Facebook":
        return <BiLogoFacebook />;
      case "Instagram":
        return <BsInstagram />;
      case "X":
        return <BsTwitterX />;
      case "Youtube":
        return <BsYoutube />;
      case "TikTok":
        return <BiLogoTiktok />;
      case "Pinterest":
        return <FaPinterestP />;
      case "Patreon":
        return <BiLogoPatreon />;
      case "Tumblr":
        return <FaTumblr />;
      case "VK":
        return <SlSocialVkontakte />;
      case "LinkedIn":
        return <BiLogoLinkedin />;
      case "Snapchat":
        return <BsSnapchat />;
      default:
        return null;
    }
  };

  const fetchUserDetails = async () => {
    try {
      const response = await fetch(
        `${API_DOMAIN}/guest/view?username=${guestUsername}`
      );
      const result = await response.json();
      const userData = result.data;

      setFullName(userData.fullName);
      setFirstName(userData.firstName);
      setGuestUserID(userData.userId);

      if (userData.bio) {
        setBio(userData.bio);
      }

      if (userData.location) {
        setLocation(userData.location);
      }

      if (userData.position) {
        setPosition(userData.position);
      }

      if (userData.workPlace) {
        setWorkplace(userData.workPlace);
      }

      if (userData.title && userData.url) {
        setSiteTitle(userData.title);
        setSiteLink(userData.url);
        setIsSiteAdded(true);
      }

      setCircleCount(userData.circleCount);
      setCircleMember(userData.memberCount);

      const theme = userData.color;
      const themeArray = [theme.light, theme.mid, theme.dark];
      setGuestTheme(themeArray);

      const socialMediaLinks = userData.socialMediaLinks;
      if (socialMediaLinks) {
        const social = socialMediaLinks.map((link) => ({
          icon: setIconType(link.type),
          type: link.type,
          smLink: link.link,
        }));
        setSocialMediaLinks(social);
        setIsSMAdded(true);
      }

      const temp = userData.tempFillOrBorder;
      setTemplate(temp);

      const custom = userData.customLinks;
      if (custom) {
        const cLinks = custom.map((link) => ({
          c_Id: link.customId,
          customUrl: link.url,
          customTitle: link.title,
        }));
        setCustomLinks(cLinks);
        setIsCustomLinkAdded(true);
        cLinks.forEach((cLink) => {
          fetchedCustomImage(cLink.c_Id);
        });
      }

      const privateLink = userData.privateLinks;
      if (privateLink) {
        const pLinks = privateLink.map((link) => ({
          plIcon: setPLType(link.type),
          privateLink: link.type,
          privacy_status: link.privacy_status || link.privacyStatus,
          pl: link.link || link.contactNo,
        }));
        setPrivateLinks(pLinks);
        setIsPLAdded(true);
      }
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        if (
          error.message ===
          "Cannot read properties of undefined (reading 'fullName')"
        ) {
          setIsConsoleError(true);
          setConsoleError(
            "Username not found. Please check and enter a valid username."
          );
        } else {
          setIsConsoleError(true);
          setConsoleError(error.message);
        }
      }
    }
  };

  useEffect(() => {
    const idOfUser = Cookies.get("userId");
    const idOfGuest = `${guestUserID}`;
    if (idOfGuest === idOfUser) {
      setIsGuestAndUserID(true);
      setIsUsernameExists(true);

      if (isGuestAndUserID) {
        setIsUsernameExists(false);
      } else {
        setIsUsernameExists(true);
      }
    }
  }, [guestUserID]);

  const handleLinksOnClink = (link) => {
    const redirect = "guestView";
    Cookies.set("redirect", redirect);
    Cookies.set("guestUsername", guestUsername);
    window.location.href = link;
  };

  const fetchedCustomImage = async (customId) => {
    try {
      const response = await fetch(
        ` ${API_DOMAIN}/customlinks/viewImage?customId=${customId}`
      );
      if (response.ok) {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.startsWith("image/")) {
          const imageBlob = await response.blob();
          const imageUrl = URL.createObjectURL(imageBlob);
          setCustomImages((prevImages) => ({
            ...prevImages,
            [customId]: imageUrl,
          }));
        } else {
          const res = await response.json();
          if (!res.success) {
            setCustomImages((prevImages) => ({
              ...prevImages,
              [customId]: null,
            }));
          }
        }
      }
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  const fetchGuestImage = async () => {
    try {
      const response = await fetch(
        `${API_DOMAIN}/guest/profile/image/view?username=${guestUsername}`
      );
      if (response.ok) {
        const blob = await response.blob();
        const imageUrl = URL.createObjectURL(blob);
        setProfileImage(imageUrl);
        setIsGuestImage(true);
      } else {
        setIsGuestImage(false);
      }
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  const fetchedTheme = async () => {
    try {
      const response = await fetch(`${API_DOMAIN}/theme/view?userId=${userId}`);
      const result = await response.json();
      if (result.success) {
        const { light, mid, dark } = jwtDecode(result.token).theme[0].color;
        const themeArray = [light, mid, dark];
        setSelectedUserTheme(themeArray);
      }
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  window.addEventListener("scroll", changeBackground);

  //lottie animation
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  //copy private link to clipboard
  const copyPL = () => {
    const linkText = document.querySelector(".pl-link").textContent;
    navigator.clipboard
      .writeText(linkText)
      .then(() => {
        setCopiedPl(1);
        setTimeout(() => setCopiedPl(0), 3000); // Reset the copied state after 2 seconds
      })
      .catch((err) => {
        setIsConsoleError(true);
        setConsoleError(String(err));
      });
  };

  const fetchUserExist = async () => {
    try {
      const response = await fetch(
        `${API_DOMAIN}/follower/username/view?userId=${userId}`
      );
      const result = await response.json();
      if (result.success) {
        const { follower } = jwtDecode(result.token);
        // Check if the current user's name is in the list of followers
        const userExists = follower.some(
          (user) => user.username === guestUsername
        );
        if (userExists) {
          setIsUsernameExists(true);
        }
      }
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  const fetchUsername = async () => {
    try {
      const response = await fetch(`${API_DOMAIN}/user?userId=${userId}`);
      const result = await response.json();
      if (result.success) {
        const { userName } = jwtDecode(result.token);
        setProfileUsername(userName);
      }
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  const smLinksCopyToClipboard = () => {
    const textToCopy = `${smLink}`;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopiedSMLink("Copied");
      })
      .catch((error) => {
        setIsConsoleError(true);
        setConsoleError(error.message);

        setCopiedSMLink("Failed to copy");
      });
  };

  const customLinksCopyToClipboard = () => {
    const textToCopy = `${customLink}`;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopiedCustomLink("Copied");
      })
      .catch((error) => {
        setIsConsoleError(true);
        setConsoleError(error.message);

        setCopiedCustomLink("Failed to copy");
      });
  };

  const setPLType = (iconPl) => {
    switch (iconPl) {
      case "Phone home":
        return (
          <div className="pl-icon-sec">
            <img src={PhoneHome} alt="" className="pl-icon-img" />
          </div>
        );
      case "Phone mobile":
        return (
          <div className="pl-icon-sec">
            <img src={PhoneW} alt="" className="pl-icon-img" />
          </div>
        );

      case "Email Address":
        return (
          <div className="pl-icon-sec">
            <img src={EmailW} alt="" className="pl-icon-img" />
          </div>
        );
      case "Mailing Address":
        return (
          <div className="pl-icon-sec">
            <img src={AddressW} alt="" className="pl-icon-img" />
          </div>
        );
      case "Whatsapp":
        return (
          <div className="pl-icon-sec">
            <img src={WhatsappW} alt="" className="pl-icon-img" />
          </div>
        );
      case "Telegram":
        return (
          <div className="pl-icon-sec">
            <img src={TelegramW} alt="" className="pl-icon-img" />
          </div>
        );
      case "Viber":
        return (
          <div className="pl-icon-sec-rio">
            <img
              src="https://img.icons8.com/external-tal-revivo-bold-tal-revivo/96/FFFFFF/external-viber-logo-with-hand-phone-receiver-under-chat-bubble-logo-bold-tal-revivo.png"
              alt="external-viber-logo-with-hand-phone-receiver-under-chat-bubble-logo-bold-tal-revivo"
              className="pl-icon-img-rio"
            />
            {/* <img="" className="pl-icon-img-rio" /> */}
          </div>
        );
      case "WeChat":
        return (
          <div className="pl-icon-sec-rio">
            <img
              src="https://img.icons8.com/external-tal-revivo-bold-tal-revivo/96/FFFFFF/external-wechat-worlds-largest-standalone-mobile-apps-by-monthly-active-users-logo-bold-tal-revivo.png"
              alt="external-wechat-worlds-largest-standalone-mobile-apps-by-monthly-active-users-logo-bold-tal-revivo"
              className="pl-icon-img-rio"
            />
            {/* <BsWechat className="pl-icon-img-rio" /> */}
          </div>
        );

      default:
        return null;
    }
  };

  const handleLeaveIconClick = () => {
    navigate(`/@${profileUsername}`);
  };

  return (
    <>
      {isLoading && <Loader />}

      <div
        className="guest-view-wrapper"
        style={{
          background: isGuestAndUserID
            ? `linear-gradient(to bottom, ${selectedUserTheme[0]}, ${selectedUserTheme[2]})`
            : `linear-gradient(to bottom, ${guestTheme[0]}, ${guestTheme[2]})`,
        }}
      >
        {isViewAsGuest && (
          <div className="view-as-guest-error">
            <div className="guest-error-desc">
              Action unavailable. You need to leave guest view to proceed.
            </div>
            <div
              className="guest-error-btn"
              onClick={() => {
                setIsViewAsGuest(false);
              }}
            >
              Ok
            </div>
          </div>
        )}

        {isViewGuestImage && (
          <div className="guest-image-modal" ref={viewGuestImageRef}>
            {isGuestImage ? (
              <img
                src={profileImage}
                alt="Profile"
                className="view-guest-image"
              />
            ) : (
              <img src={User} alt="Profile" className="no-guest-image" />
            )}
          </div>
        )}

        {isModalScanQr && (
          <div className="scan-qr-modal">
            <QRReader closeScanModal={setIsModalScanQr} />
          </div>
        )}
        {isGuestOptionModal && (
          <div className="guest-options-modal">
            <div className="gom-header">
              <div className="gom-title">{smType}</div>
              <div
                className="gom-close"
                onClick={() => {
                  setIsGuestOptionModal(false);
                }}
              >
                <RxCross2 />
              </div>
            </div>
            <div className="gom-body">
              <div className="gom-sm-link">
                <div className="gom-sm-link-text">{smLink}</div>
                <div className="gom-sm-copy" onClick={smLinksCopyToClipboard}>
                  {copiedSMLink ? (
                    <div className="copied-text">Copied</div>
                  ) : (
                    <MdOutlineContentCopy />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {isGuestCustomModal && (
          <div className="guest-options-modal">
            <div className="gom-header">
              <div className="gom-title">{customTitle}</div>
              <div
                className="gom-close"
                onClick={() => {
                  setIsGuestCustomModal(false);
                }}
              >
                <RxCross2 />
              </div>
            </div>
            <div className="gom-body">
              <div className="gom-sm-link">
                <div className="gom-sm-link-text">{customLink}</div>
                <div
                  className="gom-sm-copy"
                  onClick={customLinksCopyToClipboard}
                >
                  {copiedCustomLink ? (
                    <div className="copied-text">Copied</div>
                  ) : (
                    <MdOutlineContentCopy />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {isSitesOpModalGuest && (
          <div className="guest-sites-options">
            <div className="gom-header">
              <div className="gom-title">{siteTitle}</div>
              <div
                className="gom-close"
                onClick={() => {
                  setIsSitesOpModalGuest(false);
                }}
              >
                <RxCross2 />
              </div>
            </div>

            <div className="gso-body">
              <div className="gom-sm-link">
                <div className="gom-sm-link-text">{siteLink}</div>
                <div className="gom-sm-copy">
                  <MdOutlineContentCopy />
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="guest-view-container">
          {isGuestAndUserID && (
            <div className="view-as-guest">
              <BiLogOutCircle
                className="view-as-guest-icon"
                onClick={handleLeaveIconClick}
              />
              <div className="view-as-guest-desc">Viewing as a Guest User</div>
            </div>
          )}
          <div
            className={`isNavbar ${
              isNavbar ? "isNavbar-active" : "isNavbar-inactive"
            }`}
          >
            <div className="home-btn">
              <img src={Logo} alt="" className="home-t-logo" />
            </div>
            <div className="profile-options-btn">
              <SlOptions className="profile-options-icon" />
            </div>
          </div>

          <div className="profile-top">
            <div className="section1">
              <div className="connections">
                <span
                  className="connection-count"
                  style={{ backgroundColor: guestTheme[1], opacity: 0.7 }}
                >
                  {circleMember}
                </span>
                <Lottie
                  options={defaultOptions}
                  height={50}
                  width={50}
                  className="connection-icon"
                />
              </div>
              <div className="guest-profile">
                <div
                  className={`guest-user-add ${
                    isUsernameExists ? "check" : "plus"
                  }`}
                >
                  <UserConnect
                    connect={setIsConnectModal}
                    guestUser={guestUserID}
                    name={guestUsername}
                    fetchUserExist={fetchUserExist}
                    isUsernameExists={isUsernameExists}
                    connectIcon={"Plus"}
                    isGuestAndUserID={isGuestAndUserID}
                    setIsViewAsGuest={setIsViewAsGuest}
                  />
                </div>
                <div className="guest-profile-image">
                  {isGuestImage ? (
                    <img
                      src={profileImage}
                      alt=""
                      style={{ width: "112px", height: "112px" }}
                      onClick={() => {
                        if (isGuestAndUserID) {
                          setIsViewAsGuest(true);
                        } else {
                          setIsViewGuestImage(true);
                        }
                      }}
                    />
                  ) : (
                    <img
                      src={User}
                      alt="guest-user"
                      onClick={() => {
                        if (isGuestAndUserID) {
                          setIsViewAsGuest(true);
                        } else {
                          setIsViewGuestImage(true);
                        }
                      }}
                    />
                  )}
                </div>
              </div>
              <div
                className="share-profile"
                onClick={() => {
                  if (isGuestAndUserID) {
                    setIsViewAsGuest(true);
                  } else {
                    setIsModalShareOther(true);
                  }
                }}
                ref={isModalShareOtherRef}
                style={{
                  backgroundColor: guestTheme[1],
                  opacity: 0.7,
                  border: `2px solid ${guestTheme[2]}`,
                }}
              >
                <img src={Share} alt="" className="share-icon" />
              </div>
            </div>

            {isModalShareOther && (
              <div className="guest-sp-modal-other">
                <ShareProfile
                  closeShareModal={setIsModalShareOther}
                  guestUserName={guestUsername}
                />
              </div>
            )}

            {isModalShareOwn && (
              <div className="guest-sp-modal-own">
                <ShareProfile
                  closeShareModal={setIsModalShareOwn}
                  username={profileUsername}
                />
              </div>
            )}

            <div className="section2guest">
              <span className="name">{`${fullName}`}</span>
              <div className="uname-report">
                <span className="user-name">@{guestUsername}</span>
                {!isGuestAndUserID && (
                  <ReportProfile
                    setIsReportModal={setIsReportModal}
                    guestUsername={guestUsername}
                  />
                )}
              </div>
            </div>
            <div className="section3" ref={isModalSitesRef}>
              <div
                className="sites-wrapper"
                onClick={() => {
                  if (isGuestAndUserID) {
                    setIsViewAsGuest(true);
                  } else {
                    setIsModalSites(true);
                  }
                }}
                style={{
                  backgroundColor: guestTheme[1],
                  opacity: 0.7,
                }}
              >
                <CiGlobe className="sites" />
              </div>
              {isModalSites && (
                <div className="sites-view-popup">
                  <div className="sites-vp-title">
                    {firstName}'s Sites
                    <div
                      className="sites-vp-close"
                      onClick={() => setIsModalSites(false)}
                    >
                      <RxCross2 />
                    </div>
                  </div>
                  <div className="sites-vp-body">
                    {isSiteAdded ? (
                      <div className="sites-vlist">
                        <a
                          href={`https://${siteLink}`}
                          target="blank"
                          className="site-name"
                        >
                          {siteTitle}
                        </a>

                        <span
                          className="sites-options"
                          onClick={() => {
                            setIsSitesOpModalGuest(true);
                          }}
                        >
                          <BsThreeDots className="sites-options-icon" />
                        </span>
                      </div>
                    ) : (
                      <div className="sites-vlist-no-sites">No sites added</div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="section4">
              <div className="guest-bio">{bio ? `${bio}` : ""}</div>
            </div>
            <div className="section5">
              <div className="demographic">
                {location ? (
                  <img src={Location} alt="" className="demo-icon" />
                ) : (
                  ""
                )}

                <div className="guest-demo-desc">
                  {location
                    ? `${location.slice(0, 20)}${
                        location.length > 20 ? "..." : ""
                      }`
                    : ""}
                </div>
              </div>
              <div className="demographic">
                {position ? (
                  <img src={Designation} alt="" className="demo-icon" />
                ) : (
                  ""
                )}

                <div className="guest-demo-desc">
                  {position
                    ? `${position.slice(0, 20)}${
                        position.length > 20 ? "..." : ""
                      }`
                    : ""}
                </div>
              </div>
              <div className="demographic">
                {workplace ? (
                  <img src={Work} alt="" className="demo-icon" />
                ) : (
                  ""
                )}

                <div className="guest-demo-desc">
                  {workplace
                    ? `${workplace.slice(0, 20)}${
                        workplace.length > 20 ? "..." : ""
                      }`
                    : ""}
                </div>
              </div>
            </div>
          </div>
          <div className="profile-body">
            <div className="tabs">
              <div
                className={`tab-section-shortlys ${
                  isShortlys ? "active-shortlys" : ""
                }`}
                onClick={() => {
                  setIsShortlys(true);
                  setIsCircles(false);
                }}
              >
                <img src={Shortlys} alt="" className="tab-image" />
                <span className="tab-title">Shortlys</span>
                <span className="bar"></span>
              </div>
              <div
                className={`tab-section-circles ${
                  isCircles ? "active-circles" : ""
                }`}
                onClick={() => {
                  setIsCircles(true);
                  setIsShortlys(false);
                }}
              >
                <img src={Circles} alt="" className="tab-image" />
                <span className="tab-title">Circles</span>
                <span className="bar"></span>
              </div>
            </div>

            {isShortlys && (
              <div
                className={`guest-shortlys ${
                  isSMAdded ? "default-profile-smadd" : "default-profile-smnot"
                }`}
              >
                <div className="dp-sm-links">
                  {isSMAdded &&
                    socialMediaLinks.map((link, index) => (
                      <div
                        className={`sm-links-added ${
                          template === "fill"
                            ? "template-fill"
                            : "template-border"
                        }`}
                        key={index}
                      >
                        <div
                          className="sm-links-logo"
                          onClick={() => {
                            if (isGuestAndUserID) {
                              setIsViewAsGuest(true);
                            } else {
                              handleLinksOnClink(link.smLink);
                            }
                          }}
                        >
                          {link.icon}
                        </div>
                        <h
                          className="sm-links-title"
                          onClick={() => {
                            if (isGuestAndUserID) {
                              setIsViewAsGuest(true);
                            } else {
                              handleLinksOnClink(link.smLink);
                            }
                          }}
                        >
                          {link.type}
                        </h>

                        <span className="options">
                          <BsThreeDots
                            onClick={() => {
                              if (isGuestAndUserID) {
                                setIsViewAsGuest(true);
                              } else {
                                setIsGuestOptionModal(true);
                                setSMType(link.type);
                                setSMLink(link.smLink);
                                setCopiedSMLink("");
                              }
                            }}
                          />
                        </span>
                      </div>
                    ))}

                  {isCustomLinkAdded &&
                    customLinks.map((link, index) => (
                      <div
                        className={`sm-links-added ${
                          template === "fill"
                            ? "template-fill"
                            : "template-border"
                        }`}
                        key={index}
                      >
                        {customImages[link.c_Id] ? (
                          <div
                            className="cl-links-logo"
                            onClick={() => handleLinksOnClink(link.customUrl)}
                          >
                            <img
                              src={customImages[link.c_Id]}
                              alt="Custom"
                              className="custom-image"
                            />
                          </div>
                        ) : (
                          <div
                            className="sm-links-logo"
                            onClick={() => handleLinksOnClink(link.customUrl)}
                          >
                            <TbWorld />
                          </div>
                        )}
                        <h
                          className="sm-links-title"
                          onClick={() => handleLinksOnClink(link.customUrl)}
                        >
                          {link.customTitle}
                        </h>
                        <span className="options">
                          <BsThreeDots
                            onClick={() => {
                              setIsGuestCustomModal(true);
                              setCustomTitle(link.customTitle);
                              setCustomLink(link.customUrl);
                              setCopiedCustomLink("");
                            }}
                          />
                        </span>
                      </div>
                    ))}
                </div>
                <div className="dp-p-links" ref={modalPlViewRef}>
                  <div
                    className="pl-icons"
                    onClick={() => {
                      if (isGuestAndUserID) {
                        setIsViewAsGuest(true);
                      } else {
                        setIsPlViewModal(!isPlViewModal);
                      }
                    }}
                  >
                    {isPLAdded &&
                      privateLinks.map((link, plId) => (
                        <div
                          className="pl-icon"
                          key={plId}
                          onClick={() => {
                            setPrivacy(link.privacy_status);
                            setPlType(link.type);
                            setPlLink(link.pl);
                          }}
                        >
                          {link.plIcon}
                        </div>
                      ))}
                  </div>
                  {privacy === "Public" && isPlViewModal && (
                    <div className="pl-view-modal">
                      <div className="pl-view-details">
                        <div className="pl-view-type">{plType}</div>
                        <div className="pl-view-desc">
                          <div className="pl-link">{plLink}</div>
                          <div
                            className="pl-copy-btn"
                            onClick={() => copyPL(`${plLink}`, 1)}
                          >
                            {copiedPl === 1 ? (
                              "Copied"
                            ) : (
                              <MdOutlineContentCopy />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {privacy === "On-Request" && isPlViewModal && (
                    <div className="pl-view-modal-upgrade">
                      <div className="pl-modal-upgrade-close">
                        <div
                          className="plm-close"
                          onClick={() => setIsPlViewModal(false)}
                        >
                          <RxCross2 />
                        </div>
                      </div>
                      <div className="pl-upgrade-canvas">
                        <div className="plv-upgrade-msg">
                          You don't have permission to view Private Links.
                        </div>
                        <div className="plv-upgrade-msg">
                          <b>
                            Upgrade to the premium version to view Private
                            Links.
                          </b>
                        </div>
                        <div
                          className="plv-upgrade-btn"
                          onClick={() => {
                            setIsCircleUpgradeCanvas(true);
                          }}
                        >
                          Upgrade
                        </div>
                      </div>
                      {isCircleUpgradeCanvas && (
                        <div ref={circleUpgradeRef}>
                          <UpgradePlans
                            closeUpgradeModal={setIsCircleUpgradeCanvas}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}

            {isCircles && (
              <div
                className={`circles-view-body ${
                  isCircleAdd ? "circle-body-added" : "circle-body-not-added"
                }`}
              >
                <div className="connect-with-another">
                  <div className="cwa-circle-count">
                    <div className="cwa-con-count">{circleMember}</div>
                    <div className="cwa-circles">in {circleCount} circle</div>
                  </div>
                  <div className="cwa-connect-btn">
                    <div className="cwa-connect-btn-connected">
                      <UserConnect
                        connect={setIsConnectModal}
                        guestUser={guestUserID}
                        name={guestUsername}
                        fetchUserExist={fetchUserExist}
                        isUsernameExists={isUsernameExists}
                        connectIcon={"Connect"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {isUserFound && (
            <div className="fab">
              <div className="notifications">
                <Notification />
              </div>
              <Link to={`/@${profileUsername}`}>
                <div className="user">
                  {isOwnPhoto ? (
                    <img
                      src={userPhoto}
                      alt="user"
                      className="fab-user-photo"
                    />
                  ) : (
                    <img src={User} alt="" className="fab-user-photo-icon" />
                  )}
                </div>
              </Link>
              <div
                className="dots"
                onClick={() => {
                  setIsFabOpen(!isFabOpen);
                }}
              >
                <img src={Fab} alt="" className="fab-icon" />
              </div>

              {isFabOpen && (
                <div className="fab-menu">
                  <div
                    className="share-own"
                    onClick={() => {
                      setIsModalShareOwn(true);
                    }}
                    ref={isModalShareOwnRef}
                    style={{
                      backgroundColor: selectedUserTheme[1],
                      opacity: 0.7,
                    }}
                  >
                    <img src={Share} alt="" className="share-own-icon" />
                  </div>
                  <div
                    className="qr"
                    onClick={() => {
                      setIsModalScanQr(true);
                    }}
                    ref={modalScanRef}
                  >
                    <img src={QR} alt="" className="fab-qr-icon" />
                  </div>

                  <div className="sites-add">
                    <AddSites />
                  </div>
                </div>
              )}
            </div>
          )}

          <div className="profile-bottom">
            <Footer />
          </div>
        </div>
      </div>
      <ConsoleError
        isConsoleError={isConsoleError}
        consoleError={consoleError}
        closeConsoleError={setIsConsoleError}
      />
    </>
  );
}

import React from "react";
import { IoCheckmark } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import "./UpgradePlans.css";

export default function UpgradePlans({ closeUpgradeModal }) {
  return (
    <div className="upgrade-modal">
      <div className="upgrade-header">
        <div className="uh-title">Upgrade your Account</div>
        <div
          className="uh-close-btn"
          onClick={() => {
            closeUpgradeModal(false);
          }}
        >
          <RxCross2 />
        </div>
      </div>
      <div className="upgrade-canvas">
        <div className="free-plan-canvas">
          <div className="pc-top">
            <div className="plan-name">FREE</div>
            <div className="plan-price">$0</div>
            <div className="plan-sub-desc">Free for a lifetime</div>
          </div>

          <button className="pc-middle-btn">Current Plan</button>

          <div className="pc-content">
            <div className="plan-features">
              <IoCheckmark className="plan-features-icon" />
              <p className="plan-features-desc">Unlimited profile visits</p>
            </div>
            <div className="plan-features">
              <IoCheckmark className="plan-features-icon" />
              <p className="plan-features-desc">Unlimited fan base</p>
            </div>
            <div className="plan-features">
              <IoCheckmark className="plan-features-icon" />
              <p className="plan-features-desc">25 short Links</p>
            </div>
            <div className="plan-features">
              <IoCheckmark className="plan-features-icon" />
              <p className="plan-features-desc">5 Circles</p>
            </div>
            <div className="plan-features">
              <IoCheckmark className="plan-features-icon" />
              <p className="plan-features-desc">3 Profile themes</p>
            </div>
            <div className="plan-features">
              <RxCross2 className="plan-features-icon" />
              <p className="plan-features-desc">Private profile</p>
            </div>
            <div className="plan-features">
              <RxCross2 className="plan-features-icon" />
              <p className="plan-features-desc">Profile verification</p>
            </div>
            <div className="plan-features">
              <RxCross2 className="plan-features-icon" />
              <p className="plan-features-desc">Image Background</p>
            </div>
          </div>

          <div className="pc-footer">See more...</div>
        </div>
        <div className="silver-plan-canvas">
          <div className="pc-top">
            <div className="plan-name">SILVER</div>
            <div className="plan-price">$9.99</div>
            <div className="plan-sub-desc">Per year</div>
          </div>

          <button className="pc-middle-btn">Get Silver Plan</button>

          <div className="pc-content">
            <div className="plan-features">
              <IoCheckmark className="plan-features-icon" />
              <p className="plan-features-desc">Unlimited profile visits</p>
            </div>
            <div className="plan-features">
              <IoCheckmark className="plan-features-icon" />
              <p className="plan-features-desc">Unlimited fan base</p>
            </div>
            <div className="plan-features">
              <IoCheckmark className="plan-features-icon" />
              <p className="plan-features-desc">50 short Links</p>
            </div>
            <div className="plan-features">
              <IoCheckmark className="plan-features-icon" />
              <p className="plan-features-desc">10 Circles</p>
            </div>
            <div className="plan-features">
              <IoCheckmark className="plan-features-icon" />
              <p className="plan-features-desc">15 Profile themes</p>
            </div>
            <div className="plan-features">
              <IoCheckmark className="plan-features-icon" />
              <p className="plan-features-desc">Private profile</p>
            </div>
            <div className="plan-features">
              <RxCross2 className="plan-features-icon" />
              <p className="plan-features-desc">Profile verification</p>
            </div>
            <div className="plan-features">
              <RxCross2 className="plan-features-icon" />
              <p className="plan-features-desc">Image Background</p>
            </div>
          </div>

          <div className="pc-footer">See more...</div>
        </div>
        <div className="gold-plan-canvas">
          <div className="pc-top">
            <div className="plan-name">GOLD</div>
            <div className="plan-price">$29.99</div>
            <div className="plan-sub-desc">Per year</div>
          </div>

          <button className="pc-middle-btn">Get Gold Plan</button>

          <div className="pc-content">
            <div className="plan-features">
              <IoCheckmark className="plan-features-icon" />
              <p className="plan-features-desc">Unlimited profile visits</p>
            </div>
            <div className="plan-features">
              <IoCheckmark className="plan-features-icon" />
              <p className="plan-features-desc">Unlimited fan base</p>
            </div>
            <div className="plan-features">
              <IoCheckmark className="plan-features-icon" />
              <p className="plan-features-desc">Unlimited short Links</p>
            </div>
            <div className="plan-features">
              <IoCheckmark className="plan-features-icon" />
              <p className="plan-features-desc">20 Circles</p>
            </div>
            <div className="plan-features">
              <IoCheckmark className="plan-features-icon" />
              <p className="plan-features-desc">30 Profile themes</p>
            </div>
            <div className="plan-features">
              <IoCheckmark className="plan-features-icon" />
              <p className="plan-features-desc">Private profile</p>
            </div>
            <div className="plan-features">
              <IoCheckmark className="plan-features-icon" />
              <p className="plan-features-desc">Profile verification</p>
            </div>
            <div className="plan-features">
              <IoCheckmark className="plan-features-icon" />
              <p className="plan-features-desc">Image Background</p>
            </div>
          </div>

          <div className="pc-footer">See more...</div>
        </div>
        <div className="compare-plans">
          Compare plans and features <b className="click-plan-compare">here</b>.
        </div>
      </div>
      <div className="upgrade-modal-footer"></div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import t1 from "../../Assets/ThemeRed.svg";
import t2 from "../../Assets/ThemeGreen.svg";
import t3 from "../../Assets/ThemeBlue.svg";
import StepBar from "../../Components/StepBar/StepBar";
import Footer from "../../Components/Footer/Footer";
import API_DOMAIN from "../../ApiConfig";
import ConsoleError from "../../Components/ConfirmationMessage/ConsoleError";
import "./SetTheme.css";

const SetTheme = () => {
  const [selectedTheme, setSelectedTheme] = useState({
    light: "#0ddfff",
    mid: "#1fd093",
    dark: "#30c027",
  });
  const [consoleError, setConsoleError] = useState("");
  const [isConsoleError, setIsConsoleError] = useState(false);
  const [selectedThemeImage, setSelectedThemeImage] = useState("t2");

  const userId = Cookies.get("userId");

  const handleImageClick = (tid) => {
    setSelectedThemeImage(tid);
  };

  useEffect(() => {
    // Get theme data from Session Storage
    const savedTheme = sessionStorage.getItem("theme");

    if (savedTheme) setSelectedTheme(savedTheme);

    window.addEventListener("beforeunload", clearSessionStorage);

    return () => {
      window.removeEventListener("beforeunload", clearSessionStorage);
    };
  }, []);

  // Add theme data to Session Storage
  const handleClick = () => {
    sessionStorage.setItem("theme", selectedTheme);
  };

  // Clear theme data from Session Storage
  const clearSessionStorage = () => {
    sessionStorage.removeItem("theme");
  };

  const handleSubmit = async () => {
    // Retrieve data from session storage
    const firstName = sessionStorage.getItem("firstName");
    const lastName = sessionStorage.getItem("lastName");
    const username = sessionStorage.getItem("userName");
    const image = sessionStorage.getItem("image");
    const bio = sessionStorage.getItem("bio");
    const country = sessionStorage.getItem("country");
    const city = sessionStorage.getItem("city");
    const position = sessionStorage.getItem("position");
    const workplace = sessionStorage.getItem("workplace");

    // Send Theme to API
    const postTheme = async () => {
      try {
        await fetch(`${API_DOMAIN}/theme/update`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId, color: selectedTheme }),
        });
        const themeArray = [
          selectedTheme.light,
          selectedTheme.mid,
          selectedTheme.dark,
        ];
        Cookies.set("theme", JSON.stringify(themeArray), {
          expires: 1 / 12,
        });
      } catch (error) {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    };

    // Send Username to API
    const postUsername = async () => {
      try {
        await fetch(`${API_DOMAIN}/login/update`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId,
            firstName,
            lastName,
            username,
          }),
        });
      } catch (error) {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    };

    // Send profile photo to API
    const postPhoto = async () => {
      try {
        await fetch(`${API_DOMAIN}/profile/image/upload`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId, image }),
        });
      } catch (error) {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    };

    // Send bio description to API
    const postBio = async () => {
      try {
        if (bio !== null) {
          const response = await fetch(`${API_DOMAIN}/bio`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ userId, bio }),
          });
          const result = await response.json();
          if (result.error) {
            setIsConsoleError(true);
            setConsoleError(result.error);
          }
        }
      } catch (error) {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    };

    // Send Country and City to API
    const postCountryCity = async () => {
      try {
        if (country !== null || city !== null) {
          const response = await fetch(`${API_DOMAIN}/location`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ userId, country, city }),
          });
          const result = await response.json();
          if (result.error) {
            setIsConsoleError(true);
            setConsoleError(result.error);
          }
        }
      } catch (error) {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    };

    // Send Designation to API
    const postDesignation = async () => {
      try {
        if (position !== null) {
          const response = await fetch(`${API_DOMAIN}/designation`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ userId, position }),
          });
          const result = await response.json();
          if (result.error) {
            setIsConsoleError(true);
            setConsoleError(result.error);
          }
        }
      } catch (error) {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    };

    // Send Workplace to API
    const postWorkplace = async () => {
      try {
        if (workplace !== null) {
          const response = await fetch(`${API_DOMAIN}/work`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ userId, workPlace: workplace }),
          });
          const result = await response.json();
          if (result.error) {
            setIsConsoleError(true);
            setConsoleError(result.error);
          }
        }
      } catch (error) {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    };

    try {
      await Promise.all([
        postUsername(),
        postPhoto(),
        postBio(),
        postCountryCity(),
        postDesignation(),
        postWorkplace(),
        postTheme(),
      ]);
      window.location.href = `/@${username}`;
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  return (
    <>
      <div
        className="theme-container"
        style={{
          background: `linear-gradient(to bottom, ${selectedTheme.light}, ${selectedTheme.dark})`,
        }}
      >
        <div className="theme-wrapper">
          <div className="theme-header">
            <StepBar num={4} selectedTheme={selectedThemeImage} />
          </div>
          <div className="theme-body">
            <div className="theme-topic">
              Let's craft your Tinly experience together.
            </div>

            <div className="theme-sub-topic">
              <h>Set a Color Theme</h>
            </div>
            <div className="theme-select">
              <input
                type="radio"
                name="slider"
                id="t1"
                checked={selectedThemeImage === "t1"}
                onChange={() => handleImageClick("t1")}
                onClick={() =>
                  setSelectedTheme({
                    light: "#e3b0e3",
                    mid: "#d0637f",
                    dark: "#bc151b",
                  })
                }
                hidden
              />
              <input
                type="radio"
                name="slider"
                id="t2"
                checked={selectedThemeImage === "t2"}
                onChange={() => handleImageClick("t2")}
                onClick={() =>
                  setSelectedTheme({
                    light: "#0ddfff",
                    mid: "#1fd093",
                    dark: "#30c027",
                  })
                }
                hidden
              />
              <input
                type="radio"
                name="slider"
                id="t3"
                checked={selectedThemeImage === "t3"}
                onChange={() => handleImageClick("t3")}
                onClick={() =>
                  setSelectedTheme({
                    light: "#f0ccd2",
                    mid: "#8494cd",
                    dark: "#185cc8",
                  })
                }
                hidden
              />

              <div className="themes">
                <label htmlFor="t1" id="theme-red">
                  <div className="card">
                    <img src={t1} alt="" onClick={handleClick} />
                  </div>
                </label>
                <label htmlFor="t2" id="theme-green">
                  <div className="card">
                    <img src={t2} alt="" onClick={handleClick} />
                  </div>
                </label>
                <label htmlFor="t3" id="theme-blue">
                  <div className="card">
                    <img src={t3} alt="" onClick={handleClick} />
                  </div>
                </label>
              </div>
              <div className="theme-note">You can set more colors later.</div>
            </div>

            <div className="theme-button">
              <Link to="/demo">
                <button className="btn-prev" onClick={handleClick}>
                  {" "}
                  &lt; Previous{" "}
                </button>
              </Link>
              <button
                className={`btn-save ${
                  selectedThemeImage === "t1"
                    ? "btn-t1"
                    : selectedThemeImage === "t3"
                    ? "btn-t3"
                    : "btn-t2"
                }`}
                onClick={handleSubmit}
              >
                Save
              </button>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <ConsoleError
        isConsoleError={isConsoleError}
        consoleError={consoleError}
        closeConsoleError={setIsConsoleError}
      />
    </>
  );
};

export default SetTheme;

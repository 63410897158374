import React, { useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";
import ReactSwitch from "react-switch";
import CloseButton from "react-bootstrap/CloseButton";
import Whatsapp from "../../Assets/Whatsapp.png";
import Email from "../../Assets/Email.png";
import Phone from "../../Assets/Phone.png";
import Telegram from "../../Assets/Telegram.png";
import MailingAddress from "../../Assets/MailingAddress.png";
import { SiViber } from "react-icons/si";
import { GiRotaryPhone } from "react-icons/gi";
import { BsWechat } from "react-icons/bs";
import { IoMdAdd } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import { FaInfo } from "react-icons/fa6";
import API_DOMAIN from "../../ApiConfig";
import Cookies from "js-cookie";
import "react-phone-input-2/lib/style.css";
import "./PrivateLinks.css";
import UpgradePlans from "../UpgradePlans/UpgradePlans";
import ConsoleError from "../ConfirmationMessage/ConsoleError";

function PrivateLinks({ fetchPL, privateLinksAdded }) {
  const [phoneHome, setPhoneHome] = useState("");
  const [phoneMobile, setPhoneMobile] = useState("");
  const [mailingAddress, setMailingAddress] = useState("");
  const [email, setEmail] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [telegram, setTelegram] = useState("");
  const [viber, setViber] = useState("");
  const [weChat, setWeChat] = useState("");
  const [phoneHomePrivacyStatus, setPhoneHomePrivacyStatus] = useState("");
  const [phoneMobilePrivacyStatus, setPhoneMobilePrivacyStatus] = useState("");
  const [mailPrivacyStatus, setMailPrivacyStatus] = useState("");
  const [emailPrivacyStatus, setEmailPrivacyStatus] = useState("");
  const [whatsappPrivacyStatus, setWhatsappPrivacyStatus] = useState("");
  const [telegramPrivacyStatus, setTelegramPrivacyStatus] = useState("");
  const [viberPrivacyStatus, setViberPrivacyStatus] = useState("");
  const [weChatPrivacyStatus, setWeChatPrivacyStatus] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isPlAlert, setIsPlAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenUpgradeCanvas, setIsOpenUpgradeCanvas] = useState(false);
  const upgradeCanvasRef = useRef(null);
  const [phoneNumError, setPhoneNumError] = useState("");
  const [phoneNumMobileError, setPhoneNumMobileError] = useState("");
  const [whatsappError, setWhatsappError] = useState("");
  const [telegramError, setTelegramError] = useState("");
  const [viberError, setViberError] = useState("");
  const [consoleError, setConsoleError] = useState("");
  const [isConsoleError, setIsConsoleError] = useState(false);
  const [wechatError, setWechatError] = useState("");
  const [, setIsPLAdded] = useState(false);

  const userId = Cookies.get("userId");

  const preloadImages = (src) => {
    const img = new Image();
    img.src = src;
  };

  useEffect(() => {
    preloadImages(Phone);
    preloadImages(GiRotaryPhone);
    preloadImages(Email);
    preloadImages(MailingAddress);
    preloadImages(Whatsapp);
    preloadImages(Telegram);
    preloadImages(SiViber);
    preloadImages(BsWechat);
  });

  useEffect(() => {
    if (privateLinksAdded) {
      setIsPLAdded(true);
      privateLinksAdded.forEach((plink) => {
        const type = plink.type;
        const link = plink.link;
        const privacy = plink.privacy_status;
        const contactNo = plink.contactNo;
        switch (type) {
          case "Phone home":
            setPhoneHome(contactNo);
            handleToggle("phoneHome", true);
            setPhoneHomePrivacyStatus(privacy);
            break;
          case "Phone mobile":
            setPhoneMobile(contactNo);
            handleToggle("phoneMobile", true);
            setPhoneMobilePrivacyStatus(privacy);
            break;
          case "Mailing Address":
            setMailingAddress(link);
            handleToggle("address", true);
            setMailPrivacyStatus(privacy);
            break;
          case "Email Address":
            setEmail(link);
            handleToggle("email", true);
            setEmailPrivacyStatus(privacy);
            break;
          case "Whatsapp":
            setWhatsapp(contactNo);
            handleToggle("whatsapp", true);
            setWhatsappPrivacyStatus(privacy);
            break;
          case "Telegram":
            setTelegram(contactNo);
            handleToggle("telegram", true);
            setTelegramPrivacyStatus(privacy);
            break;
          case "Viber":
            setViber(contactNo);
            handleToggle("viber", true);
            setViberPrivacyStatus(privacy);
            break;
          case "WeChat":
            setWeChat(contactNo);
            handleToggle("weChat", true);
            setWeChatPrivacyStatus(privacy);
            break;
          default:
            break;
        }
      });
    } else {
      setIsPLAdded(false);
    }
  }, [privateLinksAdded]);

  const [checkToggle, setCheckToggle] = useState({
    phoneHome: false,
    phoneMobile: false,
    email: false,
    address: false,
    whatsapp: false,
    telegram: false,
    viber: false,
    weChat: false,
  });

  //button click loading animation
  const handleClickLoading = async () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);
  };

  const emailValidation = (e) => {
    const enteredEmail = e.target.value;
    setEmail(enteredEmail);
    const mail = enteredEmail.trim();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailRegex.test(mail)) {
      setEmailError("");
    } else {
      setEmailError("Enter a valid Email");
    }
    if (!enteredEmail) {
      setEmailError("");
    }
  };

  const validateNumber = (number) => {
    const numberRegex = /^\d{7,15}$/;
    return numberRegex.test(number.trim());
  };

  const handlePhoneNumber = (e) => {
    const enteredPhoneNum = e.target.value;
    setPhoneHome(enteredPhoneNum);

    if (validateNumber(enteredPhoneNum)) {
      setPhoneNumError("");
    } else {
      setPhoneNumError("Invalid phone number!");
    }
    if (!enteredPhoneNum) {
      setPhoneNumError("");
    }
  };

  const handlePhoneNumberMobile = (e) => {
    const enteredPhoneNum = e.target.value;
    setPhoneMobile(enteredPhoneNum);

    if (validateNumber(enteredPhoneNum)) {
      setPhoneNumMobileError("");
    } else {
      setPhoneNumMobileError("Invalid phone number!");
    }
    if (!enteredPhoneNum) {
      setPhoneNumMobileError("");
    }
  };

  const handleWhatsappNumber = (e) => {
    const enteredWhatsappNum = e.target.value;
    setWhatsapp(enteredWhatsappNum);

    if (validateNumber(enteredWhatsappNum)) {
      setWhatsappError("");
    } else {
      setWhatsappError("Invalid Whatsapp number!");
    }
    if (!enteredWhatsappNum) {
      setWhatsappError("");
    }
  };

  const handleTelegramNumber = (e) => {
    const enteredTelegramNum = e.target.value;
    setTelegram(enteredTelegramNum);

    if (validateNumber(enteredTelegramNum)) {
      setTelegramError("");
    } else {
      setTelegramError("Invalid Telegram number!");
    }
    if (!enteredTelegramNum) {
      setTelegramError("");
    }
  };

  const handleViberNumber = (e) => {
    const enteredViberNum = e.target.value;
    setViber(enteredViberNum);

    if (validateNumber(enteredViberNum)) {
      setViberError("");
    } else {
      setViberError("Invalid Viber number!");
    }
    if (!enteredViberNum) {
      setViberError("");
    }
  };

  const handleWechatNumber = (e) => {
    const enteredWechatNum = e.target.value;
    setWeChat(enteredWechatNum);

    if (validateNumber(enteredWechatNum)) {
      setWechatError("");
    } else {
      setWechatError("Invalid WeChat number!");
    }
    if (!enteredWechatNum) {
      setWechatError("");
    }
  };

  //post private links to API
  const postPrivateLink = async () => {
    try {
      const privateLinkData = [
        {
          privacyStatus: phoneHomePrivacyStatus,
          type: "Phone home",
          countryCode: "+94",
          link: phoneHome,
        },
        {
          privacyStatus: phoneMobilePrivacyStatus,
          type: "Phone mobile",
          countryCode: "+94",
          link: phoneMobile,
        },
        {
          privacyStatus: mailPrivacyStatus,
          type: "Mailing Address",
          link: mailingAddress,
        },
        {
          privacyStatus: emailPrivacyStatus,
          type: "Email Address",
          link: email,
        },
        {
          privacyStatus: whatsappPrivacyStatus,
          type: "Whatsapp",
          countryCode: "+94",
          link: whatsapp,
        },
        {
          privacyStatus: telegramPrivacyStatus,
          type: "Telegram",
          countryCode: "+94",
          link: telegram,
        },
        {
          privacyStatus: viberPrivacyStatus,
          type: "Viber",
          countryCode: "+94",
          link: viber,
        },
        {
          privacyStatus: weChatPrivacyStatus,
          type: "WeChat",
          countryCode: "+94",
          link: weChat,
        },
      ];

      const filledPrivateLinkData = privateLinkData.filter(
        (entry) => entry.link !== "" && entry.privacyStatus
      );

      await fetch(`${API_DOMAIN}/private/links/save/${userId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          privateLinks: filledPrivateLinkData,
        }),
      });
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  //update private links
  const updatePrivateLinks = async () => {
    try {
      const updatePrivateLinkData = [
        {
          privacyStatus: phoneHomePrivacyStatus,
          type: "Phone home",
          countryCode: "+94",
          link: phoneHome,
        },
        {
          privacyStatus: phoneMobilePrivacyStatus,
          type: "Phone mobile",
          countryCode: "+94",
          link: phoneMobile,
        },
        {
          privacyStatus: mailPrivacyStatus,
          type: "Mailing Address",
          countryCode: "",
          link: mailingAddress,
        },
        {
          privacyStatus: emailPrivacyStatus,
          type: "Email Address",
          countryCode: "",
          link: email,
        },
        {
          privacyStatus: whatsappPrivacyStatus,
          type: "Whatsapp",
          countryCode: "+94",
          link: whatsapp,
        },
        {
          privacyStatus: telegramPrivacyStatus,
          type: "Telegram",
          countryCode: "+94",
          link: telegram,
        },
        {
          privacyStatus: viberPrivacyStatus,
          type: "Viber",
          countryCode: "+94",
          link: viber,
        },
        {
          privacyStatus: weChatPrivacyStatus,
          type: "WeChat",
          countryCode: "+94",
          link: weChat,
        },
      ];

      const filledUpdatePrivateLinkData = updatePrivateLinkData.filter(
        (entry) => entry.link !== "" && entry.privacyStatus
      );

      await fetch(`${API_DOMAIN}/private/links/update/${userId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          updatedPrivateLinks: filledUpdatePrivateLinkData,
        }),
      });
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  //delete private links
  const deletePrivateLinks = async (type) => {
    try {
      await fetch(`${API_DOMAIN}/private/links/delete/${userId}/${type}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  const handleToggle = (type, val) => {
    // Count how many private links are currently toggled on
    const toggledLinksCount = Object.values(checkToggle).filter(
      (toggled) => toggled
    ).length;

    if (val && toggledLinksCount >= 2) {
      setIsPlAlert(true);
      return;
    }

    setCheckToggle((prevState) => ({
      ...prevState,
      [type]: val,
    }));

    // If the toggle is turned on, set the privacy status to "On-Request"
    if (val) {
      switch (type) {
        case "phoneHome":
          setPhoneHomePrivacyStatus("On-Request");
          break;
        case "phoneMobile":
          setPhoneMobilePrivacyStatus("On-Request");
          break;
        case "address":
          setMailPrivacyStatus("On-Request");
          break;
        case "email":
          setEmailPrivacyStatus("On-Request");
          break;
        case "whatsapp":
          setWhatsappPrivacyStatus("On-Request");
          break;
        case "telegram":
          setTelegramPrivacyStatus("On-Request");
          break;
        case "viber":
          setViberPrivacyStatus("On-Request");
          break;
        case "weChat":
          setWeChatPrivacyStatus("On-Request");
          break;
        default:
          break;
      }
    } else {
      // If the toggle is turned off, set the privacy status to an empty string
      switch (type) {
        case "phoneHome":
          setPhoneHomePrivacyStatus("");
          break;
        case "phoneMobile":
          setPhoneMobilePrivacyStatus("");
          break;
        case "address":
          setMailPrivacyStatus("");
          break;
        case "email":
          setEmailPrivacyStatus("");
          break;
        case "whatsapp":
          setWhatsappPrivacyStatus("");
          break;
        case "telegram":
          setTelegramPrivacyStatus("");
          break;
        case "viber":
          setViberPrivacyStatus("");
          break;
        case "weChat":
          setWeChatPrivacyStatus("");
          break;
        default:
          break;
      }
    }
  };

  const handleOnClick = async (close) => {
    try {
      if (
        checkToggle.phoneHome &&
        ((phoneHome && !phoneNumError) || !phoneHome)
      ) {
        await postPrivateLink();
        await updatePrivateLinks();
      }
      if (
        checkToggle.phoneMobile &&
        ((phoneMobile && !phoneNumError) || !phoneMobile)
      ) {
        await postPrivateLink();
        await updatePrivateLinks();
      }
      if (checkToggle.email && ((email && !emailError) || !email)) {
        await postPrivateLink();
        await updatePrivateLinks();
      }
      if (checkToggle.address) {
        await postPrivateLink();
        await updatePrivateLinks();
      }
      if (checkToggle.whatsapp && ((whatsapp && !whatsappError) || !whatsapp)) {
        await postPrivateLink();
        await updatePrivateLinks();
      }
      if (checkToggle.telegram && ((telegram && !telegramError) || !telegram)) {
        await postPrivateLink();
        await updatePrivateLinks();
      }
      if (checkToggle.viber && ((viber && !viberError) || !viber)) {
        await postPrivateLink();
        await updatePrivateLinks();
      }
      if (checkToggle.weChat && ((weChat && !wechatError) || !weChat)) {
        await postPrivateLink();
        await updatePrivateLinks();
      }

      // Delete private links based on their toggle status and privacy status
      if (!checkToggle.phoneHome) {
        await deletePrivateLinks("Phone home");
      }
      if (!checkToggle.phoneMobile) {
        await deletePrivateLinks("Phone mobile");
      }
      if (!checkToggle.email) {
        await deletePrivateLinks("Email Address");
      }
      if (!checkToggle.address) {
        await deletePrivateLinks("Mailing Address");
      }

      if (!checkToggle.whatsapp) {
        await deletePrivateLinks("Whatsapp");
      }
      if (!checkToggle.telegram) {
        await deletePrivateLinks("Telegram");
      }
      if (!checkToggle.viber) {
        await deletePrivateLinks("Viber");
      }
      if (!checkToggle.weChat) {
        await deletePrivateLinks("WeChat");
      }

      fetchPL();
      close();
    } catch (error) {
      setConsoleError(error.message);
    }
  };

  return (
    <>
      <div className="p-links-canvas">
        {/* <div className="pl-icons" onClick={() => setPlViewModal(!plViewModal)}>
        {isPLAdded
          ? privateLinks.map((link, plId) => (
              <div className="pl-icon" key={plId}>
                {link.plIcon}
              </div>
            ))
          : ""}
      </div> */}

        <Popup
          trigger={
            <button className="add-pl">
              <IoMdAdd className="add-pl-icon" />
            </button>
          }
          modal
          nested
        >
          {(close) => (
            <div className="pl-popup">
              <div className="pl-popup-header">
                <div className="pl-box">
                  <div className="pl-close">
                    <button className="wp-close-btn" onClick={() => close()}>
                      <CloseButton style={{ height: "5px", width: "5px" }} />
                    </button>
                  </div>
                  <div className="pl-title">
                    <h> Add Private Links</h>
                  </div>
                </div>
                <div className="selected-pl">
                  <div className="selected-pl-details">
                    <div className="select-pl-desc">
                      <div className="select-pl-icon">
                        <GiRotaryPhone className="select-pl-icon-img" />
                      </div>
                      <div className="select-pl-title">
                        {" "}
                        Phone Number (Home){" "}
                      </div>
                      <div className="toggle">
                        <span className="toggle-label">On</span>
                        <ReactSwitch
                          checked={checkToggle.phoneHome}
                          onChange={(val) => handleToggle("phoneHome", val)}
                          onColor="#000000"
                          offColor="#D7D7D7"
                          handleDiameter={12}
                          height={18}
                          width={32}
                          uncheckedIcon={false}
                          checkedIcon={false}
                        />
                        <span className="toggle-label">Off</span>
                      </div>
                    </div>

                    <div className="select-pl-text">
                      <div className="pl-input-canvas">
                        <input
                          type="text"
                          placeholder="Phone Number (Home)"
                          value={phoneHome}
                          onChange={handlePhoneNumber}
                          disabled={!checkToggle.phoneHome}
                          className="select-pl-input"
                        />
                        <div className="pl-info-icon">
                          <FaInfo />
                        </div>
                        <div className="pl-info-desc">
                          Should contain only digits, with a length between 7
                          and 15 characters
                        </div>
                      </div>
                      {phoneNumError && (
                        <div className="error-msg-name">{phoneNumError}</div>
                      )}
                    </div>
                    <div className="select-pl-option">
                      <div className="pl-radio">
                        <input
                          type="radio"
                          name="ph-select"
                          onClick={() => setPhoneHomePrivacyStatus("Public")}
                          checked={phoneHomePrivacyStatus === "Public"}
                          className="radio-btn"
                          disabled={!checkToggle.phoneHome}
                        />{" "}
                        Public
                      </div>
                      <div className="pl-radio">
                        <input
                          type="radio"
                          name="ph-select"
                          onClick={() =>
                            setPhoneHomePrivacyStatus("On-Request")
                          }
                          checked={phoneHomePrivacyStatus === "On-Request"}
                          className="radio-btn"
                          disabled={!checkToggle.phoneHome}
                        />{" "}
                        On Request
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="selected-pl-details">
                    <div className="select-pl-desc">
                      <div className="select-pl-icon">
                        <img src={Phone} alt="phone-icon" />
                      </div>
                      <div className="select-pl-title">
                        {" "}
                        Phone Number (Mobile){" "}
                      </div>
                      <div className="toggle">
                        <span className="toggle-label">On</span>
                        <ReactSwitch
                          checked={checkToggle.phoneMobile}
                          onChange={(val) => handleToggle("phoneMobile", val)}
                          onColor="#000000"
                          offColor="#D7D7D7"
                          handleDiameter={12}
                          height={18}
                          width={32}
                          uncheckedIcon={false}
                          checkedIcon={false}
                        />
                        <span className="toggle-label">Off</span>
                      </div>
                    </div>

                    <div className="select-pl-text">
                      <div className="pl-input-canvas">
                        <input
                          type="text"
                          placeholder="Phone Number (Mobile)"
                          value={phoneMobile}
                          onChange={handlePhoneNumberMobile}
                          disabled={!checkToggle.phoneMobile}
                          className="select-pl-input"
                        />
                        <div className="pl-info-icon">
                          <FaInfo />
                        </div>
                        <div className="pl-info-desc">
                          Should contain only digits, with a length between 7
                          and 15 characters
                        </div>
                      </div>
                      {phoneNumMobileError && (
                        <div className="error-msg-name">
                          {phoneNumMobileError}
                        </div>
                      )}
                    </div>
                    <div className="select-pl-option">
                      <div className="pl-radio">
                        <input
                          type="radio"
                          name="pm-select"
                          onClick={() => setPhoneMobilePrivacyStatus("Public")}
                          checked={phoneMobilePrivacyStatus === "Public"}
                          className="radio-btn"
                          disabled={!checkToggle.phoneMobile}
                        />
                        Public
                      </div>
                      <div className="pl-radio">
                        <input
                          type="radio"
                          name="pm-select"
                          onClick={() =>
                            setPhoneMobilePrivacyStatus("On-Request")
                          }
                          checked={phoneMobilePrivacyStatus === "On-Request"}
                          className="radio-btn"
                          disabled={!checkToggle.phoneMobile}
                        />
                        On Request
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="selected-pl-details">
                    <div className="select-pl-desc">
                      <div className="select-pl-icon">
                        <img src={Email} alt="email-icon" />
                      </div>
                      <div className="select-pl-title"> Email </div>
                      <div className="toggle">
                        <span className="toggle-label">On</span>
                        <ReactSwitch
                          checked={checkToggle.email}
                          onChange={(val) => handleToggle("email", val)}
                          onColor="#000000"
                          offColor="#D7D7D7"
                          handleDiameter={12}
                          height={18}
                          width={32}
                          uncheckedIcon={false}
                          checkedIcon={false}
                        />
                        <span className="toggle-label">Off</span>
                      </div>
                    </div>

                    <div className="select-pl-text">
                      <input
                        className="select-pl-input"
                        type="text"
                        placeholder="Email"
                        value={email}
                        onChange={emailValidation}
                        disabled={!checkToggle.email}
                      />

                      {emailError && (
                        <div className="error-msg-name">{emailError}</div>
                      )}
                    </div>
                    <div className="select-pl-option">
                      <div className="pl-radio">
                        <input
                          type="radio"
                          name="em-select"
                          onClick={() => setEmailPrivacyStatus("Public")}
                          className="radio-btn"
                          checked={emailPrivacyStatus === "Public"}
                          disabled={!checkToggle.email}
                        />{" "}
                        Public
                      </div>
                      <div className="pl-radio">
                        <input
                          type="radio"
                          name="em-select"
                          onClick={() => setEmailPrivacyStatus("On-Request")}
                          className="radio-btn"
                          checked={emailPrivacyStatus === "On-Request"}
                          disabled={!checkToggle.email}
                        />{" "}
                        On Request
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="selected-pl-details">
                    <div className="select-pl-desc">
                      <div className="select-pl-icon">
                        <img src={MailingAddress} alt="address-icon" />
                      </div>
                      <div className="select-pl-title"> Mailing Address </div>
                      <div className="toggle">
                        <span className="toggle-label">On</span>
                        <ReactSwitch
                          checked={checkToggle.address}
                          onChange={(val) => handleToggle("address", val)}
                          onColor="#000000"
                          offColor="#D7D7D7"
                          handleDiameter={12}
                          height={18}
                          width={32}
                          uncheckedIcon={false}
                          checkedIcon={false}
                        />
                        <span className="toggle-label">Off</span>
                      </div>
                    </div>
                    <div className="select-pl-text">
                      <input
                        className="select-pl-input"
                        type="text"
                        placeholder="Mailing Address"
                        value={mailingAddress}
                        onChange={(e) => setMailingAddress(e.target.value)}
                        disabled={!checkToggle.address}
                      />
                    </div>
                    <div className="select-pl-option">
                      <div className="pl-radio">
                        <input
                          type="radio"
                          value="Public"
                          name="ma-select"
                          onClick={() => setMailPrivacyStatus("Public")}
                          className="radio-btn"
                          checked={mailPrivacyStatus === "Public"}
                          disabled={!checkToggle.address}
                        />{" "}
                        Public
                      </div>
                      <div className="pl-radio">
                        <input
                          type="radio"
                          value="Public"
                          name="ma-select"
                          onClick={() => setMailPrivacyStatus("On-Request")}
                          className="radio-btn"
                          checked={mailPrivacyStatus === "On-Request"}
                          disabled={!checkToggle.address}
                        />{" "}
                        On Request
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="selected-pl-details">
                    <div className="select-pl-desc">
                      <div className="select-pl-icon">
                        <img src={Whatsapp} alt="whatsapp-icon" />
                      </div>
                      <div className="select-pl-title"> Whatsapp </div>
                      <div className="toggle">
                        <span className="toggle-label">On</span>
                        <ReactSwitch
                          checked={checkToggle.whatsapp}
                          onChange={(val) => handleToggle("whatsapp", val)}
                          onColor="#000000"
                          offColor="#D7D7D7"
                          handleDiameter={12}
                          height={18}
                          width={32}
                          uncheckedIcon={false}
                          checkedIcon={false}
                        />
                        <span className="toggle-label">Off</span>
                      </div>
                    </div>
                    <div className="select-pl-text">
                      <div className="pl-input-canvas">
                        <input
                          className="select-pl-input"
                          type="text"
                          placeholder="Whatsapp"
                          value={whatsapp}
                          onChange={handleWhatsappNumber}
                          disabled={!checkToggle.whatsapp}
                        />
                        <div className="pl-info-icon">
                          <FaInfo />
                        </div>
                        <div className="pl-info-desc">
                          Should contain only digits, with a length between 7
                          and 15 characters
                        </div>
                      </div>
                      {whatsappError && (
                        <div className="error-msg-name">{whatsappError}</div>
                      )}
                    </div>
                    <div className="select-pl-option">
                      <div className="pl-radio">
                        <input
                          type="radio"
                          value="Public"
                          name="wh-select"
                          onClick={() => setWhatsappPrivacyStatus("Public")}
                          className="radio-btn"
                          checked={whatsappPrivacyStatus === "Public"}
                          disabled={!checkToggle.whatsapp}
                        />{" "}
                        Public
                      </div>
                      <div className="pl-radio">
                        <input
                          type="radio"
                          value="Public"
                          name="wh-select"
                          onClick={() => setWhatsappPrivacyStatus("On-Request")}
                          className="radio-btn"
                          checked={whatsappPrivacyStatus === "On-Request"}
                          disabled={!checkToggle.whatsapp}
                        />{" "}
                        On Request
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="selected-pl-details">
                    <div className="select-pl-desc">
                      <div className="select-pl-icon">
                        <img src={Telegram} alt="" />
                      </div>
                      <div className="select-pl-title"> Telegram </div>
                      <div className="toggle">
                        <span className="toggle-label">On</span>
                        <ReactSwitch
                          checked={checkToggle.telegram}
                          onChange={(val) => handleToggle("telegram", val)}
                          onColor="#000000"
                          offColor="#D7D7D7"
                          handleDiameter={12}
                          height={18}
                          width={32}
                          uncheckedIcon={false}
                          checkedIcon={false}
                        />
                        <span className="toggle-label">Off</span>
                      </div>
                    </div>
                    <div className="select-pl-text">
                      <div className="pl-input-canvas">
                        <input
                          className="select-pl-input"
                          type="text"
                          placeholder="Telegram"
                          value={telegram}
                          onChange={handleTelegramNumber}
                          disabled={!checkToggle.telegram}
                        />
                        <div className="pl-info-icon">
                          <FaInfo />
                        </div>
                        <div className="pl-info-desc">
                          Should contain only digits, with a length between 7
                          and 15 characters
                        </div>
                      </div>
                      {telegramError && (
                        <div className="error-msg-name">{telegramError}</div>
                      )}
                    </div>
                    <div className="select-pl-option">
                      <div className="pl-radio">
                        <input
                          type="radio"
                          value="Public"
                          name="te-select"
                          onClick={() => setTelegramPrivacyStatus("Public")}
                          className="radio-btn"
                          checked={telegramPrivacyStatus === "Public"}
                          disabled={!checkToggle.telegram}
                        />{" "}
                        Public
                      </div>
                      <div className="pl-radio">
                        <input
                          type="radio"
                          value="Public"
                          name="te-select"
                          onClick={() => setTelegramPrivacyStatus("On-Request")}
                          className="radio-btn"
                          checked={telegramPrivacyStatus === "On-Request"}
                          disabled={!checkToggle.telegram}
                        />{" "}
                        On Request
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="selected-pl-details">
                    <div className="select-pl-desc">
                      <div className="select-pl-icon">
                        <SiViber className="select-pl-icon-img" />
                      </div>
                      <div className="select-pl-title"> Viber </div>
                      <div className="toggle">
                        <span className="toggle-label">On</span>
                        <ReactSwitch
                          checked={checkToggle.viber}
                          onChange={(val) => handleToggle("viber", val)}
                          onColor="#000000"
                          offColor="#D7D7D7"
                          handleDiameter={12}
                          height={18}
                          width={32}
                          uncheckedIcon={false}
                          checkedIcon={false}
                        />
                        <span className="toggle-label">Off</span>
                      </div>
                    </div>
                    <div className="select-pl-text">
                      <div className="pl-input-canvas">
                        <input
                          className="select-pl-input"
                          type="text"
                          placeholder="Viber"
                          value={viber}
                          onChange={handleViberNumber}
                          disabled={!checkToggle.viber}
                        />
                        <div className="pl-info-icon">
                          <FaInfo />
                        </div>
                        <div className="pl-info-desc">
                          Should contain only digits, with a length between 7
                          and 15 characters
                        </div>
                      </div>
                      {viberError && (
                        <div className="error-msg-name">{viberError}</div>
                      )}
                    </div>
                    <div className="select-pl-option">
                      <div className="pl-radio">
                        <input
                          type="radio"
                          value="Public"
                          name="vi-select"
                          onClick={() => setViberPrivacyStatus("Public")}
                          className="radio-btn"
                          checked={viberPrivacyStatus === "Public"}
                          disabled={!checkToggle.viber}
                        />{" "}
                        Public
                      </div>
                      <div className="pl-radio">
                        <input
                          type="radio"
                          value="Public"
                          name="vi-select"
                          onClick={() => setViberPrivacyStatus("On-Request")}
                          className="radio-btn"
                          checked={viberPrivacyStatus === "On-Request"}
                          disabled={!checkToggle.viber}
                        />{" "}
                        On Request
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="selected-pl-details">
                    <div className="select-pl-desc">
                      <div className="select-pl-icon">
                        <BsWechat className="select-pl-icon-img" />
                      </div>
                      <div className="select-pl-title"> WeChat </div>
                      <div className="toggle">
                        <span className="toggle-label">On</span>
                        <ReactSwitch
                          checked={checkToggle.weChat}
                          onChange={(val) => handleToggle("weChat", val)}
                          onColor="#000000"
                          offColor="#D7D7D7"
                          handleDiameter={12}
                          height={18}
                          width={32}
                          uncheckedIcon={false}
                          checkedIcon={false}
                        />
                        <span className="toggle-label">Off</span>
                      </div>
                    </div>
                    <div className="select-pl-text">
                      <div className="pl-input-canvas">
                        <input
                          className="select-pl-input"
                          type="text"
                          placeholder="WeChat"
                          value={weChat}
                          onChange={handleWechatNumber}
                          disabled={!checkToggle.weChat}
                        />
                        <div className="pl-info-icon">
                          <FaInfo />
                        </div>
                        <div className="pl-info-desc">
                          Should contain only digits, with a length between 7
                          and 15 characters
                        </div>
                      </div>
                      {wechatError && (
                        <div className="error-msg-name">{wechatError}</div>
                      )}
                    </div>
                    <div className="select-pl-option">
                      <div className="pl-radio">
                        <input
                          type="radio"
                          value="Public"
                          name="wc-select"
                          onClick={() => setWeChatPrivacyStatus("Public")}
                          className="radio-btn"
                          checked={weChatPrivacyStatus === "Public"}
                          disabled={!checkToggle.weChat}
                        />{" "}
                        Public
                      </div>
                      <div className="pl-on-request">
                        <input
                          type="radio"
                          value="Public"
                          name="wc-select"
                          onClick={() => setWeChatPrivacyStatus("On-Request")}
                          className="radio-btn"
                          checked={weChatPrivacyStatus === "On-Request"}
                          disabled={!checkToggle.weChat}
                        />{" "}
                        On Request
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="pl-popup-footer">
                <button
                  className="pl-add-btn"
                  onClick={() => {
                    handleClickLoading();
                    handleOnClick(close);
                  }}
                  disabled={isLoading}
                >
                  {isLoading ? <div className="spinner"></div> : "Update"}
                </button>
              </div>

              {isPlAlert && (
                <div className="pl-toggle-alert">
                  <div
                    className="plt-alert-close"
                    onClick={() => {
                      setIsPlAlert(false);
                    }}
                  >
                    <RxCross2 />
                  </div>
                  <div className="plt-alert-text">
                    You can only toggle on two private links at a time. Please
                    toggle off one before toggling on another.
                  </div>
                  <div className="plt-upgrade-alert">
                    Upgrade to the premium version to add more Private Links.
                  </div>

                  <div
                    className="plt-upgrade-button"
                    onClick={() => {
                      setIsOpenUpgradeCanvas(true);
                      setIsPlAlert(false);
                    }}
                  >
                    Upgrade
                  </div>
                </div>
              )}

              {isOpenUpgradeCanvas && (
                <div className="upgrade-popup" ref={upgradeCanvasRef}>
                  <UpgradePlans closeUpgradeModal={setIsOpenUpgradeCanvas} />
                </div>
              )}
            </div>
          )}
        </Popup>
      </div>
      <ConsoleError
        isConsoleError={isConsoleError}
        consoleError={consoleError}
        closeConsoleError={setIsConsoleError}
      />
    </>
  );
}

export default PrivateLinks;

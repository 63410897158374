import React, { useEffect, useState, useRef } from "react";
import Popup from "reactjs-popup";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import CloseButton from "react-bootstrap/CloseButton";
import Sites from "../../Assets/Sites.svg";
import { RxCross2 } from "react-icons/rx";
import API_DOMAIN from "../../ApiConfig";
import ConsoleError from "../ConfirmationMessage/ConsoleError";
import UpgradePlans from "../UpgradePlans/UpgradePlans";
import ConfirmationMessage from "../ConfirmationMessage/ConfirmationMessage";
import "./AddSites.css";

function AddSites({ fetchSites }) {
  const [siteLinkError, setSiteLinkError] = useState("");
  const [isValidSiteLink, setIsValidSiteLink] = useState(false);
  const [siteLink, setSiteLink] = useState("");
  const [siteTitle, setSiteTitle] = useState("");
  const [siteTitleError, setSiteTitleError] = useState("");
  const [sitesCount, setSitesCount] = useState("");
  const [consoleError, setConsoleError] = useState("");
  const [isConsoleError, setIsConsoleError] = useState(false);
  const [isSiteAdded, setIsSiteAdded] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isCircleUpgradeCanvas, setIsCircleUpgradeCanvas] = useState(false);

  const circleUpgradeRef = useRef(null);

  const userId = Cookies.get("userId");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        circleUpgradeRef.current &&
        !circleUpgradeRef.current.contains(event.target)
      ) {
        setIsCircleUpgradeCanvas(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [circleUpgradeRef]);

  const fetchSitesCount = async () => {
    const response = await fetch(`${API_DOMAIN}/site/view?userId=${userId}`);
    const result = await response.json();
    if (result.success) {
      const sites = jwtDecode(result.token);
      const siteNumber = sites.url;
      setSitesCount(siteNumber.length);
    }
  };

  useEffect(() => {
    fetchSitesCount();

    const interval = setInterval(() => {
      fetchSitesCount();
    }, 5000);

    return () => clearInterval(interval);
  }, [userId]);

  const handleSiteLinks = (e) => {
    const enteredSiteLink = e.target.value;
    setSiteLink(enteredSiteLink);
    const urlCheck = /^(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
    if (enteredSiteLink.trim() !== " ") {
      setIsValidSiteLink(urlCheck.test(enteredSiteLink));
      setSiteLinkError("Enter a valid domain name");
    } else {
      setIsValidSiteLink(urlCheck.test(enteredSiteLink));
      setSiteLinkError("");
    }
  };

  const handleSiteTitle = (e) => {
    const enteredSiteTitle = e.target.value;
    const titleOfSite = enteredSiteTitle.trim();
    setSiteTitle(enteredSiteTitle);
    if (titleOfSite.length > 20) {
      setSiteTitleError("It should contain less than 20 characters.");
    } else {
      setSiteTitleError("");
    }
  };

  // Post Sites
  const postSiteLink = async () => {
    try {
      if (!siteLink.trim() && !siteTitle.trim()) {
        setSiteLinkError("Site Link is required");
        setSiteTitleError("Site Title is required");
        return { isSiteError: false };
      } else if (!siteLink.trim()) {
        setSiteLinkError("Site Link is required");
      } else if (!siteTitle.trim()) {
        setSiteTitleError("Site Title is required");
      } else {
        const response = await fetch(`${API_DOMAIN}/site`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: userId,
            url: siteLink,
            title: siteTitle,
          }),
        });
        const result = await response.json();
        if (result.success) {
          fetchSites();
          fetchSitesCount();
          return { isSiteError: true };
        } else {
          const isNotFound = result.code === 404;
          if (isNotFound) {
            setIsConsoleError(true);
            setConsoleError(result.error);
            return { isSiteError: false };
          }
        }
      }
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  const handleClick = () => {
    setSiteLink("");
    setSiteTitle("");
    setSiteLinkError("");
    setSiteTitleError("");
  };

  const handleAddSite = async (close) => {
    const result = await postSiteLink();
    if (result && result.isSiteError) {
      close();
      setIsSiteAdded(true);
      setIsVisible(true);
    }
  };

  return (
    <>
      <Popup
        trigger={
          <div className="add-sites">
            <button className="add-sites-icon" onClick={handleClick}>
              <img src={Sites} alt="Sites" className="add-sites-icon" />
            </button>
          </div>
        }
        modal
        nested
      >
        {(close) => (
          <>
            {sitesCount > 0 ? (
              <div className="site-toggle-alert">
                <div className="site-alert-close" onClick={() => close()}>
                  <RxCross2 />
                </div>
                <div className="site-alert-text">
                  You can only add one Site in your current plan.
                </div>
                <div className="site-upgrade-alert">
                  Upgrade to the premium version to add more Sites.
                </div>

                <div
                  className="site-upgrade-button"
                  onClick={() => setIsCircleUpgradeCanvas(true)}
                >
                  Upgrade
                </div>
                {isCircleUpgradeCanvas && (
                  <div ref={circleUpgradeRef}>
                    <UpgradePlans
                      closeUpgradeModal={setIsCircleUpgradeCanvas}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className="add-sites-popup">
                <div className="add-sites-header">
                  <div className="add-sites-close">
                    <button
                      className="add-sites-close-btn"
                      onClick={() => close()}
                    >
                      <CloseButton style={{ height: "5px", width: "5px" }} />
                    </button>
                  </div>
                  <div className="add-sites-title">
                    <h> Quick Strip </h>
                  </div>
                </div>
                <div className="add-sites-body">
                  <div className="add-sites-input">
                    <div className="add-sites-input">
                      <div className="asi-input-required">
                        <div className="asi-title"> Site Title </div>
                        <div className="required-sites">*</div>
                      </div>
                      <div className="sites-input-canvas">
                        <input
                          type="text"
                          placeholder="Site Title"
                          className="add-site-text"
                          value={siteTitle}
                          onChange={handleSiteTitle}
                        />
                      </div>
                      {siteTitleError && (
                        <div className="error-msg-site-link">
                          {siteTitleError}
                        </div>
                      )}
                    </div>
                    <div className="asi-input-required">
                      <div className="asi-title"> Site URL </div>
                      <div className="required-sites">*</div>
                    </div>

                    <div className="sites-input-canvas">
                      <input
                        type="text"
                        placeholder="Site URL"
                        className="add-site-text"
                        value={siteLink}
                        onChange={handleSiteLinks}
                      />
                    </div>
                    {isValidSiteLink ? (
                      ""
                    ) : (
                      <div className="error-msg-site-link">{siteLinkError}</div>
                    )}
                  </div>
                </div>
                <div className="add-site-btn">
                  <button
                    className="site-add"
                    onClick={() => handleAddSite(close)}
                  >
                    Add
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </Popup>
      <ConsoleError
        isConsoleError={isConsoleError}
        consoleError={consoleError}
        closeConsoleError={setIsConsoleError}
      />
      <ConfirmationMessage
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        isSiteAdded={isSiteAdded}
      />
    </>
  );
}

export default AddSites;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AiFillHeart } from "react-icons/ai";
import ProfileImage from "../../Components/ProfileImage/ProfileImage";
import StepBar from "../../Components/StepBar/StepBar";
import "./SetBio.css";

export default function SetBio() {
  const [bio, setBio] = useState("");
  const [image, setImage] = useState("");
  const [charCount, setCharCount] = useState(0);
  const [, setIsImageAdded] = useState(false);

  useEffect(() => {
    const savedBio = sessionStorage.getItem("bio");
    const savedImage = sessionStorage.getItem("image");

    if (savedBio) setBio(savedBio);
    if (savedImage) setImage(savedImage);

    window.addEventListener("beforeunload", clearSessionStorage);

    return () => {
      window.removeEventListener("beforeunload", clearSessionStorage);
    };
  }, []);

  const handleChange = (e) => {
    const { value } = e.target;
    setBio(value);
    setCharCount(value.length);
    setImage(value);
  };

  const handleImageChange = (selectedImage) => {
    setImage(selectedImage);
    setIsImageAdded(true);
  };

  const handleClick = () => {
    if (bio) {
      sessionStorage.setItem("bio", bio);
    } else {
      sessionStorage.removeItem("bio");
    }

    if (image) {
      sessionStorage.setItem("image", image);
    } else {
      sessionStorage.removeItem("image");
    }
  };

  const clearSessionStorage = () => {
    sessionStorage.removeItem("bio");
    sessionStorage.removeItem("image");
  };

  return (
    <div className="bio-container">
      <div className="bio-wrapper">
        <div className="bio-header">
          <StepBar num={2} />
        </div>
        <div className="bio-desc-body">
          <div className="bb-wrapper">
            <div className="bb-topic">
              Let's craft your Tinly experience together.
            </div>
            <div className="bb-sub-topic">
              <h>Add Your Profile Photo and Bio</h>
            </div>
            <div className="add-photo">
              <ProfileImage
                onImageChange={handleImageChange}
                value={image}
                userImage={setImage}
                userPhotoAdded={setIsImageAdded}
              />
            </div>
            <div className="add-bio">
              <textarea
                className="bio-text"
                placeholder="Bio Description"
                maxLength={150}
                value={bio}
                onChange={(e) => {
                  setBio(e.target.value);
                  handleChange(e);
                }}
              />
              <div className="char">{charCount}/150</div>
            </div>
            <div className="bb-button">
              <Link to="/uname">
                <button onClick={handleClick}> &lt; Previous </button>
              </Link>
              <Link to="/demo">
                <button onClick={handleClick}> Next &gt; </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="bb-footer">
          <p>
            Made &nbsp;
            <AiFillHeart className="heart-icon" /> &nbsp; with Tinly
          </p>
        </div>
      </div>
    </div>
  );
}

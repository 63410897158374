import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Image from "react-bootstrap/Image";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import { AiFillHeart } from "react-icons/ai";
import TinlyLogo from "../../Assets/TinlyLogo.png";
import API_DOMAIN from "../../ApiConfig";
import ConsoleError from "../../Components/ConfirmationMessage/ConsoleError";
import "./GetStarted.css";

export default function GetStarted({ userName }) {
  const [consoleError, setConsoleError] = useState("");
  const [isConsoleError, setIsConsoleError] = useState(false);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const response = await fetch(`${API_DOMAIN}/login/detail/${userName}`);
        const result = await response.json();
        const token = result.token;
        const userId = result.userId;

        Cookies.set("token", token);
        Cookies.set("userId", userId);
      } catch (error) {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    };
    fetchToken();
  }, [userName]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_DOMAIN}/login/detail/${userName}`);
        const result = await response.json();
        if (result.success) {
          const { firstName, lastName, username, email } = jwtDecode(
            result.token
          );
          sessionStorage.setItem("firstName", firstName);
          sessionStorage.setItem("lastName", lastName);
          sessionStorage.setItem("userName", username);
          sessionStorage.setItem("email", email);
        }
      } catch (error) {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    };
    fetchData();
  }, [userName]);

  return (
    <>
      <div className="gs-container">
        <div className="gs-wrapper">
          <div className="gs-header">
            <div className="gs-tinly-logo">
              <Image src={TinlyLogo} alt="" className="gs-logo" />
            </div>
            <div className="gs-title">
              <h> Tinly </h>
            </div>
          </div>
          <div className="gs-body">
            <div className="gs-body-box">
              <div className="gs-welcome">
                <h> Welcome Back!</h>
              </div>
              <div className="gs-note">
                <p>
                  You're officially in. Let's make every click count! Start
                  exploring now.
                </p>
              </div>
              <div className="gs-btn-box">
                <Link to="/uname">
                  <button className="gs-btn"> Get Started </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="gs-footer">
            <p>
              Made &nbsp;
              <AiFillHeart className="heart-icon" /> &nbsp; with Tinly
            </p>
          </div>
        </div>
      </div>
      <ConsoleError
        isConsoleError={isConsoleError}
        consoleError={consoleError}
        closeConsoleError={setIsConsoleError}
      />
    </>
  );
}

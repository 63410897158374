import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { IoMdClose } from "react-icons/io";
import { FaGlobeAmericas, FaLock, FaUserFriends } from "react-icons/fa";
import { CiLock } from "react-icons/ci";
import {
  MdOutlineKeyboardArrowRight,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";
import API_DOMAIN from "../../ApiConfig";
import { IoCheckmark } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import "./Settings.css";
import { jwtDecode } from "jwt-decode";
import ConfirmationMessage from "../ConfirmationMessage/ConfirmationMessage";
import ConsoleError from "../ConfirmationMessage/ConsoleError";

export default function Settings({
  closeModalSettings,
  fetchTheme,
  fetchPrivacy,
  fetchThemeUpdated,
  fetchPrivacyUpdated,
  fetchFirstName,
  fetchLastName,
  fetchUsername,
  fetchEmail,
  fetchUserDetailsUpdated,
  fetchFirstNameUpdated,
  fetchTemplate,
  fetchTemplateUpdated,
  fetchCircleTemplate,
  fetchCircleTemplateUpdated,
  fetchCircleData,
}) {
  const [privacyChecked, setPrivacyChecked] = useState("");
  const [themeSelected, setThemeSelected] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [template, setTemplate] = useState("");
  const [allUsernames, setAllUsernames] = useState([]);
  const [, setIsUsernameAvailable] = useState(false);
  const [isThemeCanvas, setIsThemeCanvas] = useState(false);
  const [isColorThemeCanvas, setIsColorThemeCanvas] = useState(false);
  const [isTemplateCanvas, setIsTemplateCanvas] = useState(false);
  const [isAccountCanvas, setIsAccountCanvas] = useState(false);
  const [isAdvanceAcc, setIsAdvanceAcc] = useState(false);
  const [isGeneralAcc, setIsGeneralAcc] = useState(false);
  const [isInsightsCanvas, setIsInsightsCanvas] = useState(false);
  const [isUnlockColors, setIsUnlockColors] = useState(false);
  const [isPrivacyCanvas, setIsPrivacyCanvas] = useState(false);
  const [isPlansCanvas, setIsPlansCanvas] = useState(false);
  const [isConfirmDeleteProfile, setIsConfirmDeleteProfile] = useState(false);
  const [isProfileDeleted, setIsProfileDeleted] = useState(false);
  const [nameChangeCount, setNameChangeCount] = useState("");
  const [isChangeCountExceed, setIsChangeCountExceed] = useState(false);
  const [isFirstNameChanged, setIsFirstNameChanged] = useState(false);
  const [isLastNameChanged, setIsLastNameChanged] = useState(false);
  const [isUsernameChanged, setIsUsernameChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [lightTheme, setLightTheme] = useState("");
  const [isSaveChanges, setIsSaveChanges] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [circleTemplateColor, setCircleTemplateColor] = useState("#fffff");
  const [deleteProfileError, setDeleteProfileError] = useState("");
  const [usernameInput, setUsernameInput] = useState("");
  const [consoleError, setConsoleError] = useState("");
  const [isConsoleError, setIsConsoleError] = useState(false);

  const userId = Cookies.get("userId");

  useEffect(() => {
    setThemeSelected(fetchTheme);
    setPrivacyChecked(fetchPrivacy);
    setFirstName(fetchFirstName);
    setLastName(fetchLastName);
    setUsername(fetchUsername);
    setEmail(fetchEmail);
    setTemplate(fetchTemplate);
    setLightTheme(fetchTheme[0]);
    setCircleTemplateColor(fetchCircleTemplate);
  }, [
    fetchTheme,
    fetchPrivacy,
    fetchFirstName,
    fetchLastName,
    fetchUsername,
    fetchEmail,
    fetchTemplate,
    fetchCircleTemplate,
  ]);

  const colors = [
    {
      id: { light: "#e3b0e3", mid: "#d0637f", dark: "#bc151b" },
      gradient: "linear-gradient(#e3b0e3, #bc151b)",
      outline: "#d0637f",
    },
    {
      id: { light: "#0ddfff", mid: "#1fd093", dark: "#30c027" },
      gradient: "linear-gradient(#0ddfff, #30c027)",
      outline: "#30c027",
    },
    {
      id: { light: "#f0ccd2", mid: "#8494cd", dark: "#185cc8" },
      gradient: "linear-gradient(#f0ccd2, #185cc8)",
      outline: "#185cc8",
    },

    {
      id: { light: "#FB8CFF", mid: "#8494cd", dark: "#840559" },
      gradient: "linear-gradient(#FB8CFF, #840559)",
      outline: "rgba(24, 91, 200, 0.759)",
    },
    {
      id: { light: "#B8C4FF", mid: "#8494cd", dark: "#051584" },
      gradient: "linear-gradient(#B8C4FF, #051584)",
      outline: "rgba(24, 91, 200, 0.759)",
    },
    {
      id: { light: "#01C0B0", mid: "#8494cd", dark: "#B11D59" },
      gradient: "linear-gradient(#01C0B0, #B11D59)",
      outline: "rgba(24, 91, 200, 0.759)",
    },
    {
      id: { light: "#FAAA3C", mid: "#8494cd", dark: "#A1003B" },
      gradient: "linear-gradient(#FAAA3C, #A1003B)",
      outline: "rgba(24, 91, 200, 0.759)",
    },
    {
      id: { light: "#ABE069", mid: "#8494cd", dark: "#59AD31" },
      gradient: "linear-gradient(#ABE069, #59AD31)",
      outline: "rgba(24, 91, 200, 0.759)",
    },
    {
      id: { light: "#FDE1B6", mid: "#8494cd", dark: "#ED4465" },
      gradient: "linear-gradient(#FDE1B6, #ED4465)",
      outline: "rgba(24, 91, 200, 0.759)",
    },
    {
      id: { light: "#6CD4EC", mid: "#8494cd", dark: "#1A6B80" },
      gradient: "linear-gradient(#6CD4EC, #1A6B80)",
      outline: "rgba(24, 91, 200, 0.759)",
    },
    {
      id: { light: "#F77F68", mid: "#8494cd", dark: "#DD2574" },
      gradient: "linear-gradient(#F77F68, #DD2574)",
      outline: "rgba(24, 91, 200, 0.759)",
    },
    {
      id: { light: "#41CAA2", mid: "#8494cd", dark: "#195E9D" },
      gradient: "linear-gradient(#41CAA2, #195E9D)",
      outline: "rgba(24, 91, 200, 0.759)",
    },
    {
      id: { light: "#00FFFF", mid: "#8494cd", dark: "#031844" },
      gradient: "linear-gradient(#00FFFF, #031844)",
      outline: "rgba(24, 91, 200, 0.759)",
    },
    {
      id: { light: "#E992FF", mid: "#8494cd", dark: "#4968DA" },
      gradient: "linear-gradient(#E992FF, #4968DA)",
      outline: "rgba(24, 91, 200, 0.759)",
    },
    {
      id: { light: "#FFC1A1", mid: "#8494cd", dark: "#A89EA0" },
      gradient: "linear-gradient(#FFC1A1, #A89EA0)",
      outline: "rgba(24, 91, 200, 0.759)",
    },
    {
      id: { light: "#F6BDF4", mid: "#8494cd", dark: "#D84CD6" },
      gradient: "linear-gradient(#F6BDF4, #D84CD6)",
      outline: "rgba(24, 91, 200, 0.759)",
    },
    {
      id: { light: "#FFEAB8", mid: "#8494cd", dark: "#844E05" },
      gradient: "linear-gradient(#FFEAB8, #844E05)",
      outline: "rgba(24, 91, 200, 0.759)",
    },
    {
      id: { light: "#F716D4", mid: "#8494cd", dark: "#F6695F" },
      gradient: "linear-gradient(#F716D4, #F6695F)",
      outline: "rgba(24, 91, 200, 0.759)",
    },
    {
      id: { light: "#1BFBFD", mid: "#8494cd", dark: "#2E3293" },
      gradient: "linear-gradient(#1BFBFD, #2E3293)",
      outline: "rgba(24, 91, 200, 0.759)",
    },
    {
      id: { light: "#FF5D7F", mid: "#8494cd", dark: "#FF1900" },
      gradient: "linear-gradient(#FF5D7F, #FF1900)",
      outline: "rgba(24, 91, 200, 0.759)",
    },
    {
      id: { light: "#FFE45C", mid: "#8494cd", dark: "#FF1A02" },
      gradient: "linear-gradient(#FFE45C, #FF1A02)",
      outline: "rgba(24, 91, 200, 0.759)",
    },
    {
      id: { light: "#FF4EDE", mid: "#8494cd", dark: "#5D00FF" },
      gradient: "linear-gradient(#FF4EDE, #5D00FF)",
      outline: "rgba(24, 91, 200, 0.759)",
    },
    {
      id: { light: "#F6FFD1", mid: "#8494cd", dark: "#33A167" },
      gradient: "linear-gradient(#F6FFD1, #33A167)",
      outline: "rgba(24, 91, 200, 0.759)",
    },
    {
      id: { light: "#8DF5FB", mid: "#8494cd", dark: "#E447DC" },
      gradient: "linear-gradient(#8DF5FB, #E447DC)",
      outline: "rgba(24, 91, 200, 0.759)",
    },
    {
      id: { light: "#FFE45C", mid: "#8494cd", dark: "#8F5306" },
      gradient: "linear-gradient(#FFE45C, #8F5306)",
      outline: "rgba(24, 91, 200, 0.759)",
    },
    {
      id: { light: "#E8E623", mid: "#8494cd", dark: "#129742" },
      gradient: "linear-gradient(#E8E623, #129742)",
      outline: "rgba(24, 91, 200, 0.759)",
    },
    {
      id: { light: "#F857A4", mid: "#8494cd", dark: "#FE575A" },
      gradient: "linear-gradient(#F857A4, #FE575A)",
      outline: "rgba(24, 91, 200, 0.759)",
    },
    {
      id: { light: "#D9D4A3", mid: "#8494cd", dark: "#689375" },
      gradient: "linear-gradient(#D9D4A3, #689375)",
      outline: "rgba(24, 91, 200, 0.759)",
    },
    {
      id: { light: "#DED4EF", mid: "#8494cd", dark: "#F8AEAB" },
      gradient: "linear-gradient(#DED4EF, #F8AEAB)",
      outline: "rgba(24, 91, 200, 0.759)",
    },
    {
      id: { light: "#FDDA8E", mid: "#8494cd", dark: "#BF2B1F" },
      gradient: "linear-gradient(#FDDA8E, #BF2B1F)",
      outline: "rgba(24, 91, 200, 0.759)",
    },
    {
      id: { light: "#19A878", mid: "#8494cd", dark: "#B91A15" },
      gradient: "linear-gradient(#19A878, #B91A15)",
      outline: "rgba(24, 91, 200, 0.759)",
    },
  ];

  //profile delete validation
  const handleRetypeUsername = (e) => {
    const enteredUsername = e.target.value;
    if (enteredUsername === "") {
      setDeleteProfileError("");
    }
    setUsernameInput(enteredUsername);
  };

  const handleColorClick = (color, index) => {
    if (index < 3) {
      setThemeSelected(color.id);
      setLightTheme(null);
    } else {
      setIsUnlockColors(true);
    }
  };

  //button click loading animation
  const handleClickLoading = async () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  };

  const closeModalAfterDelay = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        closeModalSettings(false);
        resolve();
      }, 3000);
      setIsSaveChanges(true);
      setIsVisible(true);
    });
  };

  // Prevent closing the modal when clicked inside
  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  const handlePrivacyChange = (option) => {
    setPrivacyChecked(option);
  };

  //First name validation
  const handleFirstName = (e) => {
    const enteredFirstName = e.target.value;
    const first = enteredFirstName.trim();
    setFirstName(enteredFirstName);
    setIsFirstNameChanged(true);
    if (first.length < 3) {
      setFirstNameError("First Name should include at least 3 characters");
    } else {
      setFirstNameError("");
    }
  };

  //last name validation
  const handleLastName = (e) => {
    const enteredLastName = e.target.value;
    const last = enteredLastName.trim();
    setLastName(enteredLastName);
    setIsLastNameChanged(true);
    if (last.length < 3) {
      setLastNameError("Last Name should include at least 3 characters");
    } else {
      setLastNameError("");
    }
  };

  //username validation
  const handleUsername = (e) => {
    const enteredUsername = e.target.value;
    const user = enteredUsername.trim();
    setUsername(enteredUsername);
    setIsUsernameChanged(true);
    const userNamePattern = /^[a-z0-9]{4,16}$/;
    if (!userNamePattern.test(user)) {
      setUsernameError(
        "Username should be 4-16 characters long and contain only lowercase alphanumerical characters."
      );
    } else {
      const isAvailable = !allUsernames.includes(user);
      setIsUsernameAvailable(isAvailable);
      if (!isAvailable) {
        setUsernameError("Username  already exists");
      } else {
        setUsernameError("");
      }
    }
  };

  //user name real time validation
  useEffect(() => {
    const checkUsername = async () => {
      try {
        const response = await fetch(`${API_DOMAIN}/user/get/usernames/all`);
        const result = await response.json();
        if (result.success) {
          const availableUsernames = result.usernames;
          setAllUsernames(availableUsernames);
        }
      } catch (error) {
        if (error.message === "Failed to fetch") {
          setIsConsoleError(true);
          setConsoleError("Unable to connect. Please check back shortly!");
        } else {
          setIsConsoleError(true);
          setConsoleError(error.message);
        }
      }
    };
    checkUsername();
  }, []);

  //fetch user name update count
  useEffect(() => {
    const fetchUpdateCount = async () => {
      try {
        const response = await fetch(
          `${API_DOMAIN}/user/created/view?userId=${userId}`
        );
        const result = await response.json();
        if (result.success) {
          const { userNameChangeCount } = jwtDecode(result.token);
          setNameChangeCount(userNameChangeCount);
        }
      } catch (error) {
        if (error.message === "Failed to fetch") {
          setIsConsoleError(true);
          setConsoleError("Unable to connect. Please check back shortly!");
        } else {
          setIsConsoleError(true);
          setConsoleError(error.message);
        }
      }
    };
    fetchUpdateCount();
  }, [userId]);

  //Update user details
  const updateUserDetails = async () => {
    try {
      if (isFirstNameChanged || isLastNameChanged || isUsernameChanged) {
        if (nameChangeCount >= 3) {
          setIsChangeCountExceed(true);
          setTimeout(() => setIsChangeCountExceed(false), 3000);
          setTimeout(() => closeModalSettings(false), 3000);
          return { isSuccess: false };
        }
      }

      const res = await fetch(`${API_DOMAIN}/login/update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId,
          firstName,
          lastName,
          username,
        }),
      });
      if (res.ok) {
        if (isFirstNameChanged || isLastNameChanged || isUsernameChanged) {
          if (nameChangeCount < 3) {
            fetchFirstNameUpdated();
            fetchUserDetailsUpdated();
            await closeModalAfterDelay();
            return { isSuccess: true };
          } else {
            setIsChangeCountExceed(true);
            return { isSuccess: false };
          }
        }
      }
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  //Update theme color
  const updateTheme = async () => {
    try {
      const response = await fetch(`${API_DOMAIN}/theme/update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId, color: themeSelected }),
      });
      const result = await response.json();
      if (result.success) {
        const themeArray = [
          themeSelected.light,
          themeSelected.mid,
          themeSelected.dark,
        ];
        Cookies.set("theme", JSON.stringify(themeArray), {
          expires: 1 / 12,
        });
        fetchThemeUpdated();
      }
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  //Update Template
  const updateTemplate = async () => {
    try {
      await fetch(`${API_DOMAIN}/template/update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId, template }),
      });
      fetchTemplateUpdated();
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  //update privacy status
  const updatePrivacy = async () => {
    try {
      await fetch(`${API_DOMAIN}/privacy/update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId, privacyMode: privacyChecked }),
      });
      fetchPrivacyUpdated();
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  //delete user profile
  const deleteProfile = async () => {
    try {
      await fetch(`${API_DOMAIN}/account/delete/${userId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId,
        }),
      });
      setIsProfileDeleted(true);
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  //Update circle template
  const updateCircleTemplate = async () => {
    try {
      await fetch(`${API_DOMAIN}/circle/template/update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: userId,
          template: circleTemplateColor,
        }),
      });
      fetchCircleTemplateUpdated();
      fetchCircleData();
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  //handle delete profile
  const handleDeleteProfile = () => {
    if (usernameInput === username) {
      deleteProfile();
      setIsConfirmDeleteProfile(false);
      Cookies.remove("userId");
      Cookies.remove("token");
      Cookies.remove("userName");
      Cookies.remove("theme");
      localStorage.clear();
      sessionStorage.clear();
      setTimeout(() => {
        window.location.href = "/";
      }, 5000);
    } else {
      setDeleteProfileError("Please type your user name correctly!");
    }
  };

  const handleClose = () => {
    window.location.href = "/";
  };
  const handleSaveChanges = async () => {
    if (isFirstNameChanged || isLastNameChanged || isUsernameChanged) {
      const result = await updateUserDetails();
      if (result && result.success) {
        setIsSaveChanges(true);
        setIsVisible(true);
        await closeModalAfterDelay();
        return;
      } else {
        setIsVisible(false);
        setIsSaveChanges(false);
        return;
      }
    }

    await Promise.all([
      updateTheme(),
      updateTemplate(),
      updatePrivacy(),
      updateCircleTemplate(),
    ]);

    await closeModalAfterDelay();
  };

  return (
    <>
      <div className="settings-popup" onClick={handleModalClick}>
        <div className="sp-header">
          <div
            className="sp-close"
            onClick={() => {
              closeModalSettings(false);
            }}
          >
            <IoMdClose />
          </div>
          <div className="sp-title">
            <h>Settings</h>
          </div>
        </div>
        <div className="sp-body">
          <div className="setting-type">
            <div className="setting-header">
              <div
                className="setting-title"
                onClick={() => {
                  setIsThemeCanvas(!isThemeCanvas);
                  setIsAccountCanvas(false);
                  setIsInsightsCanvas(false);
                  setIsColorThemeCanvas(false);
                  setIsTemplateCanvas(false);
                  setIsPrivacyCanvas(false);
                  setIsPlansCanvas(false);
                }}
              >
                Theme
              </div>
              <div className="setting-type-icon">
                {isThemeCanvas ? (
                  <MdOutlineKeyboardArrowDown
                    onClick={() => {
                      setIsThemeCanvas(false);
                      setIsAccountCanvas(false);
                      setIsInsightsCanvas(false);
                      setIsColorThemeCanvas(false);
                      setIsTemplateCanvas(false);
                      setIsPrivacyCanvas(false);
                      setIsPlansCanvas(false);
                    }}
                  />
                ) : (
                  <MdOutlineKeyboardArrowRight
                    onClick={() => {
                      setIsThemeCanvas(true);
                      setIsAccountCanvas(false);
                      setIsInsightsCanvas(false);
                      setIsColorThemeCanvas(false);
                      setIsTemplateCanvas(false);
                      setIsPrivacyCanvas(false);
                      setIsPlansCanvas(false);
                    }}
                  />
                )}
              </div>
            </div>
            {isThemeCanvas && (
              <div className="settings-type-body">
                <div
                  className="setting-sub-title"
                  onClick={() => {
                    setIsColorThemeCanvas(!isColorThemeCanvas);
                    setIsTemplateCanvas(false);
                  }}
                >
                  Colors
                  {isColorThemeCanvas ? (
                    <MdOutlineKeyboardArrowDown />
                  ) : (
                    <MdOutlineKeyboardArrowRight />
                  )}
                </div>
                {isColorThemeCanvas && (
                  <div className="choose-color-sec">
                    {colors.map((color, index) => (
                      <div
                        key={color.id}
                        className={
                          lightTheme === themeSelected[0] ||
                          themeSelected.light === color.id.light
                            ? "choose-color-selected"
                            : "choose-color"
                        }
                        onClick={() => {
                          handleColorClick(color, index);
                        }}
                        style={{
                          background: color.gradient,
                          outline:
                            lightTheme === color.id.light ||
                            themeSelected.light === color.id.light
                              ? `3px solid ${color.outline}`
                              : "none",
                        }}
                      >
                        {index >= 3 && (
                          <button
                            className="choose-color-lock"
                            onClick={() => {
                              setIsUnlockColors(true);
                            }}
                          >
                            <CiLock />
                          </button>
                        )}
                      </div>
                    ))}
                  </div>
                )}
                {isUnlockColors && (
                  <div className="unlock-colors-modal">
                    <div
                      className="unlock-colors-header"
                      onClick={() => {
                        setIsUnlockColors(false);
                      }}
                    >
                      <RxCross2 />
                    </div>
                    <div className="unlock-colors-msg">
                      {" "}
                      Unlock more colors?
                    </div>
                    <div className="unlock-colors-msg">
                      <b> Upgrade to a premium plan </b>
                    </div>
                    <div
                      className="unlock-colors-btn"
                      onClick={() => {
                        setIsPlansCanvas(!isPlansCanvas);
                        setIsThemeCanvas(false);
                        setIsAccountCanvas(false);
                        setIsInsightsCanvas(false);
                        setIsPrivacyCanvas(false);
                        setIsUnlockColors(false);
                      }}
                    >
                      Upgrade
                    </div>
                  </div>
                )}

                <div
                  className="setting-sub-title"
                  onClick={() => {
                    setIsTemplateCanvas(!isTemplateCanvas);
                    setIsColorThemeCanvas(false);
                  }}
                >
                  Templates
                  {isTemplateCanvas ? (
                    <MdOutlineKeyboardArrowDown />
                  ) : (
                    <MdOutlineKeyboardArrowRight />
                  )}
                </div>
                {isTemplateCanvas && (
                  <>
                    <div className="choose-template">
                      <div className="choose-template-desc">
                        Choose your shortlys template
                      </div>
                      <div className="choose-template-radio">
                        <div className="template-radio">
                          <input
                            type="radio"
                            name="em-select"
                            checked={template !== "border"}
                            onClick={() => setTemplate("fill")}
                          />
                          <div className="templates-radio-btn">Fill</div>
                        </div>
                        <div className="template-radio">
                          <input
                            type="radio"
                            name="em-select"
                            checked={template !== "fill"}
                            onClick={() => setTemplate("border")}
                          />
                          <div className="templates-radio-btn">Border Only</div>
                        </div>
                      </div>
                    </div>

                    <div className="choose-template">
                      <div className="choose-template-desc">
                        Choose your circles template
                      </div>
                      <div className="choose-template-radio">
                        <div className="template-radio">
                          <input
                            type="radio"
                            checked={circleTemplateColor !== "#000000"}
                            onClick={() => {
                              setCircleTemplateColor("#ffffff");
                            }}
                          />
                          <div className="templates-radio-btn">Light</div>
                        </div>
                        <div className="template-radio">
                          <input
                            type="radio"
                            checked={circleTemplateColor !== "#ffffff"}
                            onClick={() => {
                              setCircleTemplateColor("#000000");
                            }}
                          />
                          <div className="templates-radio-btn">Dark</div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>

          <div className="setting-type">
            <div className="setting-header">
              <div
                className="setting-title"
                onClick={() => {
                  setIsAccountCanvas(!isAccountCanvas);
                  setIsInsightsCanvas(false);
                  setIsThemeCanvas(false);
                  setIsGeneralAcc(false);
                  setIsAdvanceAcc(false);
                  setIsPrivacyCanvas(false);
                  setIsPlansCanvas(false);
                }}
              >
                Account
              </div>
              <div className="setting-type-icon">
                {isAccountCanvas ? (
                  <MdOutlineKeyboardArrowDown
                    onClick={() => {
                      setIsThemeCanvas(false);
                      setIsAccountCanvas(false);
                      setIsInsightsCanvas(false);
                      setIsColorThemeCanvas(false);
                      setIsTemplateCanvas(false);
                      setIsPrivacyCanvas(false);
                      setIsPlansCanvas(false);
                    }}
                  />
                ) : (
                  <MdOutlineKeyboardArrowRight
                    onClick={() => {
                      setIsThemeCanvas(false);
                      setIsAccountCanvas(true);
                      setIsInsightsCanvas(false);
                      setIsColorThemeCanvas(false);
                      setIsTemplateCanvas(false);
                      setIsPrivacyCanvas(false);
                      setIsPlansCanvas(false);
                    }}
                  />
                )}
              </div>
            </div>
            {isAccountCanvas && (
              <div className="settings-type-body">
                <div
                  className="setting-sub-title"
                  onClick={() => {
                    setIsGeneralAcc(!isGeneralAcc);
                    setIsAdvanceAcc(false);
                  }}
                >
                  General
                  {isGeneralAcc ? (
                    <MdOutlineKeyboardArrowDown />
                  ) : (
                    <MdOutlineKeyboardArrowRight />
                  )}
                </div>
                {isGeneralAcc && (
                  <>
                    <div className="s-name-instruction">
                      Please note: Name and Username can be changed only 3
                      times. Choose wisely!
                    </div>
                    <div className="general-settings-body">
                      <div className="s-name-sec">
                        <span className="s-name-label">First Name</span>
                        <input
                          type="text"
                          className="s-input"
                          placeholder="First name"
                          value={firstName}
                          onChange={handleFirstName}
                        />
                        {firstNameError && (
                          <div className="gs-error-msg">{firstNameError}</div>
                        )}
                      </div>
                      <div className="s-name-sec">
                        <span className="s-name-label">Last Name</span>
                        <input
                          type="text"
                          className="s-input"
                          placeholder="Last name"
                          value={lastName}
                          onChange={handleLastName}
                        />
                        {lastNameError && (
                          <div className="gs-error-msg">{lastNameError}</div>
                        )}
                      </div>
                      <div className="s-name-sec">
                        <span className="s-name-label">User Name</span>
                        <input
                          type="text"
                          className="s-input"
                          placeholder="User name"
                          value={username}
                          onChange={handleUsername}
                        />
                        {usernameError && (
                          <div className="gs-error-msg">{usernameError}</div>
                        )}
                      </div>
                      <div className="email-sec">
                        <div className="s-name-sec">
                          <div className="s-name-label">Email</div>
                          <input
                            type="text"
                            className="s-input-email"
                            placeholder="User name"
                            value={email}
                            readOnly
                          />
                        </div>

                        <a
                          href="https://one.mavicsoft.com/"
                          target="blank"
                          style={{ textDecoration: "none" }}
                          className="email-change"
                        >
                          <div>Change Email in Mavic One</div>
                        </a>
                      </div>
                    </div>
                  </>
                )}

                <div
                  className="setting-sub-title"
                  onClick={() => {
                    setIsGeneralAcc(false);
                    setIsAdvanceAcc(!isAdvanceAcc);
                  }}
                >
                  Advanced
                  {isAdvanceAcc ? (
                    <MdOutlineKeyboardArrowDown />
                  ) : (
                    <MdOutlineKeyboardArrowRight />
                  )}
                </div>
                {isAdvanceAcc && (
                  <div className="general-settings-body">
                    <div
                      className="delete-acc"
                      onClick={() => {
                        setIsConfirmDeleteProfile(true);
                      }}
                    >
                      Delete Account
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="setting-type">
            <div className="setting-header">
              <div
                className="setting-title"
                onClick={() => {
                  setIsInsightsCanvas(!isInsightsCanvas);
                  setIsThemeCanvas(false);
                  setIsAccountCanvas(false);
                  setIsPrivacyCanvas(false);
                  setIsPlansCanvas(false);
                }}
              >
                Insights
              </div>
              <div className="setting-type-icon">
                {isInsightsCanvas ? (
                  <MdOutlineKeyboardArrowDown
                    onClick={() => {
                      setIsThemeCanvas(false);
                      setIsAccountCanvas(false);
                      setIsInsightsCanvas(false);
                      setIsColorThemeCanvas(false);
                      setIsTemplateCanvas(false);
                      setIsPrivacyCanvas(false);
                      setIsPlansCanvas(false);
                    }}
                  />
                ) : (
                  <MdOutlineKeyboardArrowRight
                    onClick={() => {
                      setIsThemeCanvas(false);
                      setIsAccountCanvas(false);
                      setIsInsightsCanvas(true);
                      setIsColorThemeCanvas(false);
                      setIsTemplateCanvas(false);
                      setIsPrivacyCanvas(false);
                      setIsPlansCanvas(false);
                    }}
                  />
                )}
              </div>
            </div>

            {isInsightsCanvas && (
              <div className="settings-type-body">
                <div className="insights-title">Wowers!</div>
                <div className="insights-count">5,675</div>
                <div className="insights-desc">
                  Your Tinly profile exploded 5,657 views and counting! Build
                  your dream community bigger!
                </div>
                <div className="insights-note">
                  Free plan is limited to 1 insight only. Upgrade for more
                  powerful insights!
                </div>
                <div className="insights-upgrade">
                  <div
                    onClick={() => {
                      setIsPlansCanvas(!isPlansCanvas);
                      setIsThemeCanvas(false);
                      setIsAccountCanvas(false);
                      setIsInsightsCanvas(false);
                      setIsPrivacyCanvas(false);
                    }}
                  >
                    Upgrade
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="setting-type">
            <div className="setting-header">
              <div
                className="setting-title"
                onClick={() => {
                  setIsPrivacyCanvas(!isPrivacyCanvas);
                  setIsThemeCanvas(false);
                  setIsAccountCanvas(false);
                  setIsInsightsCanvas(false);
                  setIsPlansCanvas(false);
                }}
              >
                Privacy
              </div>
              <div className="setting-type-icon">
                {isPrivacyCanvas ? (
                  <MdOutlineKeyboardArrowDown
                    onClick={() => {
                      setIsThemeCanvas(false);
                      setIsAccountCanvas(false);
                      setIsInsightsCanvas(false);
                      setIsColorThemeCanvas(false);
                      setIsTemplateCanvas(false);
                      setIsPrivacyCanvas(false);
                      setIsPlansCanvas(false);
                    }}
                  />
                ) : (
                  <MdOutlineKeyboardArrowRight
                    onClick={() => {
                      setIsThemeCanvas(false);
                      setIsAccountCanvas(false);
                      setIsInsightsCanvas(false);
                      setIsColorThemeCanvas(false);
                      setIsTemplateCanvas(false);
                      setIsPrivacyCanvas(true);
                      setIsPlansCanvas(false);
                    }}
                  />
                )}
              </div>
            </div>
            {isPrivacyCanvas && (
              <div className="settings-type-body">
                <div className="privacy-tab">
                  <span className="privacy-icon">
                    <FaGlobeAmericas />
                  </span>

                  <div className="privacy-type">
                    <div className="privacy-type-label">Public</div>
                    <div className="privacy-type-desc">
                      Your profile visible to everyone
                    </div>
                  </div>

                  <div className="privacy-select">
                    <input
                      type="radio"
                      name="privacyChecked"
                      checked={privacyChecked === "Public"}
                      onChange={() => handlePrivacyChange("Public")}
                    />
                  </div>
                </div>

                <div className="privacy-tab">
                  <span className="privacy-icon">
                    <FaUserFriends />
                  </span>

                  <div className="privacy-type">
                    <div className="privacy-type-label">Followers Only</div>
                    <div className="privacy-type-desc">
                      Only your approved connections can view your profile.
                    </div>
                  </div>

                  <div className="privacy-select">
                    <input
                      type="radio"
                      name="privacyChecked"
                      checked={privacyChecked === "Private"}
                      onChange={() => handlePrivacyChange("Private")}
                    />
                  </div>
                </div>

                <div className="privacy-tab">
                  <span className="privacy-icon">
                    <FaLock />
                  </span>

                  <div className="privacy-type">
                    <div className="privacy-type-label">Only Me</div>
                    <div className="privacy-type-desc">
                      Only you can view your profile
                    </div>
                  </div>

                  <div className="privacy-select">
                    <input
                      type="radio"
                      name="privacyChecked"
                      checked={privacyChecked === "Only me"}
                      onChange={() => handlePrivacyChange("Only me")}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="setting-type">
            <div className="setting-header">
              <div
                className="setting-title"
                onClick={() => {
                  setIsPlansCanvas(!isPlansCanvas);
                  setIsThemeCanvas(false);
                  setIsAccountCanvas(false);
                  setIsInsightsCanvas(false);
                  setIsPrivacyCanvas(false);
                }}
              >
                Upgrade
              </div>
              <div className="setting-type-icon">
                {isPlansCanvas ? (
                  <MdOutlineKeyboardArrowDown
                    onClick={() => {
                      setIsThemeCanvas(false);
                      setIsAccountCanvas(false);
                      setIsInsightsCanvas(false);
                      setIsColorThemeCanvas(false);
                      setIsTemplateCanvas(false);
                      setIsPrivacyCanvas(false);
                      setIsPlansCanvas(false);
                    }}
                  />
                ) : (
                  <MdOutlineKeyboardArrowRight
                    onClick={() => {
                      setIsThemeCanvas(false);
                      setIsAccountCanvas(false);
                      setIsInsightsCanvas(false);
                      setIsColorThemeCanvas(false);
                      setIsTemplateCanvas(false);
                      setIsPrivacyCanvas(false);
                      setIsPlansCanvas(true);
                    }}
                  />
                )}
              </div>
            </div>
            {isPlansCanvas && (
              <div className="settings-type-body">
                <div className="plan-canvas-free">
                  <div className="pc-top">
                    <div className="plan-name">FREE</div>
                    <div className="plan-price">$0</div>
                    <div className="plan-sub-desc">Free for a lifetime</div>
                  </div>

                  <button className="pc-middle-btn">Current Plan</button>

                  <div className="pc-content">
                    <div className="plan-features">
                      <IoCheckmark className="plan-features-icon" />
                      <p className="plan-features-desc">
                        Unlimited profile visits
                      </p>
                    </div>
                    <div className="plan-features">
                      <IoCheckmark className="plan-features-icon" />
                      <p className="plan-features-desc">Unlimited fan base</p>
                    </div>
                    <div className="plan-features">
                      <IoCheckmark className="plan-features-icon" />
                      <p className="plan-features-desc">25 short Links</p>
                    </div>
                    <div className="plan-features">
                      <IoCheckmark className="plan-features-icon" />
                      <p className="plan-features-desc">5 Circles</p>
                    </div>
                    <div className="plan-features">
                      <IoCheckmark className="plan-features-icon" />
                      <p className="plan-features-desc">3 Profile themes</p>
                    </div>
                    <div className="plan-features">
                      <RxCross2 className="plan-features-icon" />
                      <p className="plan-features-desc">Private profile</p>
                    </div>
                    <div className="plan-features">
                      <RxCross2 className="plan-features-icon" />
                      <p className="plan-features-desc">Profile verification</p>
                    </div>
                    <div className="plan-features">
                      <RxCross2 className="plan-features-icon" />
                      <p className="plan-features-desc">Image Background</p>
                    </div>
                  </div>

                  <div className="pc-footer">See more...</div>
                </div>

                <div className="plan-canvas-silver">
                  <div className="pc-top">
                    <div className="plan-name">SILVER</div>
                    <div className="plan-price">$9.99</div>
                    <div className="plan-sub-desc">Per year</div>
                  </div>

                  <button className="pc-middle-btn">Get Silver Plan</button>

                  <div className="pc-content">
                    <div className="plan-features">
                      <IoCheckmark className="plan-features-icon" />
                      <p className="plan-features-desc">
                        Unlimited profile visits
                      </p>
                    </div>
                    <div className="plan-features">
                      <IoCheckmark className="plan-features-icon" />
                      <p className="plan-features-desc">Unlimited fan base</p>
                    </div>
                    <div className="plan-features">
                      <IoCheckmark className="plan-features-icon" />
                      <p className="plan-features-desc">50 short Links</p>
                    </div>
                    <div className="plan-features">
                      <IoCheckmark className="plan-features-icon" />
                      <p className="plan-features-desc">10 Circles</p>
                    </div>
                    <div className="plan-features">
                      <IoCheckmark className="plan-features-icon" />
                      <p className="plan-features-desc">15 Profile themes</p>
                    </div>
                    <div className="plan-features">
                      <IoCheckmark className="plan-features-icon" />
                      <p className="plan-features-desc">Private profile</p>
                    </div>
                    <div className="plan-features">
                      <RxCross2 className="plan-features-icon" />
                      <p className="plan-features-desc">Profile verification</p>
                    </div>
                    <div className="plan-features">
                      <RxCross2 className="plan-features-icon" />
                      <p className="plan-features-desc">Image Background</p>
                    </div>
                  </div>

                  <div className="pc-footer">See more...</div>
                </div>

                <div className="plan-canvas-gold">
                  <div className="pc-top">
                    <div className="plan-name">GOLD</div>
                    <div className="plan-price">$29.99</div>
                    <div className="plan-sub-desc">Per year</div>
                  </div>

                  <button className="pc-middle-btn">Get Gold Plan</button>

                  <div className="pc-content">
                    <div className="plan-features">
                      <IoCheckmark className="plan-features-icon" />
                      <p className="plan-features-desc">
                        Unlimited profile visits
                      </p>
                    </div>
                    <div className="plan-features">
                      <IoCheckmark className="plan-features-icon" />
                      <p className="plan-features-desc">Unlimited fan base</p>
                    </div>
                    <div className="plan-features">
                      <IoCheckmark className="plan-features-icon" />
                      <p className="plan-features-desc">
                        Unlimited short Links
                      </p>
                    </div>
                    <div className="plan-features">
                      <IoCheckmark className="plan-features-icon" />
                      <p className="plan-features-desc">20 Circles</p>
                    </div>
                    <div className="plan-features">
                      <IoCheckmark className="plan-features-icon" />
                      <p className="plan-features-desc">30 Profile themes</p>
                    </div>
                    <div className="plan-features">
                      <IoCheckmark className="plan-features-icon" />
                      <p className="plan-features-desc">Private profile</p>
                    </div>
                    <div className="plan-features">
                      <IoCheckmark className="plan-features-icon" />
                      <p className="plan-features-desc">Profile verification</p>
                    </div>
                    <div className="plan-features">
                      <IoCheckmark className="plan-features-icon" />
                      <p className="plan-features-desc">Image Background</p>
                    </div>
                  </div>

                  <div className="pc-footer">See more...</div>
                </div>

                <div className="compare-plans">
                  Compare plans and features{" "}
                  <b className="click-plan-compare">here</b>.
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="sp-footer">
          <button
            className="sp-save"
            onClick={() => {
              handleSaveChanges();
              handleClickLoading();
            }}
            disabled={isLoading}
          >
            {isLoading ? <div className="spinner-save"></div> : "Save Changes"}
          </button>
        </div>
      </div>

      <ConfirmationMessage
        isSaveChanges={isSaveChanges}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
      />

      {isConfirmDeleteProfile && (
        <div className="delete-profile-modal" onClick={handleModalClick}>
          <div className="dp-header">
            <div className="dp-title">Delete Account</div>
            <div
              className="dp-close-btn"
              onClick={() => {
                setIsConfirmDeleteProfile(false);
              }}
            >
              <RxCross2 />
            </div>
          </div>
          <div className="dp-body">
            <div className="dp-body-desc">
              Deleting your Tinly account will permanently remove all public and
              private information associated with your profile.
            </div>
            <div className="dp-body-desc-note">
              <span>Note:</span>
              <div className="dp-body-notes">
                - All your data, including short links, private links, and your
                connections, will be permanently removed.
                <br />- You will lose access to all your saved preferences and
                settings.
              </div>
            </div>
            <div className="retype-username">
              <div className="retype-description">
                To confirm delete your account, type your username in the box
                below.
              </div>
              <input
                type="text"
                className="retype-textbox"
                onChange={handleRetypeUsername}
              />
              {deleteProfileError && (
                <div className="delete-profile-error">{deleteProfileError}</div>
              )}
            </div>
          </div>
          <div className="dp-footer">
            <div
              className="dp-cancel"
              onClick={() => {
                setIsConfirmDeleteProfile(false);
              }}
            >
              Cancel
            </div>
            <div className="dp-confirm" onClick={handleDeleteProfile}>
              Delete
            </div>
          </div>
        </div>
      )}
      {isChangeCountExceed && (
        <div className="name-count-error">
          Name and Username change limit reached
        </div>
      )}

      {isProfileDeleted && (
        <div className="profile-deleted" onClick={handleModalClick}>
          <div className="dp-body">
            <div className="pd-desc">Your profile deleted successfully!</div>

            <div className="pd-notes">
              We're sorry to see you go. If you have any feedback or need
              further assistance, please contact our support team.
            </div>

            <div className="pd-notes">
              <b>Thank you for being a part of our community.</b>
            </div>
          </div>
          <div className="dp-footer">
            <div
              className="dp-confirm"
              onClick={() => {
                handleClose();
              }}
            >
              Ok
            </div>
          </div>
        </div>
      )}
      <ConsoleError
        isConsoleError={isConsoleError}
        consoleError={consoleError}
        closeConsoleError={setIsConsoleError}
      />
    </>
  );
}
